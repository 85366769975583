(function (window, document, $, undefined) {
  FRB.contactUs = (function () {
    // Global vars
    var officeOpenOrCloseMessageURL = "/Navigation/GetOfficeOpenOrCloseMessage";

    // Get locations list
    var _getOfficeOpenOrCloseMessage = function () {
    /*  $.ajax({
        url: officeOpenOrCloseMessageURL,
        type: "GET",
        dataType: "html",
      })
        .done(function (result) {
          //console.log('Promise success callback.');
          $("#contactOfficeOpenOrClose").html(result);
        })
        .fail(function (jqXHR, status, err) {
          //console.log('Promise error callback.' + err);
        })
        .always(function () {
          //console.log('Promise completion callback.');
        });*/
    };

    return {
      getOfficeOpenOrCloseMessage: _getOfficeOpenOrCloseMessage,
    };
  })();

  $(document).ready(function () {
    if ($("#contactDrop").length > 0) {
      FRB.contactUs.getOfficeOpenOrCloseMessage();
    }
  });
})(window, document, jQuery);

import "fullpage.js";
import analytics from "./analytics-custom";

(function (window, document, $, undefined) {
  FRB.swipeDetect = (function () {
    var touchStart, touchMove, touchEnd;
    var _init = function (el, callback) {
      var touchsurface = el,
        swipedir,
        startX,
        startY,
        distX,
        distY,
        threshold = 50, //required min distance traveled to be considered swipe
        restraint = 100, // maximum distance allowed at the same time in perpendicular direction
        allowedTime = 300, // maximum time allowed to travel that distance
        elapsedTime,
        startTime,
        handleswipe = callback || function (swipedir, e) {};

      touchStart = function (e) {
        var touchobj = e.changedTouches[0];
        swipedir = "none";
        startX = touchobj.pageX;
        startY = touchobj.pageY;
        startTime = new Date().getTime();
      };

      touchMove = function (e) {};

      touchEnd = function (e) {
        var touchobj = e.changedTouches[0];
        distX = touchobj.pageX - startX;
        distY = touchobj.pageY - startY;
        elapsedTime = new Date().getTime() - startTime;
        if (elapsedTime <= allowedTime) {
          if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint) {
            swipedir = distX < 0 ? "left" : "right";
          } else if (
            Math.abs(distY) >= threshold &&
            Math.abs(distX) <= restraint
          ) {
            swipedir = distY < 0 ? "up" : "down";
          }
        }
        handleswipe(swipedir, e);
      };
      touchsurface.addEventListener("touchstart", touchStart, false);
      touchsurface.addEventListener("touchmove", touchMove, false);
      touchsurface.addEventListener("touchend", touchEnd, false);
    };

    var _destroyEvent = function (el) {
      el.removeEventListener("touchstart", touchStart, false);
      el.removeEventListener("touchmove", touchMove, false);
      el.removeEventListener("touchend", touchEnd, false);
    };
    return {
      init: _init,
      destroyEvent: _destroyEvent,
    };
  })();
})(window, document, jQuery);

/// <reference path="a-global.js" />
/// <reference path="FRB.playVideoInModal.js" />
(function (window, document, $, undefined) {
  FRB.fullPage = (function () {
    // Create global variables
    var testimonialsJsonUrl = "/GetTestimonialJSon/Testimonial/",
      testimonialCard,
      testimonialArrary = [],
      $testimonialCardWrapper = $(".testimonialCardWrapper"),
      clientNameFormatted,
      totalSectionCount,
      anchorsArray = [];

    // Fullpage settings

    var _setFillPageOptions = function (fullPageScroll) {
      totalSectionCount = $("#fullPage .section[data-anchor]").length;
      for (var i = 0; i < totalSectionCount; i++) {
        anchorsArray.push("slide-" + (i + 1));
      }

      var scrollingObject = {};
      if (fullPageScroll) {
        scrollingObject = {
          responsiveWidth: "50000",
          fitToSection: false,
          animateAnchor: true,
          autoScrolling: false,
          anchors: anchorsArray,
        };
        $(".down-arrow").hide();
      } else {
        scrollingObject = {
          navigation: true,
          responsiveWidth: "768",
          scrollBar: false,
          navigationPosition: "right",
          anchors: anchorsArray,
        };
      }

      var fullPageOptions = {
        afterRender: function () {
          if ($(this).prev().hasClass("fixed")) {
            $(this)
              .find("section:first-child")
              .css("padding-top", $(this).prev().outerHeight());
          }
        },
        afterResize: function () {
          if ($(this).prev().hasClass("fixed")) {
            $(this)
              .find("section:first-child")
              .css("padding-top", $(this).prev().outerHeight());
          }
        },
        onLeave: function (index, nextIndex, direction) {
          var leavingSection = $(this),
            logoW = $(window).width() > 961 ? "200px" : "150px",
            navMargin = fullPageScroll
              ? "16px"
              : $(window).width() >= 768
              ? "35px"
              : "16px",
            logoMargin = $(window).width() >= 768 ? "0" : "-10px";

          // Reduce header height when leaving section 1
          if (index == 1 && direction == "down") {
            $(".static-header nav")
              .animate({ marginTop: "8px" }, 200)
              .animate({ marginBottom: "8px" }, 200);
            $(".static-header .logo img")
              .animate({ width: "150px", marginTop: "-10px" }, 200)
              .eq(0)
              .fadeOut(200);
          } else if (index >= 2 && nextIndex == 1 && direction == "up") {
            $(".static-header nav")
              .animate({ marginTop: navMargin }, 200)
              .animate({ marginBottom: navMargin }, 200);
            $(".static-header .logo img")
              .animate({ width: logoW, marginTop: logoMargin }, 200)
              .eq(0)
              .fadeIn(200);
          }

          if ($(".ploc.mobile").length > 0) {
            if (nextIndex >= 2) {
              $(".ploc.sticky-footer").fadeIn();
            } else {
              $(".ploc.sticky-footer").fadeOut();
            }

            if (
              (nextIndex === 2 && direction === "down") ||
              (nextIndex === 5 && direction === "down")
            ) {
              $(this)
                .next("section")
                .find(".scroll-reveal")
                .addClass("visible");
            }
            if (nextIndex >= 6 && direction === "up") {
              $(".scroll-reveal").css({
                opacity: 1,
                transform: "translateY(0)",
              });
            }
          }
        },
        afterLoad: function (anchorLink, index) {
          var loadedSection = $(this),
            $totalSectionCount = $("#fullPage .section[data-anchor]").length;

          // Set footer section height to auto
          $("#fullPage section[data-anchor]")
            .last()
            .addClass("fp-auto-height main-footer");
          _setTotalCount($totalSectionCount);
          // Show/hide down arrow
          if (index == 1 && !fullPageScroll) {
            $(".down-arrow").fadeIn();
          } else {
            $(".down-arrow").fadeOut(200);
          }
        },
      };
      jQuery.extend(fullPageOptions, scrollingObject);

      return fullPageOptions;
    };

    var bindEvents = function () {
      // Navigate to next section
      $(".down-arrow").on("click", function () {
        $.fn.fullpage.moveSectionDown();
      });
    };

    // Client Testimonial Component
    var buildTestimonialCard = function (data) {
      $.each(data, function (i, testimonialObj) {
        clientNameFormatted = testimonialObj.ClientName.replace(/[^\w\s]/gi, "")
          .replace(/\s+/g, "_")
          .toLowerCase();

        testimonialCard = "<li><figure class='client-image-thumb'>";
        testimonialCard +=
          testimonialObj.IsVideo == true
            ? "<a href='#' data-reveal-id='videoModalTestimonial' data-reveal-data='" +
              testimonialObj.VideoID +
              "'><i class='icon icon-video' aria-label='This testimonial contains video'></i></a>"
            : "";
        testimonialCard +=
          "<a href='" +
          testimonialObj.FriendlyUrl +
          "'><img src='" +
          testimonialObj.PreviewImageUrl +
          "' aria-label='" +
          clientNameFormatted +
          "' alt='" +
          testimonialObj.PreviewImageAlt +
          "' /></a>";
        testimonialCard += "<div class='client-info'>";
        testimonialCard +=
          "<div class='type-n2'>" + testimonialObj.ClientName + "</div>";
        testimonialCard +=
          "<div class='type-t2'>" + testimonialObj.Industries[0] + "</div>";
        testimonialCard += "</div>";
        testimonialCard += "</figure></li>";

        $testimonialCardWrapper.append(testimonialCard);
      });
      if (analytics.isTrackAnalitycsLoaded()) {
        analytics.ttmCampaignQ12017Testimonials();
      }
    };

    var _setTotalCount = function (totalCount) {
      totalSectionCount = totalCount;
    };

    var _addBlankTargetToLinks = function () {
      if (
        $("#fullPage .testimonialCardWrapper a[href*='/testimonials/']").length
      ) {
        $("#fullPage .testimonialCardWrapper a[href*='/testimonials/']").prop(
          "target",
          "_blank"
        );
      }
      if ($("#fullPage .featured-articles__list-item a").length) {
        $("#fullPage .featured-articles__list-item a").prop("target", "_blank");
      }
    };

    var _addAriaLabelToSliderNav = function () {
      var _count = 1;
      $("#fp-nav li").each(function () {
        var elm = $(this).children("a");
        elm.attr("aria-label", "slide " + _count);
        elm.attr("aria-live", "assertive");
        elm.attr("aria-atomic", "true");
        _count++;
      });
    };

    var _generateDots = function (total, dotsRef) {
      dotsRef = dotsRef.replace(".", "");
      var dots = "<ul class='" + dotsRef + "'>";
      for (var i = 0; i < total; i++) {
        dots +=
          "<li><a href='javascript:;' data-num='" +
          i +
          "'><span class='show-for-sr'>Slide " +
          i +
          "</span></a></li>";
      }
      dots += "</ul>";
      return dots;
    };

    var _slideCarousel_Eagle = function (
      $layout,
      index,
      listRef,
      listItemRef,
      dotsRef
    ) {
      var slideWidth = $layout.width(),
        transformPos = (slideWidth + 20) * index;
      $layout
        .find(dotsRef + " li:eq(" + index + ") a")
        .addClass("active")
        .parent()
        .siblings()
        .find("a")
        .removeClass("active");
      $layout
        .find(listRef)
        .css("transform", "translate3d(" + -transformPos + "px, 0, 0)")
        .find(listItemRef + ":eq(" + index + ")")
        .addClass("active")
        .siblings()
        .removeClass("active");
    };

    var _slideCarousel = function (
      $layout,
      index,
      listRef,
      listItemRef,
      dotsRef
    ) {
      var slideWidth = $layout.width(),
        $slideItem = $layout.find(listRef + " " + listItemRef),
        slideCount = $slideItem.length,
        transformPos = parseInt((100 / slideCount) * index);
      $layout
        .find(dotsRef + " li:eq(" + index + ") a")
        .addClass("active")
        .parent()
        .siblings()
        .find("a")
        .removeClass("active");
      $layout
        .find(listRef)
        .css("transform", "translate3d(" + -transformPos + "%, 0, 0)")
        .find(listItemRef + ":eq(" + index + ")")
        .addClass("active")
        .siblings()
        .removeClass("active");
    };

    var _mobileCarousel_Eagle = function (
      rootRef,
      parentRef,
      listRef,
      listItemRef,
      dotsRef
    ) {
      $(rootRef + " " + parentRef)
        .each(function () {
          var $this = $(this),
            $slideItem = $this.find(listRef + " " + listItemRef),
            slideWidth = $this.width(),
            slideCount = $slideItem.length,
            totalWidth = (slideWidth + 20) * slideCount - 20;
          $slideItem.css("width", slideWidth).parent().css("width", totalWidth);
          if ($this.find(dotsRef).length === 0) {
            $this.append(_generateDots(slideCount, dotsRef));
          }
          $this
            .find(dotsRef + " li:first-child a")
            .add($slideItem.filter(":first-child"))
            .addClass("active");
        })
        .on("click", dotsRef + " li a", function () {
          var $this = $(this),
            index = parseInt($this.attr("data-num"));
          _slideCarousel_Eagle(
            $this.closest(parentRef),
            index,
            listRef,
            listItemRef,
            dotsRef
          );
        })
        .find(listRef)
        .each(function () {
          FRB.swipeDetect.init($(this).get(0), function (swipedir, e) {
            if (swipedir === "left") {
              var $slide = $(e.target).closest("li"),
                index = parseInt($slide.index()) + 1,
                maxLen = $slide.parent(listRef).find(listItemRef).length - 1;
              if (index > maxLen) {
                index = maxLen;
              }
              _slideCarousel_Eagle(
                $slide.closest(parentRef),
                index,
                listRef,
                listItemRef,
                dotsRef
              );
            } else if (swipedir === "right") {
              var $slide = $(e.target).closest("li"),
                index = parseInt($slide.index()) - 1;
              if (index < 0) {
                index = 0;
              }
              _slideCarousel_Eagle(
                $slide.closest(parentRef),
                index,
                listRef,
                listItemRef,
                dotsRef
              );
            }
          });
        });
    };

    var _mobileCarousel = function (
      rootRef,
      parentRef,
      listRef,
      listItemRef,
      dotsRef
    ) {
      $(rootRef + " " + parentRef)
        .each(function () {
          var $this = $(this),
            $slideItem = $this.find(listRef + " " + listItemRef),
            slideCount = $slideItem.length,
            totalWidth = 100 * slideCount,
            slideWidth = parseInt(100 / slideCount);

          $slideItem
            .css("width", slideWidth + "%")
            .parent()
            .css("width", totalWidth + "%");
          if ($this.find(dotsRef).length === 0) {
            $this.append(_generateDots(slideCount, dotsRef));
          }
          $this
            .find(dotsRef + " li:first-child a")
            .add($slideItem.filter(":first-child"))
            .addClass("active");
        })
        .on("click", dotsRef + " li a", function () {
          var $this = $(this),
            index = parseInt($this.attr("data-num"));
          _slideCarousel(
            $this.closest(parentRef),
            index,
            listRef,
            listItemRef,
            dotsRef
          );
        })
        .find(listRef)
        .each(function () {
          FRB.swipeDetect.init($(this).get(0), function (swipedir, e) {
            if (swipedir === "left") {
              var $slide = $(e.target).closest("li"),
                index = parseInt($slide.index()) + 1,
                maxLen = $slide.parent(listRef).find(listItemRef).length - 1;
              if (index > maxLen) {
                index = maxLen;
              }
              _slideCarousel(
                $slide.closest(parentRef),
                index,
                listRef,
                listItemRef,
                dotsRef
              );
            } else if (swipedir === "right") {
              var $slide = $(e.target).closest("li"),
                index = parseInt($slide.index()) - 1;
              if (index < 0) {
                index = 0;
              }
              _slideCarousel(
                $slide.closest(parentRef),
                index,
                listRef,
                listItemRef,
                dotsRef
              );
            }
          });
        });
    };

    var _aioMobileLayout = function () {
      if ($(".static-header .mobile-only-header").length > 0) {
        $(".static-header, #fullPage").addClass("aio-mobile");
        $(".aio-mobile-landing")
          .closest(".fp-section")
          .addClass("aio-mobile-landing-section");
        $(document).on("scroll", function () {
          var scrollPos = $(this).scrollTop(),
            $mobileHeader = $(".mobile-only-header");
          scrollPos > 55
            ? $mobileHeader.addClass("animate")
            : $mobileHeader.removeClass("animate");
        });
        _mobileCarousel(
          ".aio-mobile",
          ".service-list",
          ".service-list__list",
          ".service-list__list-item",
          ".service-list-dots"
        );
      }
    };

    var _servicesRendringCarousel = function () {
      if ($(".services-wrapper.carousel-view ").length > 0) {
        _mobileCarousel(
          ".services-wrapper.carousel-view",
          ".service-list",
          ".service-list__list",
          ".service-list__list-item",
          ".service-list-dots"
        );
      }
    };

    var _eagleInvesetCarousel = function () {
      _mobileCarousel_Eagle(
        ".full-page-getting-started",
        ".getting-started-list",
        ".getting-started-list-list",
        "li",
        ".getting-started-dots"
      );
    };

    var _reInitEagleInvesetCarousel = function () {
      $(window).resize(function () {
        if (!$(".getting-started-img-mobile").is(":visible")) {
          $(".getting-started-dots").remove();
          $(".getting-started-list-list")
            .removeAttr("style")
            .find("li")
            .removeAttr("style")
            .removeClass("active");
          $(".getting-started-list").off("click", ".getting-started-dots li a");
          FRB.swipeDetect.destroyEvent($(".getting-started-list-list").get(0));
        } else {
          _eagleInvesetCarousel();
        }
      });
    };

    var _getAIORatesAndBuildHTML = function () {
      $.ajax({
        type: "GET",
        url: "/aio/rates",
        success: function (result) {
          var rates = JSON.parse(JSON.stringify(result));
          if (rates.length > 0) {
            rates = rates.filter(function (ele) {
              return ele.fixedRate === "1";
            });

            $.each(rates, function (i, rate) {
              $("#lowFixedRate .rate-val")
                .eq(i)
                .find("span")
                .text(rate.FloorRate + "-" + rate.apr);
            });
          }
        },
        complete: function () {
          $("#lowFixedRate .rate-val span").css("opacity", "1");
        },
        error: function (err) {
          console.log(ratesUrl + ": " + err);
        },
      });
    };
    var _getCompetetiorRatesAndBuildHTML = function () {
      $.ajax({
        type: "GET",
        url: "/aio/competitorrates",
        success: function (result) {
          var rates = JSON.parse(JSON.stringify(result));
          if (rates.length > 0) {
            $.each(rates, function (i, rate) {
              $("#cbRates .rate-val")
                .eq(i)
                .find("span")
                .text(rate.FloorRate + "-" + rate.apr);
            });
          }
        },
        complete: function () {
          $("#cbRates .rate-val span").css("opacity", "1");
        },
        error: function (err) {
          console.log(ratesUrl + ": " + err);
        },
      });
    };

    /**
     * @function ploc
     * @description all things ploc
     */
    var ploc = function () {
      // attach href's to product label links
      $("#fullPage.ploc .product-labels a").attr("href", "javascript:void(0)");

      // show/hide products on click event
      $("#fullPage.ploc .product-labels").on("click", "a", function () {
        var $ele = $(this);
        var $products = $("#fullPage.ploc .products");

        $ele.parent().addClass("active").siblings().removeClass("active");

        if ($ele.hasClass("product-label-1")) {
          $products.find(".product-1").fadeIn().siblings().fadeOut();
        }
        if ($ele.hasClass("product-label-2")) {
          $products.find(".product-2").fadeIn().siblings().fadeOut();
        }
        if ($ele.hasClass("product-label-3")) {
          $products.find(".product-3").fadeIn().siblings().fadeOut();
        }
      });

      // initialize slick carousel on
      /*
      $("#fullPage.ploc .desktop-carousel").slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
        appendArrows: $(".fpc__nav"),
        responsive: [
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
            },
          },
          {
            breakpoint: 968,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              arrows: false,
            },
          },
        ],
      });*/

      // Reset products visibility for medium up devices
      $(window).on(
        "resize",
        Foundation.utils.throttle(function (e) {
          if (Foundation.utils.is_medium_up()) {
            $("#fullPage.ploc .comparison-chart.desktop .products")
              .find("> div")
              .fadeIn();
          }
          if (Foundation.utils.is_small_only()) {
            $("#fullPage.ploc .products")
              .find(".product-1")
              .fadeIn()
              .siblings()
              .fadeOut();
          }
        }, 100)
      );
    };

    /**
     * @function plocMobile
     * @description all things plocMobile
     */
    var plocMobile = function () {
      // faq content toggle
      if ($("#fullPage.ploc.mobile").length > 0) {
        var $faqContainer = $(".faqs-container");
        var $faqSection = $faqContainer.find(".faq");
        var $faqAccordion = $faqSection.find(".accordion");
        var originalFaqsArray = $faqAccordion.find("> li");
        var filteredFaqsArray = originalFaqsArray.filter(function (index) {
          var value;
          return (value = index > 2);
        });
        var $closestSection = $faqContainer.closest("section");

        if ($("#fullPage").hasClass("microsite")) {
          $.each(filteredFaqsArray, function (ele) {
            $(ele).css("display", "block");
          });
        } else {
          $.each(filteredFaqsArray, function (index, ele) {
            $(ele).css("display", "none");
          });
        }

        $(".contentToggleBtn").on("click", function () {
          $(this).toggleClass("up");
          $("html, body").animate(
            {
              scrollTop: $closestSection.offset().top,
            },
            "fast"
          );
          if ($(this).data("collapsed") === true) {
            $.each(filteredFaqsArray, function (index, ele) {
              $(ele).fadeIn();
            });
            $(this).text($(this).data("expandedLabel"));
            $(this).data("collapsed", false);
          } else {
            $.each(filteredFaqsArray, function (index, ele) {
              $(ele).fadeOut();
            });
            $(this).text($(this).data("collapsedLabel"));
            $(this).data("collapsed", true);
          }
        });

        $("#fullPage.ploc .hero-title").css("visibility", "visible");

        // show/hide products on click event
        $("#fullPage.ploc.mobile .product-labels").on(
          "click",
          "a",
          function () {
            var $ele = $(this);
            var $products = $("#fullPage.ploc.mobile .products");

            $ele.parent().addClass("active").siblings().removeClass("active");

            if ($ele.hasClass("product-label-1")) {
              $products.find(".product-1").fadeIn().siblings().fadeOut();

              $("#fullPage.ploc.mobile .chart-nav-1")
                .addClass("active")
                .parent()
                .siblings()
                .find("span")
                .removeClass("active");
            }
            if ($ele.hasClass("product-label-2")) {
              $products.find(".product-2").fadeIn().siblings().fadeOut();

              $("#fullPage.ploc.mobile .chart-nav-2")
                .addClass("active")
                .parent()
                .siblings()
                .find("span")
                .removeClass("active");
            }
            if ($ele.hasClass("product-label-3")) {
              $products.find(".product-3").fadeIn().siblings().fadeOut();

              $("#fullPage.ploc.mobile .chart-nav-3")
                .addClass("active")
                .parent()
                .siblings()
                .find("span")
                .removeClass("active");
            }
          }
        );

        // ploc mobile chart nav
        $("#fullPage.ploc.mobile .chart-nav").on("click", "span", function () {
          var $ele = $(this);
          var $products = $("#fullPage.ploc .products");

          $ele
            .addClass("active")
            .parent()
            .siblings()
            .find("span")
            .removeClass("active");

          if ($ele.hasClass("chart-nav-1")) {
            $products.find(".product-1").fadeIn().siblings().fadeOut();

            $("#fullPage.ploc .product-labels .product-label-1")
              .parent()
              .addClass("active");

            $("#fullPage.ploc .product-labels .product-label-1")
              .parent()
              .siblings()
              .removeClass("active");
          }
          if ($ele.hasClass("chart-nav-2")) {
            $products.find(".product-2").fadeIn().siblings().fadeOut();

            $("#fullPage.ploc .product-labels .product-label-2")
              .parent()
              .addClass("active");

            $("#fullPage.ploc .product-labels .product-label-2")
              .parent()
              .siblings()
              .removeClass("active");
          }
          if ($ele.hasClass("chart-nav-3")) {
            $products.find(".product-3").fadeIn().siblings().fadeOut();

            $("#fullPage.ploc .product-labels .product-label-3")
              .parent()
              .addClass("active");

            $("#fullPage.ploc .product-labels .product-label-3")
              .parent()
              .siblings()
              .removeClass("active");
          }
        });

        // mobile only carousel
        /*
        $("#fullPage.ploc.mobile .mobile-carousel").slick({
          variableWidth: true,
          dots: true,
          slidesToShow: 1,
          infinite: false,
          mobileFirst: true,
          arrows: false,
        });*/
      }
    };
    var init = function (fullPageScroll) {
      var sectionNumbers = $("section[data-anchor]").length;

      var fullPageOptions = _setFillPageOptions(fullPageScroll);

      // Initialize Play Video Modal
      //FRB.playVideoInModal.init();

      // Initialize fullpage
      $("#fullPage").fullpage(fullPageOptions);

      // Set section font color & BG image
      $("#fullPage section").each(function (sectionIndex) {
        var $this = $(this);
        if ($this.data("bgurl")) {
          $this.css({
            "background-image": "url(" + $this.data("bgurl") + ")",
            color: $this.data("color"),
          });
        } else {
          $this.css({
            background: $this.data("bgcolor"),
            color: $this.data("color"),
          });
        }
      });

      if ($("#hero-row .columns").hasClass("left")) {
        $("#hero-row .columns").parents("section").addClass("left");
      }

      //insert aria-lable to slider nav for ADA
      _addAriaLabelToSliderNav();

      // Initiate events
      bindEvents();

      // get client testimonail json
      if ($(".testimonialCardWrapper").length) {
        $.ajax({
          url: testimonialsJsonUrl + itemId,
          type: "GET",
        })
          .done(function (result) {
            $.each(result, function (i, testimonial) {
              testimonialArrary.push(testimonial);
            });
            buildTestimonialCard(testimonialArrary);
            _addBlankTargetToLinks();
          })
          .fail(function (jqxhr, status, err) {})
          .always(function () {});
      }
      //for new experience page
      if ($(".new-experience").length > 0) {
        $(".new-experience")
          .closest(".section-content-wrapper")
          .find(".bottom-margin")
          .removeClass("bottom-margin");
      }

      $(".full-page-cob .features-link a").on("click", function () {
        $(this).toggleClass("expanded");
        $(".features-expand-collapse").slideToggle();
      });

      $(".doc-resources_list").on("click", ".doc-resources-link", function () {
        var currentModule = $(this).attr("href");
        var isCurrentModuleVisible = $(currentModule).is(":visible");
        $(".doc-resources_list .doc-resources-modules").fadeOut();

        $(this)
          .parent()
          .siblings()
          .find(".doc-resources-link")
          .removeClass("active");
        $(this).parent().siblings().removeClass("expanded");

        if (isCurrentModuleVisible) {
          $(currentModule).fadeOut();
          $(this)
            .removeClass("active")
            .parent(".doc-resources_list-item")
            .removeClass("expanded");
        } else {
          $(currentModule).fadeIn();
          $(this)
            .addClass("active")
            .parent(".doc-resources_list-item")
            .addClass("expanded");
        }

        return false;
      });

      $(".doc-resources-modules").on("click", "a.close", function () {
        $(this)
          .closest(".doc-resources-modules")
          .fadeOut()
          .prev(".doc-resources-link")
          .removeClass("active")
          .parent(".doc-resources_list-item")
          .removeClass("expanded");
        return false;
      });

      $(".skip-to-content").attr("href", "#fullPage");

      _aioMobileLayout();
      _servicesRendringCarousel();

      /* SCPMG */
      if ($("#lowFixedRate").length > 0) {
        _getAIORatesAndBuildHTML();
      }
      if ($("#cbRates").length > 0) {
        _getCompetetiorRatesAndBuildHTML();
      }
      /* SCPMG */

      if ($(".full-page-getting-started").length > 0) {
        $("#socialShare").on("click", function (e) {
          e.preventDefault();
          var $social = $(".share-para");
          if ($social.is(":visible")) {
            $social.addClass("hide").next(".share-social").removeClass("hide");
          } else {
            $social.removeClass("hide").next(".share-social").addClass("hide");
          }
        });
        if ($(".getting-started-img-mobile").is(":visible")) {
          _eagleInvesetCarousel();
        }
        _reInitEagleInvesetCarousel();
      }

      // PLOC
      ploc();
      plocMobile();
    };
    return {
      init: init,
    };
  })();
  $(document).ready(function () {
    if (
      $(".fullPage-layout").length > 0 &&
      $(".new-ui").not("#estimator, #textMeApp").length === 0
    ) {
      FRB.fullPage.init(fullPageScroll);
    }
  });
})(window, document, jQuery);

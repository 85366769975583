const MobileDetect = require("mobile-detect");

// Mobile Detector
FRB.mobileDetector = (function () {
  let md;
  const getMd = function () {
    return md;
  };
  const init = function () {
    const isTablet = $(".isTablet");
    const isDesktop = $(".isDesktop");
    const isAppStore = $(".isAppStore");
    const isGoogleStore = $(".isGoogleStore");
    const hideForMobile = $(".hide-for-mobile");
    const isMobileOnly = $(".isMobileOnly");

    md = new MobileDetect(window.navigator.userAgent);

    if (md.mobile()) {
      isDesktop.hide();
    } else {
      $("#signInDrop ul li").css("text-align", "left");
    }
    if (!md.phone()) {
      isMobileOnly.hide();
    } else {
      isTablet.hide();
    }
    if (md.phone()) {
      hideForMobile.hide();
    }
    if (md.os() === "AndroidOS") {
      isGoogleStore.show();
    }
    if (md.os() === "iOS") {
      isAppStore.show();
    }
    if (md.mobile() || md.phone() || md.tablet()) {
      $("body").addClass("touch-enabled");
    }

    // Adjusment for border on homepage
    if ($(window).width() > 640) {
      $(".border-left").css({
        "min-height": `${$(".border-left")
          .siblings(".large-9")
          .innerHeight()}px`,
      });
    }
    $(window).resize(() => {
      if ($(window).width() > 640) {
        $(".border-left").css({
          "min-height": `${$(".border-left")
            .siblings(".large-9")
            .innerHeight()}px`,
        });
      }
    });
    // Adjusment for border on homepage
  };
  return {
    init,
    getMd,
  };
})();

$(document).ready(() => {
  FRB.mobileDetector.init();
});

(function (window, document, $, undefined) {
  FRB.contentTabOrder = (function () {
    var code,
      tabKeyCode = "9",
      enterKeyCode = "13";
    // Skip to Main Content
    var skipToContent = function () {
      var skipToContentBtn = $(".utility-nav--skip-to-content a");
      $("body").keyup(function (e) {
        code = e.keyCode || e.which;
        if (code == tabKeyCode) {
          skipToContentBtn.show().focus();
        }
      });
      $(skipToContentBtn).click(function () {
        $(this).hide();
      });
    };
    var _init = function () {
      skipToContent();
    };
    return {
      init: _init,
    };
  })();

  $(document).ready(function () {
    //FRB.contentTabOrder.init();
  });
})(window, document, jQuery);

//import analytics from "./analytics-custom";

FRB.megamenu = (function () {
  var menuSetTimeOut;

  //Find current page link on menu for Desktop/Mobile
  var _getCurrentPageLink = function (navPath) {
    var pathName = window.location.pathname.toLowerCase();
    if (pathName !== "/") {
      var $nav = $(navPath),
        $currentPageLink = $nav.find("a[href^='" + pathName + "']"); // Fetching the current page link using pathname from URL

      var $currentLink = $currentPageLink.first();
      //If a link in present under more than one menu then highlight the menu which belongs to its original
      if ($currentPageLink.length > 1) {
        var pageName = pathName.split("/")[1].replace(/-/g, ""), // Getting the menu name from the URL
          thisPageName = "",
          count = 0;
        while (count < $currentPageLink.length && pageName !== thisPageName) {
          thisPageName = $($currentPageLink[count])
            .attr("data-ta-gnav-text")
            .split("-")[0]; // Getting the menu name for the link
          if (pageName === thisPageName) {
            $currentLink = $currentPageLink.eq(count);
          }
          count++;
        }
      }
      return $currentLink;
    } else {
      return "";
    }
  };

  var _setSessionStorageMenu = function ($this, className) {
    //Set session storage on click of the link in the Menu
    sessionStorage.menuCurrentID = $this.closest("." + className).attr("id");
    sessionStorage.menuCurrentURL = $this.attr("href");
  };

  var _clearSessionStorageMenu = function () {
    var menuCurrentURL = sessionStorage.menuCurrentURL;
    if (
      menuCurrentURL !== undefined &&
      window.location.pathname
        .toLowerCase()
        .indexOf(menuCurrentURL.substr(0, menuCurrentURL.lastIndexOf("?"))) ===
        -1
    ) {
      //Remove the session storage when URL enter directly in the address bar or link not belongs to Menu
      sessionStorage.removeItem("menuCurrentID");
      sessionStorage.removeItem("menuCurrentURL");
    }
  };

  //Mobile Menu Highlight
  var _highlightActiveMobileMenu = function () {
    _clearSessionStorageMenu();
    // If - Fetch the Active link when links is triggered from the Menu if sessionStorage is not undefined
    // Else - Fetch the Active link when URL enter directly in the address bar or links not triggered from the Menu
    var mobileNavClass = ".mobile-nav--component ul li",
      $currentPageLink =
        sessionStorage.menuCurrentID !== undefined
          ? $("#" + sessionStorage.menuCurrentID).find(
              "a[href='" + sessionStorage.menuCurrentURL + "']"
            )
          : _getCurrentPageLink(mobileNavClass);

    if ($currentPageLink.length > 0) {
      $(mobileNavClass + " .wrapper-active")
        .removeClass("wrapper-active")
        .find("a")
        .removeClass("active");
      $currentPageLink.addClass("active");
      var $closest = $currentPageLink.closest("li.accordion-navigation");
      var $closestActive = $currentPageLink.closest("li,div"),
        linkArray = [];
      $closestActive.addClass("wrapper-active");

      //Storing all expand/collapse menu in child to parent hierarchy for current page link
      while ($closest.length > 0) {
        linkArray.push($closest.find("> a"));
        $closest = $closest.parent().closest("li.accordion-navigation");
      }
      if (linkArray.length > 0) {
        linkArray.reverse(); //Changing the hierarchy to parent - child from child - parent
        $.each(linkArray, function (i, $link) {
          // Triggering the click event for expanding the menu in parent to child hierarchy
          $link.trigger("click");
        });
      }
    }
  };

  //Desktop Menu Highlight
  var _highlightActiveDesktopMenu = function () {
    _clearSessionStorageMenu();
    var highlightMenuID;
    if (sessionStorage.menuCurrentID !== undefined) {
      // Fetch the Menu ID when links is triggered from the Menu
      highlightMenuID = sessionStorage.menuCurrentID;
    } else {
      // Fetch the Menu ID when URL enter directly in the address bar or links not triggered from the Menu
      var $currentPageLink = _getCurrentPageLink(
        ".main-nav--container .megamenu"
      );
      if ($currentPageLink.length > 0) {
        highlightMenuID = $currentPageLink.closest(".megamenu").attr("id");
      }
    }
    if (highlightMenuID !== undefined) {
      $(".main-nav")
        .find("a")
        .removeClass("active")
        .filter("[data-megamenu='" + highlightMenuID + "']")
        .addClass("active");
    }
  };

  var _closeActiveMobileMenu = function () {
    // Collapsing all the menu item n close of hamburger menu
    $(".mobile-nav--component ul li.accordion-navigation.active > a").trigger(
      "click"
    );
  };

  var _mobileMenuAccordion = function () {
    $(".mobile-nav--component ul.accordion > li.accordion-navigation > a").on(
      "click",
      function (e) {
        e.preventDefault();
        var $next = $(this).next();
        $next
          .slideToggle()
          .parent()
          .siblings(".active")
          .removeClass("active")
          .find(" > .content")
          .slideUp()
          .removeClass("active");
        if (!$next.hasClass("active")) {
          $next.slideDown().addClass("active").parent().addClass("active");
        } else {
          $next.removeClass("active").parent().removeClass("active");
        }
      }
    );
  };

  var init = function () {
    /*TODO:: Remove data attribute for mobile. Bug #67093 */
    //setTimeout(function () {
    if ($(window).width() < 641) {
      $(".utilityItem-locations-mobile").removeAttr("data-dropdown");
    } else {
      $(".utilityItem-locations-mobile").attr("data-dropdown", "locationsDrop");
    }
    $(window).resize(function () {
      if ($(window).width() < 641) {
        $(".utilityItem-locations-mobile").removeAttr("data-dropdown");
      } else {
        $(".utilityItem-locations-mobile").attr(
          "data-dropdown",
          "locationsDrop"
        );
      }
    });

    // Call Highlight function only for Desktop
    if ($(".main-nav").is(":visible")) {
      _highlightActiveDesktopMenu();
    }
    _mobileMenuAccordion();

    // Open the Menu
    var $currentMenu = "",
      currentMenuTimeout;
    var menuAction = function (thisElem) {
      //Remove active class when mouseover on the menu
      $currentMenu = $(".active[data-megamenu]");
      if ($currentMenu.length > 0) {
        clearTimeout(currentMenuTimeout);
        $currentMenu.removeClass("active");
      }

      $(thisElem).addClass("expand");
      $("#" + $(thisElem).data("megamenu"))
        .addClass("expand")
        .attr("aria-hidden", false);
    };
    // Close the Menu
    var closeMenu = function () {
      clearTimeout(menuSetTimeOut);
      var $currentActiveMenu = $(".expand[data-megamenu]");
      // Ensure all other items are closed or closing
      $(".megamenu").removeClass("expand").attr("aria-hidden", true);
      $("[data-megamenu]").removeClass("expand");

      //Add active class to the current page when mouseout of menu
      if ($currentMenu.length > 0) {
        if ($currentMenu[0] === $currentActiveMenu[0]) {
          $currentMenu.addClass("active");
        } else {
          currentMenuTimeout = setTimeout(function () {
            // Settimeout is given to eliminate the duplicate arrow on mouseover of menu
            $currentMenu.addClass("active");
          }, 200);
        }
      }
    };
    $("[data-megamenu]")
      .on("mouseenter keydown", function (e) {
        if (e.type == "mouseenter" || e.keyCode == 13) {
          e.preventDefault();
          var thisElem = this;
          closeMenu();
          // Open up this specific menu item
          menuSetTimeOut = setTimeout(function () {
            menuAction(thisElem);
            if (e.keyCode == 13) {
              $(".megamenu.expand").focus();
            }
          }, 250);
        }
      })
      .on("click", function (e) {
        e.preventDefault();
        closeMenu();
        // Open up this specific menu item
        menuAction(this);
        return false;
      });

    $(".js-main-nav--container")
      .on("mouseleave", function () {
        closeMenu();
      })
      .find(".megamenu a")
      .on("click", function () {
        //Set session storage on desktop menu click
        _setSessionStorageMenu($(this), "megamenu");
      });

    //Set session storage on mobile menu click
    $(
      ".mobile-nav--component ul li:not(.accordion-navigation):not(.category-menu-item)"
    ).on("touchstart MSPointerDown", "a", function () {
      _setSessionStorageMenu($(this), "content");
    });

    //Close Mobile Menu
    $(".close-hamburger-menu").on("click", function () {
      $(".exit-off-canvas").trigger("click");
    });

    if ($(".locations-menu").length) {
      $(".locations-map--mobile")
        .find("a, button, iframe")
        .add($(".touch-enabled .js-locations-menu--target-filters *:focusable"))
        .attr("tabindex", "-1");
      $(".locations-map--mobile, .touch-enabled .locations-filters").attr(
        "aria-hidden",
        "true"
      );
      // If an element with class ".locations-menu" exists on the page
      // Bind an 'on click' event to the '.js-locations-menu--trigger-filter'
      // link that will toggle a class on it's target.
      $(".js-locations-menu--trigger-filters").on("click", function (e) {
        e.preventDefault();
        var $targetFilters = $(".js-locations-menu--target-filters"),
          $targetFocus = $targetFilters.find("*:focusable");

        $targetFilters.toggleClass("is-on");

        if ($targetFilters.hasClass("is-on")) {
          $targetFocus.removeAttr("tabindex");
          $targetFilters
            .attr("aria-hidden", "false")
            .find("*:focusable:first")
            .focus();
          $(this).attr("aria-expanded", "true");
        } else {
          $targetFocus.attr("tabindex", "-1");
          $targetFilters.attr("aria-hidden", "true");
          $(this).attr("aria-expanded", "false");
        }
        //setTimeout(function () {
        //    if ($('.js-locations-menu--target-map').hasClass('is-on')) {
        //        $(".js-locations-menu--target-map").css("top", $(".left-top-bg").innerHeight());
        //    }
        //}, 500);
      });
      // Bind an 'on click' event to the '.js-locations-menu--trigger-map'
      // link that will toggle the map into view.
      $(".js-locations-menu--trigger-map").on("click", function (e) {
        e.preventDefault();
        var $targetMap = $(".js-locations-menu--target-map");
        $targetMap.toggleClass("is-on");
        if ($targetMap.hasClass("is-on")) {
          $(".locations-landing-wrapper").css({
            height: "100vh",
            overflow: "hidden",
          });
          $(".locations-map--mobile")
            .attr("aria-hidden", "false")
            .find("a, button, iframe")
            .removeAttr("tabindex");
          $(".locations-list--container")
            .attr("aria-hidden", "true")
            .hide()
            .find("*:focusable")
            .attr("tabindex", "-1");
          $(".js-locations-menu--target-map").css(
            "top",
            $(".left-top-bg").innerHeight()
          );
        } else {
          $(".locations-landing-wrapper").css({
            height: "auto",
            overflow: "auto",
          });
          $(".locations-map--mobile")
            .attr("aria-hidden", "true")
            .find("a, button, iframe")
            .attr("tabindex", "-1");
          $(".locations-list--container")
            .attr("aria-hidden", "false")
            .show()
            .find("*:focusable")
            .removeAttr("tabindex");
        }
        $(this).text(function (i, text) {
          text = text === "Map" ? "List" : "Map";
          if (analytics.isTrackAnalitycsLoaded())
            analytics.ttmLocationToggleMapList(text);
          return text;
        });
      });
    }
  };
  return {
    init: init,
    highlightActiveMobileMenu: _highlightActiveMobileMenu,
    highlightActiveDesktopMenu: _highlightActiveDesktopMenu,
    closeActiveMobileMenu: _closeActiveMobileMenu,
  };
})();

$(document).ready(function () {
  FRB.megamenu.init();
});

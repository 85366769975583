(function (window, document, $, undefined) {
  // Temporary fix for "title" property of <img />
  FRB.imgManipulateTitleProperty = (function () {
    var init = function () {
      $("body img[title]").each(function (index, value) {
        $(this).prop("title", "");
      });
    };
    return {
      init: init,
    };
  })();

  FRB.marketoForm = (function () {
    // Marketo Hidden forms JS implementation.
    var init = function () {
      if ($(".marketo-hidden-form").length > 0) {
        //console.log("Marketo insert hidden form, Marketo children count=" + $(".marketo-hidden-form").children().length);

        // Load the form on the page first
        const mktoFormId = $(".marketo-hidden-form")[0].id;
        let formId;

        if (mktoFormId !== null && mktoFormId !== undefined) {
          formId = mktoFormId.split("_").pop();
        }

        try {
          if (typeof MktoForms2 !== 'undefined') {
            MktoForms2.loadForm(
              "https://pages.firstrepublic.com",
              marketoEnvironment,
              formId
            );
          } else {
            console.log("*** !loading Marketo Library://page.firstrepublic.com Fail! ***");
          }
        } catch (e) {
          console.log("*** !loading Marketo Library://page.firstrepublic.com Fail! ***");
        }
      }
    };
    return {
      init: init,
    };
  })();

  FRB.articleMobile = (function () {
    var init = function () {
      $(".page-constraint").on("change", "#articleTopics", function () {
        var link = $(this).val();
        if (link !== "") {
          location.href = link;
        }
      });
    };
    return {
      init: init,
    };
  })();

  FRB.linkTotop = (function () {
    var md,
      linkShown = false;

    var _init = function () {
      md = FRB.mobileDetector.getMd();
      if ($(".article-content").length && (md.mobile() || md.phone())) {
        $(".page-constraint.bottom").append("<div class='link-up'></div>");
        $(window).scroll(function () {
          if (
            $(window).scrollTop() >=
            ($(document).height() - $(window).height()) * 0.5
          ) {
            $(".link-up").addClass("show");
            linkShown = true;
          } else {
            $(".link-up").removeClass("show");
          }
        });
      }
      $(".page-constraint.bottom").on("click", ".link-up.show", function (e) {
        $("html, body").animate({ scrollTop: 0 }, 400);
      });
    };
    return {
      init: _init,
    };
  })();

  FRB.tabToFocus = (function () {
    var _hideEmptyAlt = function () {
      $(".tab-wrapper img").each(function () {
        var $this = $(this),
          altText = $this.attr("alt");
        if (altText === undefined || altText === "") {
          $this.parent().attr({ "aria-hidden": true });
        }
      });
    };
    var _init = function () {
      $(window).keyup(function (e) {
        var $target = $(":focus");
        if (e.keyCode === 9) {
          $target.addClass("tab-focus");
          if ($target.hasClass("heading-link")) {
            $target.closest(".tab-wrapper").addClass("tab-focus");
          }

          if (
            $(
              "#wffmbcadc9c98ed54dd2bb1e694d1e160c64_Sections_3__Fields_0__Value"
            ).hasClass("tab-focus")
          ) {
            $("label.button-upload.secondary")
              .addClass("tab-focus")
              .css("outline-offset", "2px");
          }
        }
      });
      $(document).on("blur", "*:focusable", function () {
        $(".tab-focus").removeClass("tab-focus");
      });
      $(".pwm-svg svg").attr("focusable", "false"); //prevent svg got tab focus in pwm page

      _hideEmptyAlt();
    };
    return {
      init: _init,
    };
  })();

  FRB.accordionSmooth = (function () {
    var classes = {
      component: "accordion",
      content: "content",
      active: "active",
    },
      md;

    var _getScrollDirection = function (top) {
      var currentPos = $(window).scrollTop();
      return currentPos <= top ? "Down" : "Up";
    };
    var _scrollPageHashTag = function (e) {
      // Scroll the page to the active FAQ link only on hashtag change
      var hashTag = window.location.hash;
      if (hashTag) {
        var $accHashTag = $(
          ".faq ." +
          classes.component +
          " > li a[data-itemname='" +
          hashTag +
          "']"
        );
        if ($accHashTag.length && e.originalEvent === undefined) {
          // Execute if condition only on Hashtag change and not on click event
          var top = $accHashTag.offset().top;
          if (md.mobile()) {
            top -= $(".global-nav .fixed-header").height(); // Minus the top value from fixed header top section height for mobile on Scroll Up & Down
            var scrollDirection = _getScrollDirection(top);
            if (scrollDirection === "Up") {
              top -= $(".global-nav .contact-us").height(); // Minus the top value from fixed header bottom section height for mobile on Scroll Up
            }
          }
          $("html, body").animate(
            {
              scrollTop: top,
            },
            500
          );
        }
      }
    };

    var _init = function () {
      md = FRB.mobileDetector.getMd();
      $(".accordion-smooth")
        .off("click")
        .on("click", "li.accordion-navigation > a", function (e) {
          var $this = $(this),
            $thisParent = $this.closest("li"),
            $mainParent = $thisParent.closest(".accordion-smooth");
          if ($mainParent.attr("data-noprevent") === undefined) {
            e.preventDefault();
            e.stopPropagation();
          }
          $this.attr(
            "aria-selected",
            $this.attr("aria-selected") === "false" ? "true" : "false"
          );
          $thisParent
            .toggleClass(classes.active)
            .find(" > ." + classes.content)
            .slideToggle(function () {
              if ($mainParent.attr("data-hashscroll") === "true") {
                _scrollPageHashTag(e);
              }
            })
            .attr(
            "aria-hidden",
            $this.attr("aria-selected") === "false" ? "true" : "false"
            );
          var $siblings = $thisParent.siblings();
          if ($mainParent.attr("data-siblings") === "true") {
            $siblings = $siblings.add(
              $thisParent.closest("ul").parent().siblings().find("li")
            );
          }
          $siblings
            .removeClass(classes.active)
            .find("> a")
            .attr("aria-selected", false)
            .siblings("." + classes.content)
            .attr("aria-hidden", "true")
            .slideUp()
            .find("li")
            .removeClass(classes.active);
        });
    };
    return {
      init: _init,
    };
  })();

  FRB.createErrorMessage = (function () {
    var options = {
      error: "error-message",
      errorMsg: "",
      $input: "",
      isBlurFocus: false,
    };

    var _init = function (values) {
      $.extend(true, options, values);
      if (options.$input !== "") {
        var $form = options.$input.closest("form");
        if (options.errorMsg !== "") {
          $errorMsg = $form.find("." + options.error);
          if ($errorMsg.length > 0) {
            $errorMsg.html(options.errorMsg);
          } else {
            $form.append(
              '<label for="' +
              options.$input.attr("id") +
              '" class="' +
              options.error +
              '">' +
              options.errorMsg +
              "</label>"
            );
          }
        } else {
          $form.find("." + options.error).remove();
        }

        if (options.isBlurFocus === true) {
          options.$input.blur();
          setTimeout(function () {
            options.$input.focus();
          }, 150);
        }
      }
    };
    return {
      init: _init,
    };
  })();

  $(document).ready(function () {
    //temporary fix for
    FRB.imgManipulateTitleProperty.init();
    FRB.articleMobile.init();
    FRB.linkTotop.init();
    //Tab Focus on Keyboard
    FRB.tabToFocus.init();
    FRB.accordionSmooth.init();
    FRB.marketoForm.init();
  });
})(window, document, jQuery);

FRB.ADAImplementation = (function () {
  const closeSigninDrop = function () {
    $("#sign-in-button > a").attr("aria-expanded", false);
    $("#signInDrop").css({ display: "none" }).attr("aria-hidden", true);
  };

  const closeMenu = function () {
    $(".main-nav--container .megamenu.expand, .main-nav a.expand").removeClass(
      "expand"
    );
  };

  const imgFocused = function ($this, keyEvt) {
    const $imgObj = $this.find("img");
    if ($imgObj.length !== 0) {
      if (keyEvt === "up") {
        $imgObj.addClass("ele-focused");
      } else {
        $imgObj.removeClass("ele-focused");
      }
    }
  };

  const headerTabbing = function () {
    // Location, Contact Us
    $(".has-drop")
      .on("keydown", ".f-dropdown.open *:focusable:last", function (e) {
        if (!e.shiftKey && e.keyCode === 9) {
          $(this)
            .closest(".f-dropdown.open")
            .prev()
            .trigger("click.fndtn.dropdown"); // Closing Contact Us/Location fly out on blur of last link in the fly out on Tab key
        }
      })
      .on("keydown", ".f-dropdown.open *:focusable:first", function (e) {
        if (e.shiftKey && e.keyCode === 9) {
          $(this)
            .closest(".f-dropdown.open")
            .prev()
            .trigger("click.fndtn.dropdown"); // Closing Contact Us/Location fly out on blur of first link in the fly out on Shift + Tab key
        }
      });

    // Sign-in
    $("#sign-in-button > a").on("keydown touchend", function (e) {
      if (e.keyCode === 13 || e.type === "touchend") {
        $(this).attr("aria-expanded", true);
        $("#signInDrop").css({ display: "block" }).attr("aria-hidden", false); // Show Sign-in Fly out on Sing-in link using Enter key
      }
      if (e.shiftKey && e.keyCode === 9) {
        closeSigninDrop();
      }
    });
    // eslint-disable-next-line consistent-return
    $("#signInDrop").on("keydown", "ul li:first-child a", (e) => {
      if (e.shiftKey && e.keyCode === 9) {
        closeSigninDrop(); // Hide Sign-in Fly out on blur of first link using Shift + Tab key
        $("#sign-in-button > a").focus();
        return false;
      }
    });
    $(".main-nav li:first-child a").on("focus", () => {
      closeSigninDrop(); // Hide Sign-in Fly out on main menu focus using Tab key
    });

    // Adding class name to focused image
    $("body")
      .on("keyup", "a", function () {
        imgFocused($(this), "up");
      })
      .on("keydown", "a", function () {
        imgFocused($(this), "down");
      });

    // Main Navigation
    $(".main-nav--container")
      .on("keydown", ".megamenu.expand a:last", function (e) {
        if (!e.shiftKey && e.keyCode === 9) {
          e.preventDefault();
          const id = $(this).closest(".megamenu").attr("id");
          const $nextEle = $(`.main-nav a[data-megamenu=${id}]`)
            .closest("li")
            .next()
            .find("a[data-megamenu]");
          closeMenu();
          if ($nextEle.length !== 0) {
            // Focusing Next Main Menu on Tab key from last link of Sub Menu
            $nextEle.focus();
          } else {
            // Focus on Search field on Tab key from last link of Sub Menu
            $(".search-field  #search-input").focus();
          }
        }
      })
      .on("keydown", ".megamenu.expand a:first", function (e) {
        if (e.shiftKey && e.keyCode === 9) {
          // Shift + Tab key focusing Main Menu from Sub Menu
          e.preventDefault();
          const id = $(this).closest(".megamenu").attr("id");
          closeMenu();
          $(`.main-nav a[data-megamenu=${id}]`).focus();
        }
      });

    // Change magnifying glass color on search input focus
    $(".search .search-input")
      .focusin(function () {
        $(this).next().children("i").css("color", "#000");
      })
      .focusout(function () {
        $(this).next().children("i").css("color", "#d1d0cb");
      });
  };

  const preventRevealClose = function ($this) {
    if ($this.closest(".reveal-modal").is(":visible")) {
      $("[data-reveal]").data("revealInit").close_on_esc = false;
      setTimeout(() => {
        $("[data-reveal]").data("revealInit").close_on_esc = true;
      }, 300);
    }
  };

  const contentTabbing = function () {
    const shareClass =
      ".icon-buttons.component .icon-buttons--link.js-simple-show-hide--trigger";
    let isMouseDown = false;
    // Share Link Expand / Collapse on Keyboard
    $(document)
      .on("keydown", shareClass, function (e) {
        // Show Share link fly out on Enter and Space Key ---> e.keyCode === 13 || e.keyCode === 32
        // Hide Share link fly out on Esc Key when focus is on Share link ---> e.keyCode === 27 && $(this).next().hasClass("is-on")
        // Hide Share link fly out on blur of Share link using Shift + Tab key ---> e.shiftKey && e.keyCode == 9 && $(this).next().hasClass("is-on")
        const { keyCode } = e;
        if (
          keyCode === 32 ||
          ((keyCode === 27 || (e.shiftKey && keyCode === 9)) &&
            $(this).next().find("ul").hasClass("is-on"))
        ) {
          e.preventDefault();
          preventRevealClose($(this));
          $(this).trigger("mousedown");
        }
      })
      .on("keydown", ".icon-buttons--slide-out a", function (e) {
        if ($(this).parent().is(":last-child")) {
          if (!e.shiftKey && e.keyCode === 9) {
            $(this)
              .closest(".icon-buttons--slide-out-wrapper")
              .prev()
              .trigger("mousedown"); // Hide Share link fly out on blur from the fly out links
          }
        }
        if (e.keyCode === 27) {
          preventRevealClose($(this));
          $(this)
            .closest(".icon-buttons--slide-out-wrapper")
            .prev()
            .trigger("mousedown")
            .focus()
            .addClass("tab-focus"); // Hide Share link fly out on Esc key when focus is on fly out links
        }
      })
      .on("mousedown", shareClass, (e) => {
        if (e.originalEvent !== undefined) {
          isMouseDown = true;
        }
      })
      .on("click", shareClass, function (e) {
        if (isMouseDown === false) {
          e.preventDefault();
          $(this).trigger("mousedown");
        }
        isMouseDown = false;
      });

    // Remove duplicate ID's for Share link if repeat more than once in a page
    const $shareLayout = $(".icon-buttons--slide-out");
    if ($shareLayout.length > 1) {
      $shareLayout.each(function (i) {
        if (i > 0) {
          const id = $(this).attr("id") + i;
          $(this)
            .attr("id", id)
            .closest(".icon-buttons--item")
            .find(".icon-buttons--link")
            .attr("aria-controls", id);
        }
      });
    }

    // Tab
    $(".tab-panels")
      .on(
        "keydown",
        "#custom-accordion li.active *:focusable:not([tabindex=-1]):visible:last",
        (e) => {
          if (!e.shiftKey && e.keyCode === 9) {
            const $ele = $("#custom-tab-panel > li.active").next().find("a"); // From Tab content last link focusing to Next Tab on Tab key
            if ($ele.length !== 0) {
              e.preventDefault();
              $ele.trigger("click");
            }
          }
        }
      )
      // eslint-disable-next-line consistent-return
      .on("keydown", "#custom-tab-panel li a", function (e) {
        if (!e.shiftKey && e.keyCode === 9) {
          // Focusing on first link on Tab key from Tabs
          e.preventDefault();
          $(this).trigger("click");
          const $eleLink = $(
            "#custom-accordion li.active .content *:focusable:visible:first"
          );
          if ($eleLink.length !== 0) {
            $eleLink.focus(); // Focus on content first link from the active Tabs on Tab key
          } else {
            const $nextTab = $(this).closest("li").next().find("a");
            if ($nextTab.length !== 0) {
              $(this).closest("li").next().find("a").trigger("click"); // Focus on next Tabs if reaches last link in the content using Tab key
            } else {
              const $links = $("*:focusable:visible");
              $links.get($links.index($(this)) + 1).focus(); // Focus next link if its lat tab and not having links in tab content
            }
          }
          return false;
        }
        if (e.shiftKey && e.keyCode === 9) {
          const $ele = $(this).closest("li").prev().find("a");
          e.preventDefault();
          if ($ele.length !== 0) {
            // Focusing prevoius Tab link on Shift + Tab  key
            $ele.trigger("click");
            return false;
          } // Focusing previous link on Shift + Tab from First Tab key
          const $links = $("*:focusable:visible");
          const $focused = $(":focus");
          const focusPos = $links.index($focused);
          const $focusLink = $($links.get(focusPos - 1));
          const $focusParent = $focusLink.closest("ul.icon-buttons--slide-out");

          if ($focusParent.length > 0 && !$focusParent.hasClass("is-on")) {
            // Checking condition for Share link
            $focusLink.closest("ul").parent().prev().focus();
            return false;
          }
          $focusLink.focus();
          return false;
        }
        if (e.keyCode === 39) {
          $(this).closest("li").next().find("a").trigger("click");
        } else if (e.keyCode === 37) {
          $(this).closest("li").prev().find("a").trigger("click");
        }
      });
  };

  // Tabbing order for OLB Login Page
  const OLBLoginFormTabbing = function () {
    const links = document
      .querySelector(".bo-support")
      .getElementsByTagName("a");
    const lastLink = links[links.length - 1];
    let lastLinkTabindex = parseInt(lastLink.getAttribute("tabindex"));
    const linksArray = [
      ...document.querySelectorAll(".bo-banner a"),
      ...document.querySelectorAll(".bo-banner+div a"),
      ...document.querySelectorAll(".main-footer a"),
    ];

    linksArray.forEach((item) => {
      item.setAttribute("tabindex", lastLinkTabindex + 1);
      lastLinkTabindex += 1;
    });
  };

  // Home Page Carousel Tabbing Order
  const homeCarousel = function () {
    const carouselClass = ".carousel-wrapper";
    const $carousel = $(carouselClass);
    const $searchBtn = $("#search-input").siblings("button");
    const $headerAlert = $(".header-alert");

    const sliderFocus = function () {
      if ($(".slick-dots li").length > 0) {
        $carousel.find(".slick-dots li:first-child button").focus(); // Focus on first slider button on Blur of Search Button ---> Tab Key
      } else {
        $carousel
          .find(".slick-track .slick-slide.slick-active *:focusable:first")
          .focus(); // Focus on first focusable element inside active slider content  ---> Tab Key
      }
    };

    const headerFocus = function () {
      if (!$headerAlert.is(":visible")) {
        $searchBtn.focus(); // Focus on Search Button on blur on first slider button -> Shift + Tab Key
      } else {
        $headerAlert.find("*:focusable:last").focus();
      }
    };

    // eslint-disable-next-line consistent-return
    $searchBtn.on("keydown", (e) => {
      if (!e.shiftKey && e.keyCode === 9) {
        if (!$headerAlert.is(":visible")) {
          sliderFocus();
          return false;
        }
      }
    });

    // eslint-disable-next-line consistent-return
    $headerAlert.on("keydown", "*:focusable:last", (e) => {
      if (!e.shiftKey && e.keyCode === 9) {
        sliderFocus();
        return false;
      }
    });

    $carousel
      // eslint-disable-next-line consistent-return
      .on("keydown", ".slick-dots li button", function (e) {
        const $parent = $(this).parent();
        if (!e.shiftKey && e.keyCode === 9) {
          if ($parent.hasClass("slick-active")) {
            $carousel
              .find(".slick-track .slick-slide.slick-active *:focusable:first")
              .focus(); // Focus on first focusable element inside active slider content  ---> Tab Key
            return false;
          }
          if ($parent.is(":last-child")) {
            $carousel
              .next()
              .find("*:focusable:not([tabindex=-1]):first")
              .focus(); // Focus on first focusable element after the Caruosel layout on blur of last slider button if the last slider is not active ---> Tab Key
            return false;
          }
          if (!$parent.hasClass("slick-active") && !$parent.is(":last-child")) {
            $parent.next().find("button").focus(); // Focus on next button ---> Tab Key
            return false;
          }
        }
        if ($parent.index() === 0 && e.shiftKey && e.keyCode === 9) {
          headerFocus();
          return false;
        }
        if (e.shiftKey && e.keyCode === 9) {
          if ($parent.prev().hasClass("slick-active")) {
            $carousel
              .find(".slick-track .slick-slide.slick-active *:focusable:last")
              .focus(); // Focus on last focusable element inside active slider content on blur of next active button ---> Shift + Tab Key
            return false;
          }
          if (
            !$parent.prev().hasClass("slick-active") &&
            !$parent.is(":first-child")
          ) {
            $parent.prev().find("button").focus(); // Focus on previous button ---> Shift + Tab Key
            return false;
          }
        }
      })
      .on(
        "keydown",
        ".slick-track .slick-slide.slick-active *:focusable:last",
        // eslint-disable-next-line consistent-return
        function (e) {
          if (!e.shiftKey && e.keyCode === 9) {
            if ($(this).closest(".slick-slide").is(":last-child")) {
              $carousel
                .next()
                .find("*:focusable:not([tabindex=-1]):first")
                .focus(); // Focus on first focusable element after the Caruosel layout on blur when last focusable element in slider content if last slider is active ---> Tab Key
              return false;
            }
            $(`${carouselClass} .slick-dots li.slick-active`)
              .next()
              .find("button")
              .focus(); // Focus on slider button next to active slide button on blur wehn last focusable element in slider content if last slider is not active ---> Tab Key
            return false;
          }
        }
      )
      .on(
        "keydown",
        ".slick-track .slick-slide.slick-active *:focusable:first",
        // eslint-disable-next-line consistent-return
        (e) => {
          if (e.shiftKey && e.keyCode === 9) {
            if ($(".slick-dots li").length > 0) {
              $(`${carouselClass} .slick-dots li.slick-active button`).focus(); // Focus on active slider button on blur of first focusable element in the active slider content ---> Shift + Tab Key
              return false;
            }
            headerFocus();
            return false;
          }
        }
      )
      .on("keydown", "*:focusable", (e) => {
        if (e.keyCode === 9) {
          $(".tab-focus").removeClass("tab-focus");
        }
      })
      .next()
      // eslint-disable-next-line consistent-return
      .on("keydown", "*:focusable:not([tabindex=-1]):first", (e) => {
        if (e.shiftKey && e.keyCode === 9) {
          if (
            $(`${carouselClass} .slick-dots li:last-child`).hasClass(
              "slick-active"
            )
          ) {
            $(
              `${carouselClass} .slick-track .slick-slide.slick-active *:focusable:last`
            ).focus(); // Focus on last focusable element in the ative slider content on blur of first focusbale element after the Caruosel layout when the last slide is active ---> Shift + Tab Key
            return false;
          }
          if ($(".slick-dots li").length > 0) {
            $(`${carouselClass} .slick-dots li:last-child button`).focus(); // Focus on last slider button on blur of first focusbale element after the Caruosel layout when the last slide is not active ---> Shift + Tab Key
            return false;
          }

          $carousel
            .find(".slick-track .slick-slide.slick-active *:focusable:last")
            .focus(); // Focus on first focusable element inside active slider content  ---> Tab Key
          return false;
        }
      });
  };

  const trapFocusOnReveal = function () {
    let $ele;
    $(document)
      .on("opened.fndtn.reveal", "[data-reveal]", function () {
        const $this = $(this);
        $ele = $this.siblings(":not(script):not(style):not([aria-hidden])");
        $ele.attr("aria-hidden", true);
        $this.focus();
      })
      .on("close.fndtn.reveal", "[data-reveal]", () => {
        if ($ele !== undefined) {
          $ele.removeAttr("aria-hidden");
        }
      });
    $("[data-reveal]")
      // eslint-disable-next-line consistent-return
      .on("keydown", ".close-reveal-modal, .btn-continue", function (e) {
        if (!e.shiftKey && e.keyCode === 9) {
          $(this).closest(".reveal-modal").focus();
          return false;
        }
      })
      // eslint-disable-next-line consistent-return
      .on("keydown", function (e) {
        if (e.shiftKey && e.keyCode === 9 && e.target === this) {
          $(this).find(".close-reveal-modal, .btn-continue").focus();
          return false;
        }
      });
  };

  const returnFocusOnRevealClose = function () {
    let $linkModal = "";
    $(document).on("open.fndtn.reveal", "[data-reveal]", () => {
      $linkModal = $(":focus");
    });
    $(document).on("closed.fndtn.reveal", "[data-reveal]", () => {
      if ($linkModal !== "") {
        $linkModal.focus();
        $linkModal = "";
      }
    });
  };

  const mapInfoWindowFocusPrevent = function () {
    // Info Window Focus Prevent
    $("#map-canvas")
      // eslint-disable-next-line consistent-return
      .on("keydown", ".info-window:visible", function (e) {
        if (e.shiftKey && e.keyCode === 9 && e.target === this) {
          $(".info-window:visible > [role=button]").focus();
          return false;
        }
      })
      // eslint-disable-next-line consistent-return
      .on("keydown", ".info-window:visible > [role=button]", (e) => {
        if (!e.shiftKey && e.keyCode === 9) {
          $(".info-window:visible").focus();
          return false;
        }
      });
  };

  // eslint-disable-next-line consistent-return
  const mobileSearchFocus = function ($this) {
    if ($this.parent().hasClass("is-on")) {
      $this.attr({ tabindex: "-1", "aria-hidden": "true" });
      $("#search-input-mob").focus();
      return false;
    }
  };

  const mobileMenu = function () {
    // Focus on Search Field on Keydown
    $(document).on(
      "keydown",
      ".mobile-nav--search .js-simple-show-hide--trigger",
      // eslint-disable-next-line consistent-return
      function (e) {
        if (e.keyCode === 13) {
          $(this).trigger("mousedown");
          return mobileSearchFocus($(this));
        }
      }
    );
    // Focus on Search Field on Mouse Click
    $(".mobile-nav--search__show").on("click", function () {
      mobileSearchFocus($(this));
    });
    $(".mobile-nav--search__cancel").on("click", () => {
      $("#search-input-mob").autocomplete("close").val("");
      $(".mobile-nav--search__show")
        .attr({ tabindex: "0", "aria-hidden": "false" })
        .focus();
    });
  };

  const preventMobileScrollOnFocus = function () {
    // Foundation Overwrite. Preventing Scroll for Mobile devices on focus
    if (
      navigator.userAgent.match(
        /iPad|iPhone|Android|BlackBerry|Windows Phone|webOS/i
      )
    ) {
      // Given the same devices which are present in foundation.abide.js
      $("form[data-abide]").find("input, textarea, select").off("focus");
    }
  };

  const clientTestimonialGridFocusEvent = function () {
    $(".annual-report__list ul.ar-share li").not(":nth-child(1)").remove();

    $("#dynamicContent, .annual-report__list")
      .on(
        "focus mouseenter touchstart MSPointerDown",
        ".full-width-grid__item--description",
        function (e) {
          if (e.type === "focus" || e.type === "focusin") {
            $(this).addClass("tab-focus");
          }
          $(this)
            .attr("aria-hidden", "false")
            .closest("li")
            .addClass("active")
            .find(".full-width-grid__image, .bottom")
            .attr("aria-hidden", "true");
          $(this).find(".bottom").removeAttr("aria-hidden");
        }
      )
      .on(
        "blur mouseleave touchend MSPointerUp",
        ".full-width-grid__item--description",
        function (e) {
          if (e.type === "blur" || e.type === "focusout") {
            $(this).removeClass("tab-focus");
          }
          $(this)
            .attr("aria-hidden", "true")
            .closest("li")
            .removeClass("active")
            .find(".full-width-grid__image, .bottom")
            .attr("aria-hidden", "false");
          $(this).find(".bottom").removeAttr("aria-hidden");
        }
      );
  };

  // Foundation tooltip ADA Compliance
  const tooltipOnTabFocus = function () {
    const classes = {
      toolTip: "tooltip",
      close: "close-fndtn-tooltip",
      external: "external-link",
      tabFocus: "tab-focus",
    };
    $(window).keyup((e) => {
      const $target = $(":focus");
      if (e.keyCode === 9 && $target.hasClass(classes.external)) {
        const id = $target.attr("aria-describedby");
        const $tooltip = $(`#${id}`);
        // On Tab focus appending Close button to the tooltip and adding tabindex to the tooltip for focus
        $tooltip
          .append(
            `<a href="javascript:;" class="${classes.close}" role="button" aria-label="Close Tooltip">&#215;</a>`
          )
          .attr("tabindex", "0");
        // And trigger the foundation mouseeneter event to show the tooltip since only on mouseenter tooltip will open in foundation
        $target.find("[data-tooltip]").trigger("mouseenter");
        setTimeout(() => {
          // Set focus to the tooltip with 200ms timedelay as tooltip will take 200ms second to open
          $tooltip.focus().addClass(classes.tabFocus);
        }, 200);
      }
    });
    $(document)
      .on("click", `.${classes.toolTip} .${classes.close}`, function () {
        // Closing the tooltip on enter key on close button by triggering mouseleave event and setting focus back to the link
        $(
          `.${classes.external} [data-selector='${$(this)
            .parent()
            .attr("id")}']`
        )
          .trigger("mouseleave")
          .parent()
          .focus()
          .addClass(classes.tabFocus);
      })
      // eslint-disable-next-line consistent-return
      .on("keydown", `.${classes.toolTip}`, function (e) {
        // Prevent the focus out of tooltip
        if (e.shiftKey && e.keyCode === 9 && e.target === this) {
          $(this).find(`.${classes.close}`).focus();
          return false;
        }
      })
      // eslint-disable-next-line consistent-return
      .on("keydown", `.${classes.toolTip} .${classes.close}`, function (e) {
        // Prevent the focus out of tooltip
        if (!e.shiftKey && e.keyCode === 9) {
          $(this).closest(`.${classes.toolTip}`).focus();
          return false;
        }
      })
      .on("mouseleave", `.${classes.external} [data-selector]`, function () {
        // Removing tabindex and close button on close of the tooltip
        const id = $(this).attr("data-selector");
        $(`#${id}`).removeAttr("tabindex").find(`.${classes.close}`).remove();
      });
  };

  const preventLocationDetailsMapFocus = function () {
    const $focusable = $("*:focusable:not(.js-content-swapper *:focusable)");
    const $locationLast = $(".location-detail-info *:focusable:last");
    // eslint-disable-next-line consistent-return
    $locationLast.on("keydown", function (e) {
      if (!e.shiftKey && e.keyCode === 9) {
        const index = $focusable.index($(this));
        $focusable.eq(index + 1).focus();
        return false;
      }
    });
    // eslint-disable-next-line consistent-return
    $focusable.eq($focusable.index($locationLast) + 1).on("keydown", (e) => {
      if (e.shiftKey && e.keyCode === 9) {
        $locationLast.focus();
        return false;
      }
    });
  };

  const checkboxToRadioButton = function (box, myEvent) {
    if (myEvent === "enter") {
      const $box = $(box);
      if ($box.prop("checked")) {
        $box.prop("checked", false);
      } else {
        $box.prop("checked", true);
      }
    }
  };

  // Enable ENTER key for checkboxes
  const toggleCheckboxOnEnter = function () {
    $("input:checkbox").keypress(function (event) {
      const keycode = event.keyCode ? event.keyCode : event.which;
      if (keycode === 13) {
        checkboxToRadioButton(this, "enter");
      }
      event.stopPropagation();
    });
  };

  const init = function () {
    headerTabbing();
    contentTabbing();
    trapFocusOnReveal();
    if ($(".bo-signin-form").length > 0) {
      OLBLoginFormTabbing();
    }
    if ($(".carousel-wrapper").length > 0) {
      homeCarousel();
    }
    returnFocusOnRevealClose();
    mapInfoWindowFocusPrevent();
    mobileMenu();
    if ($("form[data-abide]").length > 0) {
      preventMobileScrollOnFocus();
    }

    clientTestimonialGridFocusEvent();

    tooltipOnTabFocus();

    if ($(".location-detail").length > 0) {
      preventLocationDetailsMapFocus();
    }

    toggleCheckboxOnEnter();
  };
  return {
    init,
  };
})();

$(document).ready(() => {
  FRB.ADAImplementation.init();
});

  $(document).foundation({
    abide: {
      timeout: 0,
      patterns: {
        us_currency: /^\$?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$/,
        interest_rate: /^[-]?\d+([.]\d+)?%?$/,
      },
      validators: {
        cdMinMaxAmount (el) {
          const elNumberValue = el.value.currencyToNumber();
          if ($(el).hasClass("placeholder")) {
            return false;
          }
          return elNumberValue >= 10000 && elNumberValue <= 10000000;
        },
        cdMinMaxPattern (el) {
          return el.value.match(
            /^\$?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$/
          );
        },
        interestRatePattern (el) {
          return el.value.match(/^[-]?\d+([.]\d+)?%?$/);
        },
        interestRateMinMaxAmount (el) {
          const elNumberValue = el.value.percentToNumber();
          if ($(el).hasClass("placeholder")) {
            return false;
          }
          return elNumberValue >= 0.0001;
        },
        termMinMaxLength (el) {
          const elNumberValue = Number(el.value);
          return elNumberValue >= 1 && elNumberValue <= 72;
        },
        selectOptionWithValue (el) {
          const elWithValue = Number(el.value);
          return !(!elWithValue || elWithValue.length === 0);
        },
        ariaRequired (el) {
          const $el = $(el);
          if ($el.val() === "" && $el.attr("aria-required") === "true") {
            $el.attr({
              "aria-invalid": "true",
              "aria-describedby": $el.next().attr("id"),
            });
            return false;
          } 
            $el
              .attr({ "aria-invalid": "false" })
              .removeAttr("aria-describedby");
            return true;
          
        },
      },
    },
    equalizer: { // Foundation Equalizer options
      equalize_on_stack: true,
    },
  });

  /* 1/20/2016: SBruno, clean up the code below */
  $(document).ready(() => {
    // basic scripts for now
    $(".expandable > a").on("click", function (e) {
      e.preventDefault();
      $(this).parent().toggleClass("expand");
    });

    // Simple, Global, Show/Hide scripting
    const simpleHide = () => {
      // Function that, simply, hides a targetted element
      // For this, the following html and class structure
      // is needed:
      /*
                <div class="js-simple-show-hide">
                    <span class="js-simple-show-hide--trigger">I'm a trigger.  I can be any element, even an <a></a>!</span>
                    <div clsss="simple-show-hide--target js-simple-show-hide--target">
                        <p>My parent is the TARGET that needs to be
                        shown or hidden.  Note that I have an
                        additional, non "js-", that is required.</p>
                        <p>To display by default, i need the active
                        class of "is-on", as well.</p>
                        <p>I, the target, could also be on the PARENT
                        element rather than a child inside... I could
                        also be nested deeper in more
                        children/grandchildren elements</p>
                    </div>
                </div>
            */
      const debug = false;
      if (debug) {
        console.log("simpleHide() has been called!");
      }

      const classes = {
        parent: "js-simple-show-hide",
        trigger: "js-simple-show-hide--trigger",
        target: "js-simple-show-hide--target",
        active: "is-on",
      };

      $(document).on(
        "mousedown",
        `.${  classes.parent  } .${  classes.trigger}`,
        function (e) {
          e.preventDefault();
          const $parent = $(this).closest(`.${  classes.parent}`);
          $parent
            .find(`.${  classes.target}`)
            .addBack(`.${  classes.parent}`)
            .toggleClass(classes.active);
          $(this)
            .not(".mobile-nav--search__show")
            .not(".mobile-nav--search__cancel")
            .attr(
            "aria-expanded",
            $parent.hasClass("is-on") ? "true" : "false"
            );
          $parent
            .find(`.${  classes.target}`)
            .attr("aria-hidden", $parent.hasClass("is-on") ? "false" : "true")
            .find("*:focusable")
            .attr("tabindex", $parent.hasClass("is-on") ? "0" : "-1");
          return false;
          // $.cookie('header-alert', 'off');
        }
      );
    }

    // Call the simpleShowHide function to create the appropriate bindings
    simpleHide();

    // jQuery version of simple show hide
    // This version does the same as the simpleShowHide,
    // with the addition of using Modernizr to check media query
    // breakpoints to only show/hide at specific screen sizes.
    const jQueryShowHide = () => {
      // possibly coming soon
      const debug = false;
      if (debug) {
        console.log("jQueryShowHide() has been called!");
      }
      const classes = {
        parent: "jq-simple-show-hide",
        trigger: "jq-simple-show-hide--trigger",
        target: "jq-simple-show-hide--target",
        active: "is-on",
      };
      $(document).on(
        "click",
        `.${  classes.parent  } .${  classes.trigger}`,
        function (e) {
          e.stopPropagation();
          e.preventDefault();
          const $clickedItem = $(this);
          if ($clickedItem.data("expand") === "small") {
            if (Foundation.utils.is_small_only()) {
              $clickedItem
                .closest(`.${  classes.parent}`)
                .children(`.${  classes.target}`)
                .slideToggle()
                .addBack(`.${  classes.parent}`)
                .toggleClass(classes.active);
            }
          }
          if ($clickedItem.data("expand") === "medium") {
            if (Foundation.utils.is_medium_only()) {
              $clickedItem
                .closest(`.${  classes.parent}`)
                .children(`.${  classes.target}`)
                .slideToggle()
                .addBack(`.${  classes.parent}`)
                .toggleClass(classes.active);
            }
          }
        }
      );
    } // function jQueryShowHide

    // Call the jQueryShowHide function
    jQueryShowHide();

    const contentSwap = () => {
      const debug = false;
      if (debug) {
        console.log("contentSwap() has been called!");
      }
      const classes = {
        parent: "js-content-swapper",
        trigger: "js-content-swapper--trigger",
        target: "js-content-swapper--target",
        active: "is-on",
      };
      $(`.${  classes.parent}`).on("click", `.${  classes.trigger}`, function (e) {
        e.preventDefault();
        const $this = $(this);
          const thisIndex = $this.index();
        // Add the active class to this item, and
        // remove it from it's siblings
        $this.addClass(classes.active).siblings().removeClass(classes.active);
        // Find the appropriate target to match what
        // was clicked (by index), add the active class
        // and remove it from it's siblings
        $this
          .parents(`.${  classes.parent}`)
          .find(`.${  classes.target}`)
          .eq(thisIndex)
          .addClass(classes.active)
          .attr("aria-hidden", "false")
          .siblings()
          .removeClass(classes.active)
          .attr("aria-hidden", "true")
          .find("a, button, iframe")
          .attr("tabindex", "-1");
        $this
          .parents(`.${  classes.parent}`)
          .find(`.${  classes.target  }.${  classes.active}`)
          .find("a, button, iframe")
          .removeAttr("tabindex");
      });
    } // function contentSwap

    contentSwap();

    const locationSwap = () => {
      const debug = false;
      if (debug) {
        console.log("contentSwap() has been called!");
      }
      const classes = {
        parent: "js-location-swapper",
        trigger: "js-location-swapper--trigger",
        target: "js-location-swapper--target",
        active: "is-on",
      };
      $(`.${  classes.parent}`).on("click", `.${  classes.trigger}`, function (e) {
        e.preventDefault();
        const $this = $(this);
          const thisIndex = $this.index();
        if ($this.hasClass(classes.active)) {
          // is already active, drop-down instead
          $this.parent().addClass("open-it");
        } else {
          // Is not active; make it so
          // Add the active class to this item, and
          // remove it from it's siblings
          $this
            .addClass(classes.active)
            .siblings()
            .removeClass(classes.active)
            .parent()
            .removeClass("open-it");
          // Find the appropriate target to match what
          // was clicked (by index), add the active class
          // and remove it from it's siblings
          $this
            .parents(`.${  classes.parent}`)
            .find(`.${  classes.target}`)
            .eq(thisIndex)
            .addClass(classes.active)
            .siblings()
            .removeClass(classes.active);
        } // end if/else
      });
    } // function contentSwap

    locationSwap();

    // essentially a content swapper & accordion hybrid, built
    // for the Contact Us Directory Navigation.
    const directoryNav = () => {
      const classes = {
        component: "js-directory-nav",
        parent: "js-directory-nav--parent",
        trigger: "js-directory-nav--trigger",
        active: "is-on",
        hasKids: "js-directory-nav--has-children",
        kids: "js-directory-sub-nav--parent",
      };
      $(`.${  classes.component}`).on(
        "click",
        `.${  classes.trigger}`,
        function (e) {
          e.preventDefault();
          const $this = $(this);
            const $thisParent = $this.parents(`.${  classes.parent}`);

          if (!$thisParent.hasClass(classes.active)) {
            // Remove active class from any already open items
            $this
              .parents(`.${  classes.component}`)
              .find(`.${  classes.parent}`)
              .removeClass(classes.active);
            // Turn on the active class if it's not already on
            $thisParent.addClass(classes.active);
          } else if ($thisParent.hasClass(classes.hasKids)) {
            $thisParent.find(`.${  classes.kids}`).removeClass(classes.active);
            $thisParent.removeClass(classes.active);
            $thisParent
              .find(`.${  classes.kids  }:eq(0)`)
              .addClass(classes.active);
          } // end else if
        }
      );
    } // function directorNav()

    directoryNav();

    const directorySubNav = () => {
      const debug = false;
      if (debug) {
        // console.log('directoryNav() has been called!');
      }
      const classes = {
        component: "js-directory-sub-nav",
        parent: "js-directory-sub-nav--parent",
        trigger: "js-directory-sub-nav--trigger",
        active: "is-on",
      };
      $(`.${  classes.component}`).on(
        "click",
        `.${  classes.trigger}`,
        function (e) {
          if (debug) {
            // console.log('A ' + classes.component + ' has been clicked.');
          }
          e.preventDefault();
          const $this = $(this);
            const $thisParent = $this.parents(`.${  classes.parent}`);
          if (debug) {
            // console.log("$this:", $this);
            // console.log("classes.parent of $this: ", $thisParent);
          }
          // Unlike 'directoryNav', 'directorySubNav' must always
          // have at least one option "on".
          if (!$thisParent.hasClass(classes.active)) {
            // Turn on the active class if it's not already on
            $thisParent.addClass(classes.active);
          }
          // If this item is now considered active, then
          // ensure that none of it's siblings are active
          if ($thisParent.hasClass(classes.active)) {
            $thisParent.siblings().removeClass(classes.active);
          }
        }
      );
    } // function directorNav()

    directorySubNav();

    // Custom Accordion
    const customAccordion = () => {
      const debug = false;
      if (debug) {
        console.log("customAccordion() has been called.");
      }
      const classes = {
        component: "js-custom-accordion",
        parent: "js-custom-accordion--parent",
        trigger: "js-custom-accordion--trigger",
        target: "js-custom-accordion--target",
        active: "is-on",
      };
      $(`.${  classes.component}`).on(
        "click",
        `.${  classes.trigger}`,
        function (e) {
          e.preventDefault();
          const $this = $(this);
            const $thisParent = $this.parent(`.${  classes.parent}`);
            const $thisSiblings = $thisParent.siblings(`.${  classes.parent}`);
          // Remove the active state from siblings that may have it
          $thisSiblings.removeClass(classes.active);
          // And slide up those siblings, if necessary
          $thisSiblings.children(`.${  classes.target}`).slideUp();
          // toggle the active class and
          // slide the open/closed state of the target.
          $thisParent
            .toggleClass(classes.active)
            .children(`.${  classes.target}`)
            .slideToggle();
        }
      ); // $('.' + classes.component).on('click', '.' + classes.trigger, function()
    } // function customAccordion()

    customAccordion();

    // Print from action buttons
    /* eslint-disable consistent-return */
    $(".js-print").on("click", (e) => {
       /* eslint-disable no-undef */
      if (
        typeof Sitecore !== "undefined" &&
        Sitecore.PageModes !== "undefined" &&
        Sitecore.PageModes.PageEditor !== "undefined"
      ) {
        // chillax
        
      } else {
        e.preventDefault();
        window.print();
        return false;
      }
    });

  });



(function (window, document, $) {
  // List of internal links
  FRB.InternalDomainsStr += `,/firstrepublic/,client.firstrepublic.com,frcorporateonline.com,careers-firstrepublic.icims.com,firstrepublicsecurities.netxinvestor.com,gradifi.com,eagleinvest.futureadvisor.com,services2.sungard.com,login2.fisglobal.com,eagleinvest.firstrepublic.com,innovation.firstrepublic.com,ir.firstrepublic.com,m.frcorporateonline.com,online.firstrepublic.com,online.frbnp2.com,online.frbnp3.com,us.10to8.com,info.gradifi.com,portals.frb.us.ionmarkets.net,${window.location.hostname.replace(
    "www.",
    ""
  )}`;

  // External link navigator module
  FRB.externalLinkNavigator = (function () {
    const createTooltip = function ($links) {
      let $openPrevModal;
      // Add class '.external-link' to all the external URLs except the internal URLs and links with class '.supress-external-tooltip'
      $links.not(".supress-external-tooltip").each(function () {
        const domainList = FRB.InternalDomainsStr.split(",");
        const $this = $(this);

        // Determine if the link is part of the internal domain list, if so then the external tooltip will not be displayed
        const thisHostName = this.hostname.replace("www.", "");
        if (thisHostName !== "" && $.inArray(thisHostName, domainList) === -1) {
          $this
            .addClass("external-link")
            .attr({ "data-reveal-id": "externalLinkModal" });
        } else if ($.inArray(thisHostName, domainList) >= 0) {
          $this.on("click", () => {
            FRB.bodyInternalLinkTracking.externalLinks($this);
          });
        }
      });
      $("a.supress-external-tooltip").on("click", function () {
        const $this = $(this);
        FRB.bodyInternalLinkTracking.externalLinks($this);
      });
      $(document).on("click", ".external-link", function (e) {
        e.preventDefault();
        e.stopPropagation();
        const $this = $(this);
        const externalURL = $this.attr("href");
        const $btnContinue = $("#externalLinkModal").find(".btn-continue");
        const $btnBack = $("#externalLinkModal").find(
          ".close-external-link-modal.secondary"
        );
        $openPrevModal = $(".reveal-modal.open");
        $btnContinue.attr({ href: externalURL, target: "_blank" });
        // eslint-disable-next-line no-script-url
        $btnBack.attr({ href: "javascript:void(0);" });
        if (!$this.hasClass("share-popup")) {
          $btnContinue.removeClass("open-share-window");
        } else {
          $btnContinue.addClass("open-share-window");
        }
        // DLG external link tracking
        $btnContinue.on("click", () => {
          FRB.bodyInternalLinkTracking.externalLinks($this);
        });
      });
      $("#externalLinkModal")
        .on("click", ".close-external-link-modal, .btn-continue", function () {
          $(this)
            .closest("div[class*='reveal-modal']")
            .foundation("reveal", "close");
          if ($openPrevModal !== undefined && $openPrevModal.length > 0) {
            $(this)
              .closest("div[class*='reveal-modal']")
              .foundation("reveal", "close");
          }
          $(document).find(".reveal-modal-bg").hide();
        })
        .on("click", ".open-share-window", function (e) {
          e.preventDefault();
          FRB.openNewShareWindow.sharePopupWindow($(this));
        });
      $(document).on("closed.fndtn.reveal", "#externalLinkModal", () => {
        if ($openPrevModal !== undefined && $openPrevModal.length > 0) {
          setTimeout(() => {
            $openPrevModal.foundation("reveal", "open");
          }, 100);
        }
      });
    };

    const init = function () {
      const $links = $("a[href*='http://'],a[href*='https://']");

      createTooltip($links);

      // Dynamically adding House Icon if the text is matching 'Equal Housing Lender'
      $(".supplemental-disclosure .rich-text").each(function () {
        const $textContainer = $(this).filter(
          ":contains('Equal Housing Lender')"
        );
        if (!$textContainer.find("i").hasClass("icon-equal_housing_lender")) {
          $(
            '<i class="icon icon-equal_housing_lender" aria-label="Home Icon"></i>'
          ).insertBefore($textContainer.find("br"));
        }
      });
    };

    const initSecondarySpeedBump = function () {
      let $openPrevModal;
      $(document).on("click", ".external-link-frc", function (e) {
        e.preventDefault();
        e.stopPropagation();
        const $this = $(this);
        const externalURL = $this.attr("href");
        const $btnContinue = $("#externalFRCModal").find(".btn-continue");
        const $btnBack = $("#externalFRCModal").find(
          ".close-external-link-modal.secondary"
        );
        $openPrevModal = $(".reveal-modal.open");
        $btnContinue.attr({ href: externalURL, target: "_blank" });
        // eslint-disable-next-line no-script-url
        $btnBack.attr({ href: "javascript:void(0);" });
        if (!$this.hasClass("share-popup")) {
          $btnContinue.removeClass("open-share-window");
        } else {
          $btnContinue.addClass("open-share-window");
        }
        // DLG external link tracking
        $btnContinue.on("click", () => {
          FRB.bodyInternalLinkTracking.externalLinks($this);
        });
      });
      $("#externalFRCModal")
        .on("click", ".close-external-link-modal, .btn-continue", function () {
          $(this)
            .closest("div[class*='reveal-modal']")
            .foundation("reveal", "close");
          if ($openPrevModal !== undefined && $openPrevModal.length > 0) {
            $(this)
              .closest("div[class*='reveal-modal']")
              .foundation("reveal", "close");
          }
          $(document).find(".reveal-modal-bg").hide();
        })
        .on("click", ".open-share-window", function (e) {
          e.preventDefault();
          FRB.openNewShareWindow.sharePopupWindow($(this));
        });
      $(document).on("closed.fndtn.reveal", "#externalFRCModal", () => {
        if ($openPrevModal !== undefined && $openPrevModal.length > 0) {
          setTimeout(() => {
            $openPrevModal.foundation("reveal", "open");
          }, 100);
        }
      });
    };

    return {
      init,
      createTooltip,
      initSecondarySpeedBump,
    };
  })();

  $(document).ready(() => {
    FRB.externalLinkNavigator.init();
    if (
      $("a[data-reveal-id='externalFRCModal']").length &&
      $("#externalFRCModal").length
    ) {
      FRB.externalLinkNavigator.initSecondarySpeedBump();
    }
  });
})(window, document, jQuery);

(function (window, document, $, undefined) {
  FRB.login = (function () {
    //var onlineBankingChecker = new firstRepublic.OnlineBankingAvailabilityChecker();
    var init = function () {
      var md = FRB.mobileDetector.getMd();
      var $signinButton = $("#sign-in-button");
      var $loginOptions = $("#signInDrop");
      if (md.mobile()) {
        //Prevent default css hovering from happening
        $signinButton.addClass("no-hover");
        //Binding touchstart event to signin button
        $signinButton.on("click", function (e) {
          //e.preventDefault();
          if ($("#locationsDrop").is(".open")) {
            $(".utilityItem-locations-mobile").trigger("click.fndtn.dropdown");
          }
          if ($("#contactDrop").is(".open")) {
            $(".utilityItem-contact").trigger("click.fndtn.dropdown");
          }
          $(this)
            .find("#signInDrop")
            .show(function () {
              $("#signInDrop").focus();
            });
          $(this).removeClass("no-hover").addClass("hover");
          //return false;
        });
        //Binding touchstart event to entire document to close dropdown menu
        $(document).on("touchstart", function (e) {
          var $clickTarget = $(e.target);
          //If the clicked target is not the signin button or anything inside the dropdown
          if (
            $clickTarget.get(0) !== $signinButton.get(0) &&
            $loginOptions.has($clickTarget).length === 0
          ) {
            $loginOptions.hide().attr("aria-hidden", true);
            $(".sign-in__button").attr("aria-expanded", false);
            $signinButton.removeClass("hover").addClass("no-hover");
          }
        });
      } else {
        $signinButton
          .on("mouseover", function () {
            $(this).find(" > a").attr("aria-expanded", true);
            $loginOptions
              .stop(true, true)
              .fadeIn(200, function () {})
              .attr("aria-hidden", false);
            if ($("#locationsDrop").is(".open")) {
              $(".utilityItem-locations-mobile").trigger(
                "click.fndtn.dropdown"
              );
            }
            if ($("#contactDrop").is(".open")) {
              $(".utilityItem-contact").trigger("click.fndtn.dropdown");
            }
          })
          .on("mouseleave", function () {
            $(this).find(" > a").attr("aria-expanded", false);
            $loginOptions
              .fadeOut(200, function () {})
              .attr("aria-hidden", true);
          });
      }

      //When the sign in button is focused on using tab set the hover class and fadein the login options
      $signinButton.focus(function () {
        $loginOptions.stop(true, true).fadeIn(200, function () {});
      });
      //when a login option is focused on open the container loginoptions
      $(".login-option")
        .focus(function () {
          $signinButton.addClass("hover");
          $loginOptions.stop(true, true).fadeIn(200, function () {});
        })
        .blur(function () {
          $signinButton.removeClass("hover");
        });
      //when the input field for the search box is focused on close the loginoptions field and remove the hover class from sign in button
      //the input box is always selected with tab after the sign in button; this is the only way to reset the log in options after cycling through all the options
      $("#search-input").focus(function () {
        $signinButton.removeClass("hover");
        $loginOptions.fadeOut(200, function () {});
      });
    };
    return {
      init: init,
    };
  })();

  $(document).ready(function () {
    //secure sign in behavior
    FRB.login.init();
  });
})(window, document, jQuery);

import dlg from "./analytics-custom";

/**
 * @module ExpiringBrowsers
 */
const ExpiringBrowsers = (() => {
  const EXPIRING_BROWSERS_API = "/ExpiringBrowsers/GetPageSpecific";
  const pageItemID = document.getElementById("contextid").value;
  const OLBComponentsContainer = document.querySelector(".olb-component");
  const expiringBrowsersContainer = document.querySelector(
    ".expiring-browsers-component"
  );

  /**
   * @function detectBrowser
   * @description This function returns
   * name of the current browser.
   * @returns browser
   */
  const detectBrowser = () => {
    const { userAgent } = window.navigator;
    let browser;

    if (userAgent.includes("Firefox/")) {
      browser = "Firefox";
    } else if (userAgent.includes("Edg/")) {
      browser = "Edg";
    } else if (userAgent.includes("Chrome/")) {
      browser = "Chrome";
    } else if (userAgent.includes("Safari/")) {
      browser = "Safari";
    } else if (userAgent.includes("Trident/")) {
      browser = "Trident";
    }

    return browser;
  };

  /**
   * @function detectBrowserVersion
   * @description This function returns
   * the MAJOR version number of the current browser.
   * @returns browserVersion
   */
  const detectBrowserVersion = () => {
    const { userAgent } = window.navigator;
    const browser = detectBrowser() === "Safari" ? "Version" : detectBrowser();
    const browserVersion = userAgent.split(`${browser}/`)[1].split(".")[0];

    return browserVersion;
  };

  /**
   * @function renderOLBLoginFormView
   * @description This function when called sets
   * the CSS display property of the OLB Components Contianer.
   */
  const renderOLBLoginFormView = () => {
    OLBComponentsContainer.style.display = "block";
  };

  /**
   * @function renderExpiringBrowsersView
   * @description This function handles the rendering of
   * two different views based on the current browser and version.
   * 1. Expiring Browsers View (Hard Block || Soft Block)
   * 2. OLB Login Form View
   * @param {object} dataObj
   */
  const renderExpiringBrowsersView = (dataObj) => {
    const data = dataObj;
    const currentBrowserVersion = detectBrowserVersion();
    let expiringBrowsersHTML = "";

    if (
      data.hardBlockVersion &&
      data.hardBlockVersion !== null &&
      currentBrowserVersion <= data.hardBlockVersion
    ) {
      // Build HTML for hard block view
      expiringBrowsersHTML = `
        <div class="expiring-browsers-wrapper text-center">
          <h2>${data.hardBlockTitle}</h2>
          ${data.hardBlockContent}
        </div>
      `;

      // Inject HTML to the container and display it on the page
      expiringBrowsersContainer.innerHTML = expiringBrowsersHTML;
      expiringBrowsersContainer.style.display = "block";
      FRB.externalLinkNavigator.init();

      // When it's a hard block remove OLB Login form from the DOM tree
      OLBComponentsContainer.remove();
    } else if (
      data.softBlockVersion &&
      data.softBlockVersion !== null &&
      currentBrowserVersion <= data.softBlockVersion
    ) {
      // Build HTML for soft block view (inside a modal)
      expiringBrowsersHTML = `
        <div id="expiringBrowsersModal" class="reveal-modal" data-reveal aria-labelledby="${data.softBlockTitle}" aria-hidden="true" role="dialog" data-options="close_on_background_click:false;close_on_esc:false;">
          <div class="expiring-browsers-wrapper text-center">
            <h2>${data.softBlockTitle}</h2>
            ${data.softBlockContent}
          </div>
        </div>
      `;

      // Inject HTML to the container and display it on the page
      expiringBrowsersContainer.innerHTML = expiringBrowsersHTML;
      expiringBrowsersContainer.style.display = "block";
      FRB.externalLinkNavigator.init();

      // Open soft block modal window
      $("#expiringBrowsersModal").foundation("reveal", "open");
      dlg.ttmEBSoftBlockView();
    } else {
      renderOLBLoginFormView();
    }
  };

  /**
   * @function expiringBrowsersSuccessCallback
   * @description This function is a callback
   * to the \@getExpiringBrowsers ajax call.
   * @param {object} browsersObj
   */
  const expiringBrowsersSuccessCallback = (browsersObj) => {
    const browsers = browsersObj;
    const currentBrowser = detectBrowser();
    const currentBrowserData = browsers[currentBrowser];

    if (Object.keys(browsers).includes(currentBrowser)) {
      renderExpiringBrowsersView(currentBrowserData);
    } else {
      renderOLBLoginFormView();
    }
  };

  /**
   *
   * @returns This function returns ajax response and in success
   * if response is not null calls \@expiringBrowsersSuccessCallback function
   * else calls \@renderOLBLoginFormView function
   * and if the ajax call is failed, logs error to the console.
   */
  const getExpiringBrowsers = () =>
    $.ajax({
      url: EXPIRING_BROWSERS_API,
      method: "POST",
      data: { pageItemID },
    })
      .done((response) => {
        if (response) {
          expiringBrowsersSuccessCallback(response);
        } else {
          renderOLBLoginFormView();
        }
      })
      .fail((error) => console.error(error));

  /**
   * @constructor init
   * @description Modules constructor function.
   */
  const init = () => {
    getExpiringBrowsers();

    // Attach click listener to the dynamically generated close link soft block modal
    document.addEventListener("click", (e) => {
      if (e.target && e.target.className.indexOf("js-close-modal") !== -1) {
        $("#expiringBrowsersModal").foundation("reveal", "close");
        renderOLBLoginFormView();
        dlg.ttmEBSoftBlockTapSignIn();
      }
    });
  };

  return { init };
})();

// Invoke expiringBrowsers init function on DOM content load
window.addEventListener("DOMContentLoaded", () => {
  if (document.querySelector(".expiring-browsers-component") !== null) {
    ExpiringBrowsers.init();
  }
});

// External libraries
import "jquery-ui";
import "jquery.cookie";
import "jquery-ui/ui/focusable";
import "foundation5/js/foundation";

// Custom scripts
//import "./js/frb/analytics-custom";
//import "./js/frb/global";
import "./js/frb/mobile-detector";
import "./js/frb/accessibility";
//import "./js/frb/recaptcha";
//import "./js/frb/cd-promo";
//import "./js/frb/cd-rate-calculator";
//import "./js/frb/carousel-testimonials";
//import "./js/frb/contact-cta-goal";
import "./js/frb/contact-us";
import "./js/frb/skip-to-main-content";
//import "./js/frb/corporate-online";
//import "./js/frb/cd-rate-analytics";
//import "./js/frb/cra-mortgage";
//import "./js/frb/media-player";
//import "./js/frb/daa-process";
//import "./js/frb/daa-open-account";
//import "./js/frb/estimator-e14b";
//import "./js/frb/eagle-invest";
//import "./js/frb/easy-enrollment";
//import "./js/frb/engage-goal";
//import "./js/frb/load-more";
import "./js/frb/speedbump";
//import "./js/frb/historical-interest-rates";
import "./js/frb/foundation-overrides";
//import "./js/frb/inputmask";
import "./js/frb/fullpage";
import "./js/frb/global1";
//import "./js/frb/stock-price";
//import "./js/frb/alerts";
//import "./js/frb/googler";
//import "./js/frb/carousel-main";
//import "./js/frb/articles";
import "./js/frb/secure-cta";
//import "./js/frb/marketing-org-chart";
//import "./js/frb/marketo";
import "./js/frb/mega-menu";
//import "./js/frb/mortgage-refi";
//import "./js/frb/social-share-window";
//import "./js/frb/article-videos";
//import "./js/frb/modal-video-player";
//import "./js/frb/pwm-cross-serve";
//import "./js/frb/savings-calculator";
//import "./js/frb/google-custom-search";
import "./js/frb/secure-cta-mobile";
//import "./js/frb/vimeo-player-single";
import "./js/frb/sticky-header";
import "./js/frb/tabs-to-accordion";
//import "./js/frb/trusted-partner";
//import "./js/frb/vimeo-player";
//import "./js/frb/welcome";
//import "./js/frb/banking-online";
//import "./js/frb/sitecore-forms";
import "./js/frb/expiring-browsers";
//import "./js/frb/text-me-app";

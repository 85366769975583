(function (window, document, $) {
  FRB.stickyHeader = function () {
    const init = function () {
      const initScrollVal = 5;
      let didScroll;
      let lastScrollTop = 0;
      // var $headerHeight = $(".fixed-header").height();
      let windowHeight = $(window).height();
      const navbarHeight = $('.main-header .columns.contact-us').outerHeight();
      const getStickeyNScroll = function () {
        $(window).scroll(() => {
          didScroll = true;
        });

        function hasScrolled() {
          const scrollTopVar = $(this).scrollTop();
          if (Math.abs(lastScrollTop - scrollTopVar) <= initScrollVal) { return; }
          if (scrollTopVar > lastScrollTop && scrollTopVar > navbarHeight) { // Scroll Down
            $('.main-header .columns.contact-us').removeClass('nav-down').addClass('nav-up');
          }
          else {// Scroll Up
            // eslint-disable-next-line no-lonely-if
            if (scrollTopVar + $(window).height() < $(document).height()) {
              $('.main-header .columns.contact-us').removeClass('nav-up').addClass('nav-down');
            }
          }
          lastScrollTop = scrollTopVar;
        }
        
        setInterval(() => {
          if (didScroll) {
            hasScrolled();
            didScroll = false;
          }
        }, 50);
        
        $(".left-off-canvas-toggle").click(() => {
          // $('.off-canvas-wrap').foundation('offcanvas', 'toggle', 'move-right'); // SGM: added to fix mobile nav not working in prod 11/22/2021
          FRB.megamenu.highlightActiveMobileMenu(); // Highlight current page mobile menu
          $("body").addClass("blockPgScroll");
          $("aside.left-off-canvas-menu").attr("aria-hidden", false).css({ 'height': windowHeight }).find(".close-hamburger-menu").focus();
        });
        $(".exit-off-canvas").on("click", () => {
          FRB.megamenu.closeActiveMobileMenu(); // Collapse all active mobile menu
          $("body").removeClass("blockPgScroll");
          $("aside.left-off-canvas-menu").attr("aria-hidden", true);
        });
      }
      // always calling first time
      getStickeyNScroll();
      $(window).resize(() => {
        if ($(window).width() <= 768) {
          // getting the updated height
          windowHeight = $(window).height();
          // calling for layout  change
          getStickeyNScroll();
          // getting the updated height
          $("aside.left-off-canvas-menu").css({ 'height': windowHeight });
        }
        else if ($(window).width() > 768) {
          $("body").removeClass("blockPgScroll");
          $('.off-canvas-wrap').removeClass('move-right');
        }
      });
    }
    return {
      init
    }
  }();

  $(document).ready(() => {
    FRB.stickyHeader.init();
  });
})(window, document, jQuery);

(function (window, document, $) {
  // Converting Tabs to Accordion for Mobile (markup reference: Tabs.cshtml)
  FRB.tabToAccordion = (function () {
    const refreshAccordionContent = function () {
      if ($("#custom-accordion > li.active > div .slider-thumbnails").length) {
        FRB.ClientTestimonialDetails.updateSlick(
          FRB.ClientTestimonialDetails.getFilteredTestimonialsArray()
        );
        $("div[role='tabpanel'] .component-client-testimonials").animate(
          { opacity: 1 },
          200,
          "swing"
        );
      }
    };

    const desktopADA = function () {
      const $accordion = $("#custom-accordion");
      if (!$accordion.find(".accordion-navigation > a").is(":visible")) {
        $accordion.removeAttr("role");
        $accordion.find("li.accordion-navigation").removeAttr("role");
        $accordion.find("a.show-for-small-only").removeAttr("role");
      }
    };

    const init = function () {
/*      let hashTag = window.location.hash;
      const justHashTag = hashTag.indexOf("?");*/
      const win = window;
/*
      desktopADA();

      hashTag = hashTag.substring(
        0,
        justHashTag !== -1 ? justHashTag : hashTag.length
      );

      if (hashTag) {
        const $customTab = $("#custom-tab-panel .tab-title");
          const $activeTab = $customTab.find(`a[href='${  hashTag  }']`).parent();
          const $accHashTag = $(
            `.faq .accordion.outer > li a[data-itemname='${  hashTag  }']`
          );
        if ($activeTab.length) {
          $customTab.removeClass("active");
          $activeTab.addClass("active");

          $("#custom-accordion > li")
            .removeClass("active")
            .find("> .content")
            .removeClass("active")
            .closest("ul")
            .find(`> li:eq(${  $activeTab.index()  })`)
            .addClass("active")
            .find(" > .content")
            .addClass("active");
          refreshAccordionContent();
        }

        if ($accHashTag.length) {
          // Trigger the FAQ link after page load completed
          $(window).load(() => {
            $accHashTag.trigger("click");
          });
        }
      }
*/
      // Trigger the Tab Link on Hash Change in the URL
      // $(window).on("hashchange", (e) => {
      //  hashTag = hashTag.substring(
      //    0,
      //    justHashTag !== -1 ? justHashTag : hashTag.length
      //  );

      //  const $tabLink = $(`#custom-tab-panel > li a[href='${  hashTag  }']`);
      //  const $accHashTag = $(
      //      `.faq .accordion.outer > li a[data-itemname='${  hashTag  }']`
      //  );

      //  if ($tabLink.length) {
      //    e.preventDefault();
      //    $(`#custom-tab-panel > li a[href='${  hashTag  }']`).trigger(
      //      "click"
      //    );
      //  }

      //  if ($accHashTag.length) {
      //    e.preventDefault();
      //    $accHashTag.trigger("click");
      //  }
      // });

      $("#custom-tab-panel").on("click", ".tab-title:not(.active) a", () => {
          const locationStripped = window.location.href.replace(/#.*$/, "");
          // const $this = $(this).attr("href");
          const thisHash = window.location.hash;
          if(thisHash !== "") win.location.href = locationStripped + thisHash;
        }
      );

      $("#custom-accordion > li > div.active").animate(
        { opacity: 1 },
        500,
        "swing",
        function () {
          $(this).prev("a").attr("aria-expanded", "true");
        }
      );

      // Tabs
      $("#custom-tab-panel").on("toggled", (event, tab) => {
        win.location.hash = tab.context.hash;
        const activeTab = $("#custom-tab-panel > li.active");
        if (activeTab.length) {
          const tabContentPos = $(activeTab).offset().top - 100;
          $("html, body").scrollTop(tabContentPos);
        }

        const $customLiActive = $("#custom-accordion li.active");
          const $customLi = $(`#custom-accordion > li:eq(${  tab.index()  })`);

        $customLiActive
          .add($customLiActive.find(" > div.active"))
          .removeClass("active");
        $customLi.add($customLi.find(" > div")).addClass("active");

        $("#custom-accordion > li:not(.active) > div").animate(
          {
            opacity: 0,
          },
          500,
          "swing",
          function () {
            $(this).prev("a").attr("aria-expanded", "false");
          }
        );
        $("#custom-accordion > li.active > div").animate(
          {
            opacity: 1,
          },
          500,
          "swing",
          function () {
            $(this).prev("a").attr("aria-expanded", "true");
            refreshAccordionContent();
          }
        );
        activeTab.find("a").focus();
        return false;
      });
      // Accordions
      $("#custom-accordion").on("toggled", (event, accordion) => {
        win.location.hash = accordion[0].id;
        const activeAcc = $("#custom-accordion > li.active");
        if (activeAcc.length) {
          const accContentPos = $("#custom-accordion > li.active").offset().top;
          $("html, body").animate(
            {
              scrollTop: accContentPos - 93,
            },
            500,
            () => {
              refreshAccordionContent();
            }
          );
        }
        $("#custom-accordion > li:not(.active) > div").animate(
          {
            opacity: 0,
          },
          500,
          "swing",
          function () {
            $(this)
              .prev("a")
              .attr("aria-expanded", "false")
              .removeAttr("tabindex")
              .removeAttr("aria-hidden");
          }
        );
        $("#custom-accordion > li.active > div").animate(
          {
            opacity: 1,
          },
          500,
          "swing",
          function () {
            $(this)
              .prev("a")
              .attr("aria-expanded", "true")
              .removeAttr("tabindex")
              .removeAttr("aria-hidden");
          }
        );

        $("#custom-tab-panel > li.active").removeClass("active");

        $(
          `#custom-tab-panel > li:eq(${ 
            $("#custom-accordion li.active").index() 
            })`
        ).addClass("active");
        if ($("#custom-accordion li:not(.active)")) {
          $("#custom-tab-panel > li")
            .removeClass("active")
            .filter(":eq(0)")
            .addClass("active");
        }
      });
    };
    return {
      init,
    };
  })();

  $(document).ready(() => {
    if ($("#custom-tab-panel, .faq").length > 0) {
      FRB.tabToAccordion.init();
    }
  });
})(window, document, jQuery);

(function (window, document, $, undefined) {
    // Sign in Detector
    FRB.signInLinkForMobile = function () {
        var _init = function () {
            var simd = FRB.mobileDetector.getMd();
            var $MobileCorporateBankingAnchor = $('a[href*="m.frcorporateonline.com"]')
            var $DesktopCorporateBankingAnchor = $('a[href*="www.frcorporateonline.com"]')
            var MobileCorporateBankingLink = $MobileCorporateBankingAnchor.first().attr('href');
            var DesktopCorporateBankingLink = $DesktopCorporateBankingAnchor.first().attr('href');
            if (MobileCorporateBankingLink != undefined && MobileCorporateBankingLink != undefined) {
                (simd.phone()) ?
                    $DesktopCorporateBankingAnchor.attr({ 'href': MobileCorporateBankingLink }) :
                $MobileCorporateBankingAnchor.attr({
                    'href': DesktopCorporateBankingLink
                });
            }
        }
        return {
            init: _init
        }
    }();

    $(document).ready(function () {
        // FRB.signInLinkForMobile.init();
    });
})(window, document, jQuery);
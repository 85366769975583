import "jquery-validation";
import "jquery-validation/dist/additional-methods";

// Global Variables
const urlPath = window.location.pathname;
let officesArray = [];
let tdl = {};
let locationField = "";
let locationFieldZip = "";
let locationFieldCity = "";
let resultPosition = 1;
let lastScrollTop = 0;

const ttmCallTrackAnalytics = function () {
  const dlExtend = $.extend(true, {}, _dl);
  delete dlExtend.site_events;

  try {
    $.extend(true, tdl, dlExtend);
    _trackAnalytics(tdl);
  } catch (err) {
    console.log(err);
  }
};

const ttmCallTrackAnalyticsOLB = function () {
  const dlExtend = $.extend(true, {}, _dl);
  delete dlExtend.site_events;
  try {
    $.extend(true, tdl, dlExtend);
    _trackAnalytics(tdl);
  } catch (err) {
    console.log(err);
  }
};

const ttmCustomerLeadType = {
  contact: {
    // Contact-Us/Have-Us-Contact-You
    id: "fxb_a57489d9-d8f0-4be1-8262-76eeb048df21_ad9114a3-993e-44ca-a8b0-b103bfb1a218",
    label: "have us contact you",
    fields: [
      {
        id: {
          value:
            "fxb.a57489d9-d8f0-4be1-8262-76eeb048df21.Fields[1895e827-2a99-40f3-acf9-b7731f8a639b].Value",
          label: "isFrbClient",
        },
      },
      {
        id: {
          value: "_Fields_14252b47-8296-4864-9b75-464f8bdfb237__Value",
          label: "ZipCode",
        },
      },
      {
        id: {
          value:
            "fxb.a57489d9-d8f0-4be1-8262-76eeb048df21.Fields[35e481b8-3e5c-4a0f-8dd3-597d5e949d3e].Value",
          label: "IsEmailOrPhone",
        },
      },
      {
        id: {
          value: "_Fields_defb7201-ddf3-43d2-bdd8-229cd83edefc__Value",
          label: "FirstName",
        },
      },
      {
        id: {
          value: "_Fields_d54b866c-21f9-45d9-a644-af1b6faba97e__Value",
          label: "LastName",
        },
      },
      {
        id: {
          value: "_Fields_79a6f0e8-f4c9-4305-aa12-4d5f905d7c08__Value",
          label: "PhoneNumber",
        },
      },
      {
        id: {
          value: "_Fields_798ae486-8090-44be-a70c-7143bbb947d6__Value",
          label: "EmailAddress",
        },
      },
    ],
  },
  feedback: {
    // Contact-Us/send-feedback
    id: "fxb_1eb17ac5-69ee-4428-ac32-a19f4e810530_f2c4da12-420b-4c2b-84a2-c3100e92e031",
    label: "Send Feedback",
    fields: [
      {
        id: {
          value:
            "fxb.1eb17ac5-69ee-4428-ac32-a19f4e810530.Fields[d3816dad-d1c4-4b8a-8087-9eaad2cd49fb].Value",
          label: "isFrbClient",
        },
      },
      {
        id: {
          value: "_Fields_d346c422-90de-4a9e-a578-6c3d2156d72e__Value",
          label: "ZipCode",
        },
      },
      {
        id: {
          value: "_Fields_39030ec4-f70f-434a-96ae-415ad03b1f6d__Value",
          label: "email",
        },
      },
      {
        id: {
          value: "_Fields_407a507a-c07f-420e-ab3e-07ad4d54f936__Value",
          label: "yourMessage",
        },
      },
      {
        id: {
          value: "_Fields_aaed8aa8-090b-4edc-8b7c-ea27ab89d796__Value",
          label: "FirstName",
        },
      },
      {
        id: {
          value: "_Fields_0695d70d-2355-4e86-a50d-4169ddea9970__Value",
          label: "LastName",
        },
      },
      {
        id: {
          value:
            "fxb.1eb17ac5-69ee-4428-ac32-a19f4e810530.Fields[8987bdcc-8b8c-4b21-b491-f265a927da1c].Value",
          label: "WantAResponse",
        },
      },
    ],
  },
  contactEngage: {
    // unidentfy form
    id: "{CF93DBD3-A312-4DB8-BA62-51AB50FA9244}",
    label: "have us contact you",
    fields: [
      { id: { value: "_Sections_4__Fields_0__Value", label: "isFrbClient" } },
      { id: { value: "_Sections_0__Fields_2__Value", label: "ZipCode" } },
      {
        id: {
          value: "_Sections_1__Fields_0__Value",
          label: "IsEmailOrPhone",
        },
      },
      { id: { value: "_Sections_0__Fields_0__Value", label: "FirstName" } },
      { id: { value: "_Sections_0__Fields_1__Value", label: "LastName" } },
      { id: { value: "_Sections_3__Fields_0__Value", label: "PhoneNumber" } },
      {
        id: { value: "_Sections_2__Fields_0__Value", label: "EmailAddress" },
      },
    ],
  },
  contactEnagageBusiness: {
    /// Contact-Us/Have-Us-Contact-You-Eagle-One
    id: "fxb_c68559c9-4583-4e95-9a34-a402d46e2cac_7cff07ae-bd8d-4377-9450-6f2785298be5",
    label: "have us contact you",
    fields: [
      {
        id: {
          value:
            "fxb.c68559c9-4583-4e95-9a34-a402d46e2cac.Fields[70cc68a6-2070-479b-a6bd-4e74f1737547].Value",
          label: "isFrbClient",
        },
      },
      {
        id: {
          value: "_Fields_33c6f4ca-76cd-44a4-bafa-f6e43721cd89__Value",
          label: "ZipCode",
        },
      },
      {
        id: {
          value:
            "fxb.c68559c9-4583-4e95-9a34-a402d46e2cac.Fields[ad23feb6-97e8-4dd9-8bc4-db4bf4db28f8].Value",
          label: "IsEmailOrPhone",
        },
      },
      {
        id: {
          value: "_Fields_77c9aa94-ef9b-4438-bb08-661729c9f86c__Value",
          label: "FirstName",
        },
      },
      {
        id: {
          value: "_Fields_a7dc24d4-5bbd-46d2-93c7-71cfb792feab__Value",
          label: "LastName",
        },
      },
      {
        id: {
          value: "_Fields_59ad12af-4c47-4735-987a-2d5163591cca__Value",
          label: "PhoneNumber",
        },
      },
      {
        id: {
          value: "_Fields_454241c7-ba31-493a-b347-4bef73d09507__Value",
          label: "EmailAddress",
        },
      },
      {
        id: {
          value: "_Fields_b422ba06-5194-472b-9096-360383340139__Value",
          label: "CompanyName",
        },
      },
    ],
  },
  contactEagleInvest: {
    // private-wealth-management/eagleinvest/waitlist
    id: "fxb_a57489d9-d8f0-4be1-8262-76eeb048df21_086db996-31b4-4382-86d3-43afe1235daa",
    label: "have us contact you",
    fields: [
      {
        id: {
          value:
            "fxb.a57489d9-d8f0-4be1-8262-76eeb048df21.Fields[dd6409f1-5dc9-4c79-ade9-d69114ff28a5].Value",
          label: "isFrbClient",
        },
      },
      {
        id: {
          value: "_Fields_b32362b8-690d-4039-a82f-e87bb64cffe4__Value",
          label: "ZipCode",
        },
      },
      {
        id: {
          value: "_Fields_128e83ad-96af-4dc6-893d-4fee3e4cb421__Value",
          label: "FirstName",
        },
      },
      {
        id: {
          value: "_Fields_87b716e3-46ba-4e48-9d2e-5b9e72d83f48__Value",
          label: "LastName",
        },
      },
      {
        id: {
          value: "_Fields_a84f7e01-a35a-4815-bf70-05d5d11adec3__Value",
          label: "EmailAddress",
        },
      },
    ],
  },
  cdBirthdaySpecial: {
    // Contact-Us/cd-birthday-special
    id: "fxb_e12af60d-4e1e-46d5-818d-1c932a0002c9_ae462fc4-f52e-446a-9c00-ffa028b162b7",
    label: "cd birthday special",
    fields: [],
  },
  contactEngageMasterBrand: {
    // engage/business-banking-contact-form
    id: "fxb_f638ad78-3880-4a8c-87e9-0b6b5ba8ca76_f5f0ce43-68d5-4bb8-8b97-dddbc2da1684",
    label: "have us contact you",
    fields: [
      {
        id: {
          value:
            "fxb.f638ad78-3880-4a8c-87e9-0b6b5ba8ca76.Fields[498101ab-cad8-4b5f-9ed0-a4575a38e3c3].Value",
          label: "isFrbClient",
        },
      },
      {
        id: {
          value: "_Fields_6bcc626a-acc0-4a16-adc0-96377b7a6ede__Value",
          label: "ZipCode",
        },
      },
      {
        id: {
          value:
            "fxb.f638ad78-3880-4a8c-87e9-0b6b5ba8ca76.Fields[1e88d877-6668-4fa3-9117-11e34bd729a2].Value",
          label: "IsEmailOrPhone",
        },
      },
      {
        id: {
          value: "_Fields_436d230c-c113-46d5-87c6-fe9ccbefe3e2__Value",
          label: "FirstName",
        },
      },
      {
        id: {
          value: "_Fields_66e9d374-6eeb-4c3f-a3a9-1fbd64d3835e__Value",
          label: "LastName",
        },
      },
      {
        id: {
          value: "_Fields_6b62cfac-d386-4994-be4b-252687d4a72a__Value",
          label: "PhoneNumber",
        },
      },
      {
        id: {
          value: "_Fields_6bf9ac89-eacc-4e9e-8b25-e6e46cfea871__Value",
          label: "EmailAddress",
        },
      },
    ],
  },
};

const isTrackAnalitycsLoaded = function () {
  return typeof ttmCustomerLeadType === "object";
};

const ttmLocationParseSearchField = function (zipcode) {
  locationFieldZip = "";
  locationFieldCity = "";

  if (zipcode.match(/^(?!(00000(-0000)?))\d{5}(-\d{4})?$/)) {
    locationFieldZip = zipcode;
  } else {
    locationFieldCity = zipcode;
  }
};

const ttmSetLocationEvent = function (siteEvents, zipCode, officesArray1) {
  const siteEvent = {};
  ttmLocationParseSearchField(zipCode);

  siteEvent[siteEvents] = true;

  tdl = {};
  tdl.site_events = siteEvent;
  tdl.zip_code = locationFieldZip;
  tdl.city_searched = locationFieldCity;
  tdl.locations = officesArray1;
  ttmCallTrackAnalytics();
};

// called from locations.js
const ttmLocationIndex = function (arrayObject) {
  officesArray = [];

  if (typeof Storage !== "undefined") {
    locationField = sessionStorage.zipCodeandCity;
  }
  let jsonObject;
  let officeTitle;
  for (let i = 0; i < arrayObject.length; i++) {
    const atm = false;
    officeTitle = arrayObject[i].Title.trim()
      .replace(/(&amp;)+/g, "and")
      .replace(/(&)+/g, "and")
      .replace(/\s+/g, "_")
      .replace(/-/g, "_")
      .toLowerCase();
    jsonObject = {};
    jsonObject.location_id = officeTitle;
    jsonObject.address = arrayObject[i].Address1;
    jsonObject.city = arrayObject[i].City;
    jsonObject.state = arrayObject[i].State;
    jsonObject.zip = arrayObject[i].Zip;
    jsonObject.location_type =
      arrayObject[i].IsBankLocation === "true" ? "branch" : "atm";
    jsonObject.result_position = i + 1;
    jsonObject.amenities = {
      parking: arrayObject[i].HasParking,
      atm,
    };
    officesArray.push(jsonObject);
  }
  ttmSetLocationEvent("find_location", locationField, officesArray);
};

// click "get direction" from the location PBO page
const ttmLocationGetDirection = function (arrayObject) {
  $(".location-info--directions-link").on("click", () => {
    if (typeof Storage !== "undefined") {
      locationField = sessionStorage.zipCodeandCity;
    }
    ttmSetLocationEvent("get_directions", locationField, arrayObject);
  });
};

// click "get direction" from the locations page google map pin window
const ttmLocationBubbleGetDirection = function (locationName) {
  if (typeof Storage !== "undefined") {
    locationField = sessionStorage.zipCodeandCity;
  }
  const selectedOffice = [];
  for (let i = 0; i < officesArray.length; i++) {
    if (locationName === officesArray[i].location_id) {
      selectedOffice.push(officesArray[i]);
      break;
    }
  }
  ttmSetLocationEvent("get_directions", locationField, selectedOffice);
};

const ttmLocationViewHours = function () {
  $(".locations-list").on("click", ".view-hours", function () {
    resultPosition = $(this).attr("data-pos-index");
    sessionStorage.setItem("result_position", resultPosition);
  });

  $(".locations-list").on("click", ".location-title", function () {
    resultPosition = $(this).attr("data-pos-index");
    sessionStorage.setItem("result_position", resultPosition);
  });

  $("#map-canvas").on("click", ".gm-branch-detail--direction", function () {
    resultPosition = $(this).attr("data-pos-index");
    sessionStorage.setItem("result_position", resultPosition);
  });
};

const ttmLocationDetailCall = function () {
  $(".location-cta-phone").on("click", () => {
    if (typeof Storage !== "undefined") {
      locationField = sessionStorage.zipCodeandCity;
    }
    ttmLocationParseSearchField(locationField);
    tdl = {};
    tdl.site_events = { call_branch: true };
    tdl.zip_code = locationFieldZip;
    tdl.city_searched = locationFieldCity;
    tdl.locations = officesArray;
    ttmCallTrackAnalytics();
  });
};

// called from locations.js
const ttmLocationZoomMap = function () {
  tdl = {};
  tdl.site_events = { location_finder_zoom_map: true };
  tdl.zip_code = locationFieldZip;
  tdl.city_searched = locationFieldCity;
  tdl.locations = officesArray;
  ttmCallTrackAnalytics();
};

// called from app.js
const ttmLocationToggleMapList = function (textView) {
  const mapView = textView === "Map";
  tdl = {};
  tdl.site_events = {
    map_view: mapView,
    list_view: !mapView,
  };
  tdl.zip_code = locationFieldZip;
  tdl.city_searched = locationFieldCity;
  tdl.locations = officesArray;
  ttmCallTrackAnalytics();
};

// suggested search clicks
const ttmSuggestedSearch = function () {
  $("body").on("click", ".ui-autocomplete .ui-menu-item", function () {
    tdl = {};
    tdl.site_events = {
      suggested_search: true,
    };
    tdl.keyword = $(this).find("a strong").text();
    tdl.suggested_keyword = $(this).find("a").text();
    ttmCallTrackAnalytics();
  });
};

// search results clicks
const ttmSearchResultClick = function () {
  $("#all-search-results a").on("click", () => {
    tdl = {};
    tdl.site_events = {
      select_search_result: true,
    };
    tdl.keyword = _dl.keyword;
    tdl.results_count = _dl.results_count;
    ttmCallTrackAnalytics();
  });
};

// Contact menu flyout click on "contact us" link
// Locations menu flyout click on "Locations" link
const ttmUtilityNavMenuLinks = function () {
  $('ul.utility-nav li.has-drop a[data-dropdown="contactDrop"]').on(
    "click",
    () => {
      tdl = {};
      tdl.site_events = {
        contact_fly_out: true,
      };
      ttmCallTrackAnalytics();
    }
  );
  $('ul.utility-nav li.has-drop a[data-dropdown="locationsDrop"]').on(
    "click",
    () => {
      tdl = {};
      tdl.site_events = {
        locations_fly_out: true,
      };
      ttmCallTrackAnalytics();
    }
  );
};

const ttmContactUsCustomerCare = function () {
  if ($(".company-directory").length > 0) {
    const $contactUsDept = $(".company-directory__list > li");
    const $CustomerCareDept = $(".company-directory__list li:first");

    $CustomerCareDept.find('a[href*="tel"]').on("click", () => {
      tdl = {};
      tdl.site_events = { call_customer_care: true };
      ttmCallTrackAnalytics();
    });
    $CustomerCareDept.find('a[href*="mailto"]').on("click", () => {
      tdl = {};
      tdl.site_events = {
        email_customer_care: true,
      };
      ttmCallTrackAnalytics();
    });

    $contactUsDept
      .find("a.company-directory__list-item--title")
      .on("click", function () {
        const departmentText = $(this).text().toLowerCase();
        tdl = {};
        tdl.site_events = {
          view_company_directory: true,
        };
        tdl.department_name = departmentText;
        const dlExtend = $.extend(true, {}, _dl);
        delete dlExtend.site_events;
        delete dlExtend.department_name;
        try {
          $.extend(true, tdl, dlExtend);
          _trackAnalytics(tdl);
        } catch (err) {
          console.log(err);
        }
      });
  }
};

// share social network clicks
const ttmShareLinks = function () {
  // general and annual report
  $('a[class*="social-sharing"]').on("click", function () {
    const socialNetwork = $(this).attr("data-ttm");
    tdl = {};
    tdl.site_events = { social_share_media: true };
    tdl.social_network = socialNetwork;
    tdl.event_element = "share";
    ttmCallTrackAnalytics();
  });
  // travel gallery modal window
  $("#imageGridModal").on("click", 'a[class*="social-sharing"]', function () {
    const socialNetwork = $(this).attr("data-ttm");
    tdl = {};
    tdl.site_events = { social_share_media: true };
    tdl.social_network = socialNetwork;
    tdl.event_element = "share";
    ttmCallTrackAnalytics();
  });
};

// click social sites referral
const ttmFooterSocialNetwork = function () {
  $('a[class*="social-network-referral"]').on("click", function () {
    const socialNetwork = $(this).attr("href");
    tdl = {};
    tdl.site_events = { property_referral: true };
    tdl.exit_url = socialNetwork;
    if (window.location.href.indexOf("/about-us/careers") >= 0) {
      tdl.event_element = "follow us";
    }
    ttmCallTrackAnalytics();
  });
};

// filter News Articles
const ttmNewsFilter = function () {
  if ($("#form-results_rates-form").length > 0) {
    const categoryIndex = $('select[name="category"]').prop("selectedIndex");
    const categoryText = $('select[name="category"] option:selected').text();
    const monthIndex = $('select[name="month"]').prop("selectedIndex");
    const monthText = $('select[name="month"] option:selected').text();
    const yearIndex = $('select[name="year"]').prop("selectedIndex");
    const yearText = $('select[name="year"] option:selected').text();

    if (categoryIndex > 0 || monthIndex > 0 || yearIndex > 0) {
      tdl = {};
      tdl.site_events = { apply_filter: true, news_article_filter: true };
      tdl.filter_by_category = categoryText;
      tdl.filter_by_month = monthText;
      tdl.filter_by_year = yearText;
      ttmCallTrackAnalytics();
    }
  }
};

// from FRB.loadArticles in app.js
const ttmArticlesFilter = function () {
  tdl = {};
  tdl.site_events = { article_list_page: true };
  ttmCallTrackAnalytics();
};

// filter Testimonials called from \Views\Renderings\Testimonials\ClientTestimonials.cshtml
const ttmTestimonialsFilters = function () {
  const loationSelected = document.getElementById("locationId").value;
  const typeSelected = document.getElementById("allType").value;
  const sel = document.getElementById("industryId");
  const industrySelected = sel.options[sel.selectedIndex].text;
  tdl = {};
  tdl.site_events = {
    testimonials_filter: true,
    apply_filter: true,
  };
  tdl.filter_by_type = typeSelected;
  tdl.filter_by_location = loationSelected;
  tdl.filter_by_industry = industrySelected;
  ttmCallTrackAnalytics();
};

// filter PWM team
const ttmPWMTeamFilter = function () {
  if ($(".pwm-team-filter-trackanalytics").length > 0) {
    const slIndex = $("#SelectedLocation").prop("selectedIndex");
    const strLocation = $("#SelectedLocation option:selected").text();
    const sgIndex = $("#SelectedGroup").prop("selectedIndex");
    const strGroup = $("#SelectedGroup option:selected").text();

    if (slIndex > 0 || sgIndex > 0) {
      tdl = {};
      tdl.site_events = { pwm_filter: true, apply_filter: true };
      tdl.filter_by_location = strLocation;
      tdl.filter_by_service_group = strGroup;
      ttmCallTrackAnalytics();
    }
  }
};

const ttmHeaderSignIn = function () {
  $(".secure-signin--list-item a").on("click", function () {
    const globalLinkText = $(this).text();
    tdl = {};
    tdl.site_events = { global_auth_link: true };
    tdl.auth_lob = globalLinkText;
    ttmCallTrackAnalytics();
  });

  $("#signInDrop li a").on("click", function () {
    const globalLinkText = $(this).text();
    tdl = {};
    tdl.site_events = { global_auth_link: true };
    tdl.auth_lob = globalLinkText;
    ttmCallTrackAnalytics();
  });
};

const ttmDownloadFile = function () {
  let fileName = [];
  $(document).on(
    "click",
    'a[href*=".ashx"],a[href*=".pdf"],a[href*=".doc"]',
    function () {
      fileName = $(this).prop("href").split("/");
      tdl = {};
      tdl.site_events = { download_content: true };
      tdl.file_name = fileName[fileName.length - 1];
      ttmCallTrackAnalytics();
    }
  );
};

const ttmHomepageCarouselRotate = function () {
  $(".carousel").on("click", " .slick-dots li", function () {
    const hpPos = $(this).index() + 1;
    tdl = {};
    tdl.site_events = { hp_carousel_rotate: true };
    tdl.hp_carousel_position = hpPos;
    ttmCallTrackAnalytics();
  });
};

// called from app.js
const ttmCurrentDepositRate = function () {
  const rateSelected = $('select[name="ratesSelect"]').val();
  const regionSelected = $('select[name="regionSelect"]').val();
  const prodname = rateSelected.toLowerCase();

  tdl = {};
  tdl.site_events = { find_rate: true };
  tdl.product_type = prodname;
  tdl.region = regionSelected;
  ttmCallTrackAnalytics();
};

// called from app.js
const ttmCDCalculator = function (
  endingBalance,
  apy,
  averageMonthlyInterest,
  totalInterest
) {
  tdl = {};
  tdl.site_events = { find_cd_maturity: true };
  tdl.cd_attributes = {
    val_maturity: endingBalance,
    apy,
    mi: averageMonthlyInterest,
    interest: totalInterest,
    tax: "0",
    after_tax: "0",
  };
  ttmCallTrackAnalytics();
};

const ttmAlerts = function () {
  const $alerts = $("div[data-alert-type]:visible"); // home navigation alerts
  let alertTitle;
  let alertType;
  const tdlextend = tdl;
  delete tdlextend.site_events;
  delete tdlextend.alert_title;
  delete tdlextend.alert_type;

  // if(typeof _tdl.tls_ver != "undefined") tdlextend["tls_ver"] = _tdl.tls_ver;
  $alerts.each(function () {
    alertTitle = $(this).attr("data-alert-title");
    alertType = $(this).attr("data-alert-type");
    tdl = {};
    tdl.site_events = { alert_impression: true };
    tdl.alert_title = alertTitle;
    tdl.alert_type = alertType;
    $.extend(true, tdl, tdlextend);
    ttmCallTrackAnalytics();

    $(this)
      .find("a.close-alert")
      .each(function () {
        $(this).on("click", () => {
          tdl = {};
          tdl.site_events = { alert_close: true };
          tdl.alert_title = alertTitle;
          tdl.alert_type = alertType;
          ttmCallTrackAnalytics();
        });
      });
  });
};

const ttmCategoryTabThrough = function () {
  const $tabs = $("#custom-tab-panel");
  $tabs.find(".tab-title").each(function () {
    $(this).on("click", function () {
      tdl = {};
      tdl.site_events = { toggle_content_tab: true };
      tdl.content_tab_text = $(this).find("a").text().trim();
      ttmCallTrackAnalytics();
    });
  });
};

const ttmErrorPage = function () {
  tdl = {};
  tdl.site_events = { error_page: true };
  tdl.error_code = "404";
  ttmCallTrackAnalytics();
};

const ttmVideoPlayback = function (
  videoName,
  videoLength,
  playbackInterval,
  cuePointName,
  cuePointTime
) {
  tdl = {};
  tdl.site_events = { video_playback: true };
  tdl.video_name = videoName;
  tdl.video_length = videoLength;
  if (cuePointName !== undefined) {
    tdl.video_cue_point_name = cuePointName;
    tdl.video_cue_point_time = cuePointTime;
  }
  tdl.video_length = videoLength;
  tdl.playback_interval = playbackInterval;
  if (window.location.href.indexOf("/about-us/careers") >= 0) {
    tdl.event_element = `video playback: ${videoName}`;
  }
  ttmCallTrackAnalytics();
};

// 7.3.19 Audio Plays
const ttmAudioPlayback = function (audioName, audioLength, playbackInterval) {
  tdl = {};
  tdl.site_events = { audio_playback: true };
  tdl.audio_name = audioName;
  tdl.audio_length = audioLength;
  tdl.audio_playback_interval = playbackInterval;
  ttmCallTrackAnalytics();
};

const ttmWFFMContactUsSuccess = function () {
  const sessionFormSuccess = {};

  sessionFormSuccess.success = JSON.parse(
    sessionStorage.getItem("sessionFormData")
  );

  if (sessionFormSuccess.success != null) {
    tdl = {};
    // eslint-disable-next-line prefer-destructuring
    tdl = sessionFormSuccess.success[0];
    ttmCallTrackAnalytics();
    sessionStorage.removeItem("sessionFormData");
  }
};

const ttmWFFMValid = function () {
  const $form = $("form[data-sc-fxb]");
  let formJSON;
  const $formId = $form.attr("id");
  const $formDataWffm = $formId.substring(0, 40);
  const sessionFormData = [];
  const jsonData = {};
  const siteEvents = {};

  // V9 - WFFM
  switch ($formId) {
    case ttmCustomerLeadType.contact.id:
      // console.log("contact us form");
      formJSON = ttmCustomerLeadType.contact;
      jsonData.client =
        $form
          .find(`:input[name='${formJSON.fields[0].id.value}']:checked`)
          .val() === "Yes"
          ? "yes"
          : "no";
      jsonData.zip_code = $form
        .find(`:input[id='${$formDataWffm}${formJSON.fields[1].id.value}']`)
        .val();
      jsonData.customer_lead_id = scvisitor;
      jsonData.customer_lead_type = formJSON.label; // "have us contact you"
      jsonData.customer_lead_level =
        $form
          .find(`:input[name='${formJSON.fields[2].id.value}']:checked`)
          .val() === "Email"
          ? "partial: email"
          : "partial: phone";
      siteEvents.lead_submitted = true;
      siteEvents.contact_me = true;
      break;
    case ttmCustomerLeadType.feedback.id:
      // console.log("feedback");
      formJSON = ttmCustomerLeadType.feedback;
      jsonData.client =
        $form
          .find(`:input[name='${formJSON.fields[0].id.value}']:checked`)
          .val() === "Yes"
          ? "yes"
          : "no";
      jsonData.zip_code = $form
        .find(`:input[id='${$formDataWffm}${formJSON.fields[1].id.value}']`)
        .val();
      jsonData.response =
        $form
          .find(`:input[name='${formJSON.fields[6].id.value}']:checked`)
          .val() !== "No";
      siteEvents.send_feedback = true;
      break;
    case ttmCustomerLeadType.contactEngage.id:
      // console.log("contactEngage");
      formJSON = ttmCustomerLeadType.contactEngage;
      jsonData.client =
        $form
          .find(`:input[name='${formJSON.fields[0].id.value}']:checked`)
          .val() === "Yes"
          ? "yes"
          : "no";
      jsonData.zip_code = $form
        .find(`:input[id='${$formDataWffm}${formJSON.fields[1].id.value}']`)
        .val();
      jsonData.customer_lead_id = scvisitor;
      jsonData.customer_lead_type = formJSON.label;
      jsonData.customer_lead_level =
        $form
          .find(`:input[name='${formJSON.fields[2].id.value}']:checked`)
          .val() === "Email"
          ? "partial: email"
          : "partial: phone";
      siteEvents.lead_submitted = true;
      siteEvents.contact_me = true;
      break;
    case ttmCustomerLeadType.contactEnagageBusiness.id:
      // console.log("contactEnagageBusiness");
      formJSON = ttmCustomerLeadType.contactEnagageBusiness;
      jsonData.client =
        $form
          .find(`:input[name='${formJSON.fields[0].id.value}']:checked`)
          .val() === "Yes"
          ? "yes"
          : "no";
      jsonData.zip_code = $form
        .find(`:input[id='${$formDataWffm}${formJSON.fields[1].id.value}']`)
        .val();
      jsonData.company_name = $form
        .find(`:input[id='${$formDataWffm}${formJSON.fields[7].id.value}']`)
        .val();
      jsonData.customer_lead_id = scvisitor;
      jsonData.customer_lead_type = formJSON.label;
      jsonData.customer_lead_level =
        $form
          .find(`:input[name='${formJSON.fields[2].id.value}']:checked`)
          .val() === "Email"
          ? "partial: email"
          : "partial: phone";
      siteEvents.lead_submitted = true;
      siteEvents.contact_me = true;
      break;
    case ttmCustomerLeadType.contactEagleInvest.id:
      // console.log("contactEagleInvest");
      formJSON = ttmCustomerLeadType.contactEagleInvest;
      jsonData.client =
        $form
          .find(`:input[name='${formJSON.fields[0].id.value}']:checked`)
          .val() === "Yes"
          ? "yes"
          : "no";
      jsonData.zip_code = $form
        .find(`:input[id='${$formDataWffm}${formJSON.fields[1].id.value}']`)
        .val();
      jsonData.customer_lead_id = scvisitor;
      jsonData.customer_lead_type = formJSON.label;
      jsonData.customer_lead_level = "partial: email";
      siteEvents.lead_submitted = true;
      siteEvents.contact_me = true;
      break;
    case ttmCustomerLeadType.cdBirthdaySpecial.id:
      // console.log("cdBirthdaySpecial");
      formJSON = ttmCustomerLeadType.cdBirthdaySpecial;
      siteEvents.birthday_promo = true;
      jsonData.form_name = formJSON.label;
      break;
    case ttmCustomerLeadType.contactEngageMasterBrand.id:
      // console.log("contactEngageMasterBrand");
      formJSON = ttmCustomerLeadType.contactEngage;
      jsonData.client =
        $form
          .find(`:input[name='${formJSON.fields[0].id.value}']:checked`)
          .val() === "Yes"
          ? "yes"
          : "no";
      jsonData.zip_code = $form
        .find(`:input[id='${$formDataWffm}${formJSON.fields[1].id.value}']`)
        .val();
      jsonData.customer_lead_id = scvisitor;
      jsonData.customer_lead_type = formJSON.label;
      jsonData.customer_lead_level =
        $form
          .find(`:input[name='${formJSON.fields[2].id.value}']:checked`)
          .val() === "Email"
          ? "partial: email"
          : "partial: phone";
      siteEvents.lead_submitted = true;
      siteEvents.contact_me = true;
      break;
    default:
      break;
  }

  jsonData.site_events = siteEvents;
  sessionFormData.push(jsonData);
  try {
    sessionStorage.setItem("sessionFormData", JSON.stringify(sessionFormData));
    ttmWFFMContactUsSuccess();
  } catch (err) {
    console.log(err);
  }
};

const ttmWFFMError = function (formJSON, $formId) {
  const errorIdList = [];
  if ($('span[id*="Value-error"]').length > 0) {
    $('span[id*="Value-error"]').each((ind, obj) => {
      const errorId = $(obj)
        .attr("id")
        // eslint-disable-next-line no-useless-escape
        .replace(/[\[\].]/g, "_")
        .replace("-error", "");
      for (let j = 0; j < formJSON.fields.length; j++) {
        const fieldId = formJSON.fields[j].id.value;
        if (errorId === $formId + fieldId) {
          errorIdList.push(formJSON.fields[j].id.label);
        }
      }
    });
  }
  return errorIdList;
};

const ttmWFFMListError = function () {
  const $form = $("form[data-sc-fxb]");
  const $formId = $form.attr("id");
  let formJSON;
  let errorIdList = [];

  switch ($formId) {
    case ttmCustomerLeadType.contact.id:
      formJSON = ttmCustomerLeadType.contact;
      errorIdList = ttmWFFMError(formJSON, $formId);
      break;
    case ttmCustomerLeadType.feedback.id:
      formJSON = ttmCustomerLeadType.feedback;
      errorIdList = ttmWFFMError(formJSON, $formId);
      break;
    case ttmCustomerLeadType.contactEngage.id:
      formJSON = ttmCustomerLeadType.contactEngage;
      errorIdList = ttmWFFMError(formJSON, $formId);
      break;
    case ttmCustomerLeadType.contactEnagageBusiness.id:
      formJSON = ttmCustomerLeadType.contactEnagageBusiness;
      errorIdList = ttmWFFMError(formJSON, $formId);
      break;
    case ttmCustomerLeadType.contactEagleInvest.id:
      formJSON = ttmCustomerLeadType.contactEagleInvest;
      errorIdList = ttmWFFMError(formJSON, $formId);
      break;
    case ttmCustomerLeadType.cdBirthdaySpecial.id:
      formJSON = ttmCustomerLeadType.cdBirthdaySpecial;
      errorIdList = ttmWFFMError(formJSON, $formId);
      break;
    default:
      break;
  }
  if (errorIdList.length > 0) {
    // errors
    tdl = {};
    let errorList = "error for: ";
    for (let i = 0; i < errorIdList.length; i++) {
      errorList +=
        i < errorIdList.length - 1 ? `${errorIdList[i]}, ` : errorIdList[i];
    }
    tdl.site_events = { form_error: true };
    tdl.form_name = formJSON.label;
    tdl.error_code = errorList;
    ttmCallTrackAnalytics();
  }
};

const ttmWFFMSubmit = function () {
  $("body").on("click", "form[data-sc-fxb] input[type='submit']", function () {
    if ($(this).closest("form").valid()) {
      ttmWFFMValid();
    } else {
      window.setTimeout(ttmWFFMListError, 1000);
    }
  });
};

const ttmInterstitialMessages = function () {
  // Secondary button tagging
  $(document).on("click", ".close-wrapper .closeit-hide", function () {
    tdl = {};
    tdl.site_events = {
      interstitial_view: true,
      interstitial_do_not_show_again: true,
      interstitial_dismiss: false,
      MarketingID: $(this).data("marketing-id"),
      campaign_name: $(this).data("campaign-name"),
    };
    ttmCallTrackAnalyticsOLB();
  });

  // Primary button tagging
  $(document).on("click", ".close-wrapper .closeit", function () {
    tdl = {};
    tdl.site_events = {
      interstitial_view: true,
      interstitial_do_not_show_again: false,
      interstitial_dismiss: true,
      MarketingID: $(this).data("marketing-id"),
      campaign_name: $(this).data("campaign-name"),
    };
    ttmCallTrackAnalyticsOLB();
  });
};

// Called from FRB.trstusteermodal.js
const ttmOLBWrongPassword = function () {
  tdl = {};
  tdl.site_events = { forgot_pw_cred_err: true };
  ttmCallTrackAnalyticsOLB();
};

const ttmOLBForgotUsernamePasswordLink = function () {
  if ($(".bo-signin-form form[id='ubo_login']").length) {
    $("#ubo_login .ta_forgotusername").on("click", () => {
      tdl = {};
      tdl.site_events = {
        fgun_link: true,
      };
      ttmCallTrackAnalyticsOLB();
    });

    $("#ubo_login .ta_forgotpassword").on("click", () => {
      tdl = {};
      tdl.site_events = {
        fgpw_link: true,
      };
      ttmCallTrackAnalyticsOLB();
    });
  }
};

const ttmOLBSupportCall = function () {
  if ($(".bo-signin-form form[id='fp']").length) {
    $(".bo-support").on("click", 'a[href*="tel:"]', function () {
      tdl = {};
      tdl.site_events = {
        support_calls: true,
        call_online_banking: true,
      };

      if ($(this).hasClass("new-digital")) {
        tdl = {};
        tdl.site_events = {
          support_calls: true,
          call_new_digital_banking: true,
        };
      }
      ttmCallTrackAnalyticsOLB();
    });
  }
};

// Called from FRB.trstusteermodal.js
const ttmOLBForgotUsernameError = function () {
  tdl = {};
  tdl.site_events = {
    forgot_username: true,
    forgot_un_error: true,
  };
  ttmCallTrackAnalyticsOLB();
};

// 02/08/2015 depreciated with the new unified login, not called anymore.
const ttmOLBSignIn = function (analyticsIdQ2) {
  tdl = {};
  tdl.site_events = { login_button_click: true };
  tdl.frb_cp = [{ cd_7: analyticsIdQ2 }];
  _dl.authentication_status = "authenticated";
  ttmCallTrackAnalyticsOLB();
};

const ttmTestimonialComponent = function () {
  if ($(".component-client-testimonials").length > 0) {
    $(".slider-thumbnails").on("click", 'button[class*="slick-prev"]', () => {
      tdl = {};
      tdl.site_events = { testimonial_carousel_rotate_top_left: true };
      ttmCallTrackAnalytics();
    });

    $(".slider-thumbnails").on("click", 'button[class*="slick-next"]', () => {
      tdl = {};
      tdl.site_events = { testimonial_carousel_rotate_top_right: true };
      ttmCallTrackAnalytics();
    });

    $(".slider-thumbnails").on("click", "img", function () {
      tdl = {};
      tdl.site_events = { testimonial_carousel_image_click: true };
      tdl.testimonial_id = $(this).attr("aria-label");
      ttmCallTrackAnalytics();
    });

    $(".slider-details").on("click", "a.slick-custom-prev", () => {
      tdl = {};
      tdl.site_events = { testimonial_carousel_rotate_bottom_left: true };
      ttmCallTrackAnalytics();
    });

    $(".slider-details").on("click", "a.slick-custom-next", () => {
      tdl = {};
      tdl.site_events = { testimonial_carousel_rotate_bottom_right: true };
      ttmCallTrackAnalytics();
    });
  }
};

// (Tagging FRB Logo)
const ttmBrandLogo = function () {
  // Master Brand Ellington
  if (urlPath.indexOf("/engage/personal-banking-ellington") > -1) {
    $(".global-nav .logo a").attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "header",
      "data-ta-intcmp-id": "master_brand",
    });
  }

  if (
    urlPath.indexOf("/business/industry-lending/nonprofit-organizations") > -1
  ) {
    $(
      ".global-nav .logo a, .global-nav #contactDrop .contact-links li:first-child a"
    ).attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "global_header",
      "data-ta-intcmp-id": "non_campaign",
    });

    // To be removed when campaign is over
    $("#contactDrop").on("click", 'a[href*="tel:"]', () => {
      tdl = {};
      tdl.site_events = { call_customer_care: true };
      ttmCallTrackAnalytics();
    });
  }
};

// (Tagging Articles)
const ttmArticles = function () {
  if (urlPath.indexOf("/") > -1 && $(".homepage").length > 0) {
    $(".homepage .ta-linkable-content-preview a").attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "article",
      "data-ta-intcmp-id": "non_campaign",
    });
  }

  if (urlPath.indexOf("/personal/residential-lending") > -1) {
    $(".ta-linkable-content-preview a").attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "body",
      "data-ta-intcmp-id": "eagle_community",
    });
  }

  if (
    urlPath.indexOf(
      "/business/business-lines-of-credit/eagle-one-for-business"
    ) > -1
  ) {
    $(".ta-linkable-content-preview a").attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "article",
      "data-ta-intcmp-id": "eagle_business",
    });
  }
  if (
    urlPath.indexOf("/business/industry-lending/nonprofit-organizations") > -1
  ) {
    $(
      ".section-layout.component .medium-block-grid-2.large-block-grid-2 li a"
    ).attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "article",
      "data-ta-intcmp-id": "non_campaign",
    });
  }
  if (urlPath.indexOf("/engage/personal-banking-ellington") > -1) {
    $(".ta-linkable-content-preview a").attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "article",
      "data-ta-intcmp-id": "master_brand",
    });
  }
};

// (Tagging Services)
const ttmServices = function () {
  if (
    urlPath.indexOf("/business/industry-lending/nonprofit-organizations") >= -1
  ) {
    $(".service-list.content .service-list__list-item--title a").attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "service",
      "data-ta-intcmp-id": "non_campaign",
    });
  }
  // Master Brand Ellington
  if (urlPath.indexOf("/engage/personal-banking-ellington") > -1) {
    $(".service-list__list-item--description a").attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "service",
      "data-ta-intcmp-id": "master_brand",
    });
  }
};

// (Tagging Testimonials)
const ttmTestimonials = function () {
  if (
    urlPath.indexOf("/business/industry-lending/nonprofit-organizations") >= -1
  ) {
    $(
      ".featured-testimonial__component .featured-testimonial__buttons a.more-link"
    ).attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "testimonial",
      "data-ta-intcmp-id": "non_campaign",
    });

    $("#the-community p a").attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "commitment",
      "data-ta-intcmp-id": "non_campaign",
    });
  }
};

// (Tagging CTA)
const ttmCTA = function () {
  if (
    window.location.href.indexOf("/engage") > -1 ||
    window.location.href.indexOf("/personal/residential-lending") > -1 ||
    urlPath.indexOf(
      "/business/business-lines-of-credit/eagle-one-for-business"
    ) > -1
  ) {
    $(".contact-cta").on("click", 'a[href*="tel:"]', () => {
      tdl = {};
      tdl.site_events = { call_customer_care: true };
      ttmCallTrackAnalytics();
    });
    $(".contact-cta a")
      .not('[href*="tel:"]')
      .each((ind, obj) => {
        $(obj).attr({
          "data-ta-intcmp": "",
          "data-ta-intcmp-loc": "body",
          "data-ta-intcmp-id":
            window.location.href.indexOf("/personal/residential-lending") > -1
              ? "eagle_community"
              : "get_started_today",
        });
      });
  }

  if (urlPath.indexOf("/engage/personal-banking-ellington") > -1) {
    $(".contact-cta a")
      .not('[href*="tel:"]')
      .each((ind, obj) => {
        $(obj).attr({
          "data-ta-intcmp": "",
          "data-ta-intcmp-loc": "get_started_today",
          "data-ta-intcmp-id": "master_brand",
        });
      });
  }

  if (
    urlPath.indexOf(
      "/business/business-lines-of-credit/eagle-one-for-business"
    ) > -1
  ) {
    $(".contact-cta a")
      .not('[href*="tel:"]')
      .each((ind, obj) => {
        $(obj).attr({
          "data-ta-intcmp": "",
          "data-ta-intcmp-loc": "get_started_today",
          "data-ta-intcmp-id": "eagle_business",
        });
      });

    $(".rich-text.content a.button.supress-external-tooltip").attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "body",
      "data-ta-intcmp-id": "eagle_business",
    });
  }

  if (
    window.location.href.indexOf("/testimonials/marc-and-marjorie-mcmorris") >
      -1 ||
    window.location.href.indexOf("/testimonials/francisco-varela") > -1 ||
    window.location.href.indexOf("/testimonials/san-francisco-spca") > -1
  ) {
    $(".contact-cta a")
      .not('[href*="tel:"]')
      .each((ind, obj) => {
        $(obj).attr({
          "data-ta-intcmp": "",
          "data-ta-intcmp-loc": "get_started_today",
          "data-ta-intcmp-id": "non_campaign",
        });
      });
  }

  if ($("#fullPage").length && urlPath.indexOf("/engage/") >= 0) {
    $("#fullPage .contact-cta a")
      .not('[href*="tel:"]')
      .each((ind, obj) => {
        $(obj).attr({
          "data-ta-intcmp": "",
          "data-ta-intcmp-loc": "get_started_today",
          "data-ta-intcmp-id": "master_brand",
        });
      });
  }
};

/*
  DLG Tagging for Marketing Campaigns
*/
const ttmCampaignFall2016BB = function () {
  if (
    $(".service-list.content").length > 0 &&
    urlPath.indexOf("/engage/business-banking") >= 0
  ) {
    $(
      '.linkable-content-preview  .link-list__item a[href*="/articles-insights/"]'
    ).attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "article",
      "data-ta-intcmp-id": "business_banking_2016",
    });

    $(
      '.service-list.content .service-list__list-item--title a[href*="/articles-insights/"]'
    ).attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "article",
      "data-ta-intcmp-id": "business_banking_2016",
    });

    $(
      '.service-list.content .service-list__list-item--title a:not([href*="/articles-insights/"])'
    ).attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "service",
      "data-ta-intcmp-id": "business_banking_2016",
    });
  }
};

// PWM Campaign
const ttmCampaignFall2016PWM = function () {
  if (
    $(".service-list.content").length > 0 &&
    urlPath.indexOf("/engage/private-wealth-management") >= 0
  ) {
    $(
      '.linkable-content-preview  .link-list__item a[href*="/articles-insights/"]'
    ).attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "article",
      "data-ta-intcmp-id": "private_wealth_management_2016",
    });

    $(
      '.service-list.content .service-list__list-item--title a[href*="/articles-insights/"]'
    ).attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "article",
      "data-ta-intcmp-id": "private_wealth_management_2016",
    });

    $(
      '.service-list.content .service-list__list-item--title a:not([href*="/articles-insights/"])'
    ).attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "service",
      "data-ta-intcmp-id": "private_wealth_management_2016",
    });
  }
};

const ttmCampaignQ12017 = function () {
  if ($("#fullPage").length && urlPath.indexOf("/engage/") >= 0) {
    $(".static-header").on("click", 'a[href*="tel:"]', () => {
      tdl = {};
      tdl.site_events = { call_customer_care: true };
      ttmCallTrackAnalytics();
    });

    if ($(".nonprofits-campaign").length) {
      $("a.logo").attr({
        "data-ta-intcmp": "",
        "data-ta-intcmp-loc": "header",
        "data-ta-intcmp-id": "non_campaign",
      });

      $('.featured-articles__list-item  a[href*="/articles-insights/"]').attr({
        "data-ta-intcmp": "",
        "data-ta-intcmp-loc": "article",
        "data-ta-intcmp-id": "non_campaign",
      });
    } else {
      $("a.logo").attr({
        "data-ta-intcmp": "",
        "data-ta-intcmp-loc": "header",
        "data-ta-intcmp-id": "master_brand",
      });

      $('.featured-articles__list-item  a[href*="/articles-insights/"]').attr({
        "data-ta-intcmp": "",
        "data-ta-intcmp-loc": "article",
        "data-ta-intcmp-id": "master_brand",
      });
    }

    $('.hero-row a.button-large[href*="contact"]').attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "hero",
      "data-ta-intcmp-id": "get_started_today",
    });

    if (!$(".nonprofits-campaign").length) {
      $(
        '.service-list.content .service-list__list-item a:not([href*="/articles-insights/"])'
      ).attr({
        "data-ta-intcmp": "",
        "data-ta-intcmp-loc": "product",
        "data-ta-intcmp-id": "master_brand",
      });
    } else {
      $(
        '.service-list.content .service-list__list-item a:not([href*="/articles-insights/"])'
      ).attr({
        "data-ta-intcmp": "",
        "data-ta-intcmp-loc": "product",
        "data-ta-intcmp-id": "master_brand",
      });
    }

    $(".static-header a.button").each((ind, obj) => {
      $(obj).attr({
        "data-ta-intcmp": "",
        "data-ta-intcmp-loc": "header",
        "data-ta-intcmp-id": "master_brand",
      });
    });

    $(".full-page-slide-header-contact a.button").attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "get_started_today",
      "data-ta-intcmp-id": "master_brand",
    });

    if (urlPath.indexOf("/engage/nonprofits") > -1) {
      $(".static-header a.logo").attr({
        "data-ta-intcmp": "",
        "data-ta-intcmp-loc": "header_logo",
        "data-ta-intcmp-id": "nonprofit",
      });
      $(".static-header a.button").attr({
        "data-ta-intcmp": "",
        "data-ta-intcmp-loc": "header_contact",
        "data-ta-intcmp-id": "nonprofit",
      });
      $(
        '.service-list.content .service-list__list-item a:not([href*="/articles-insights/"])'
      ).attr({
        "data-ta-intcmp": "",
        "data-ta-intcmp-loc": "business",
        "data-ta-intcmp-id": "nonprofit",
      });
      $('.featured-articles__list-item  a[href*="/articles-insights/"]').attr({
        "data-ta-intcmp": "",
        "data-ta-intcmp-loc": "article",
        "data-ta-intcmp-id": "nonprofit",
      });
      $('.client-image-thumb  a[href*="/testimonials/"]').attr({
        "data-ta-intcmp": "",
        "data-ta-intcmp-loc": "testimonial",
        "data-ta-intcmp-id": "nonprofit",
      });
    }
  }
};

const ttmCampaignQ12017Testimonials = function () {
  if ($(".nonprofits-campaign").length) {
    $('.client-image-thumb  a[href*="/testimonials/"]').attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "body",
      "data-ta-intcmp-id": "non_campaign",
    });
  } else {
    $('.client-image-thumb  a[href*="/testimonials/"]').attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "testimonial",
      "data-ta-intcmp-id": "master_brand",
    });
  }

  if (urlPath.indexOf("/engage/nonprofits") > -1) {
    $('.client-image-thumb  a[href*="/testimonials/"]').attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "testimonial",
      "data-ta-intcmp-id": "nonprofit",
    });
  }

  $(".client-image-thumb a[data-reveal-id=videoModalTestimonial]").attr({
    "data-ta-intcmp": "",
    "data-ta-intcmp-loc": "body_testimonials",
    "data-ta-intcmp-id": "non_campaign",
  });
};

// Master Brand Campaign 2017 testimonial pages
const ttmCampaignQ12017RegularPage = function () {
  if (
    urlPath.indexOf("/testimonials/peloton") > -1 ||
    urlPath.indexOf("/testimonials/trevor-traina") > -1 ||
    urlPath.indexOf("/testimonials/marc-and-marjorie-mcmorris") > -1 ||
    urlPath.indexOf("/testimonials/francisco-varela") > -1 ||
    urlPath.indexOf("/testimonials/san-francisco-spca") > -1
  ) {
    if (
      urlPath.indexOf("/testimonials/francisco-varela") > -1 ||
      urlPath.indexOf("/testimonials/san-francisco-spca") > -1
    ) {
      $(".logo a").attr({
        "data-ta-intcmp": "",
        "data-ta-intcmp-loc": "global_header",
        "data-ta-intcmp-id": "non_campaign",
      });
    } else {
      $(".logo a").attr({
        "data-ta-intcmp": "",
        "data-ta-intcmp-loc": "header",
        "data-ta-intcmp-id": "non_campaign",
      });
    }
    $('#contactDrop a[href*="/have-us-contact-you"]').attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "header",
      "data-ta-intcmp-id": "non_campaign",
    });

    $(".link-list li a")
      .not('[href*="/articles-insights/"]')
      .each((ind, obj) => {
        $(obj).attr({
          "data-ta-intcmp": "",
          "data-ta-intcmp-loc": "product",
          "data-ta-intcmp-id": "non_campaign",
        });
      });

    $('.link-list li a[href*="/articles-insights/"]').attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "article",
      "data-ta-intcmp-id": "non_campaign",
    });

    $('.featured-articles__list-item a[href*="/about-us/testimonials/"]').attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "testimonial",
      "data-ta-intcmp-id": "non_campaign",
    });

    $(".contact-cta").on("click", 'a[href*="tel:"]', () => {
      tdl = {};
      tdl.site_events = { call_customer_care: true };
      ttmCallTrackAnalytics();
    });

    $(".contact-cta a")
      .not('[href*="tel:"]')
      .each((ind, obj) => {
        $(obj).attr({
          "data-ta-intcmp": "",
          "data-ta-intcmp-loc": "body",
          "data-ta-intcmp-id": "non_campaign",
        });
      });
  }
};

// Ian Bremmer 2018
const ttmCampaign2018IanBremmer = function () {
  if (urlPath.indexOf("/testimonials/ian-bremmer") > -1) {
    // DLG 2.1
    $(".contact-cta").on("click", 'a[href*="tel:"]', () => {
      tdl = {};
      tdl.site_events = {
        call_customer_care: true,
      };
      ttmCallTrackAnalytics();
    });

    // DLG 3.1
    $(".link-list li a")
      .not('[href*="/articles-insights/"]')
      .each((ind, obj) => {
        $(obj).attr({
          "data-ta-intcmp": "",
          "data-ta-intcmp-loc": "rail_products",
          "data-ta-intcmp-id": "non_campaign",
        });
      });

    // DLG 3.2
    $('.link-list li a[href*="/articles-insights/"]').each((ind, obj) => {
      $(obj).attr({
        "data-ta-intcmp": "",
        "data-ta-intcmp-loc": "rail_articles",
        "data-ta-intcmp-id": "non_campaign",
      });
    });

    // DLG 3.3
    $(".featured-articles__list-item a").attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "body_testimonials",
      "data-ta-intcmp-id": "non_campaign",
    });

    // DLG 3.4
    $(".contact-cta a")
      .not('[href*="tel:"]')
      .each((ind, obj) => {
        $(obj).attr({
          "data-ta-intcmp": "",
          "data-ta-intcmp-loc": "body_continue_form",
          "data-ta-intcmp-id": "non_campaign",
        });
      });

    // DLG 3.5
    $(".contact-cta a:last")
      .not('[href*="tel:"]')
      .each((ind, obj) => {
        $(obj).attr({
          "data-ta-intcmp": "",
          "data-ta-intcmp-loc": "body_find_office",
          "data-ta-intcmp-id": "non_campaign",
        });
      });
  }
};

// CD campaign 2018
const ttmCampaignCD = function () {
  if (
    window.location.href.indexOf("personal/cds/") === -1 &&
    window.location.href.indexOf("personal/cds") >= 0
  ) {
    $("a.heading-link[href*='/certificates-of-deposit']").attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "body_cd",
      "data-ta-intcmp-id": "non_campaign",
    });

    $("a.heading-link[href*='/liquid-certificates-of-deposit']").attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "body_liquid_cd",
      "data-ta-intcmp-id": "non_campaign",
    });

    $("#mainContent a[href*='/cd-calculator']").attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "body_cd_calculator",
      "data-ta-intcmp-id": "non_campaign",
    });

    $("#mainContent a[href*='/cds/cd-comparison']").attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "body_compare_cds",
      "data-ta-intcmp-id": "non_campaign",
    });

    $('#mainContent a[href*="/about-us/testimonials/"]').attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "body_testimonial_more",
      "data-ta-intcmp-id": "non_campaign",
    });

    $(".icon-buttons--item a[href*='/current-deposit']").attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "body_rates",
      "data-ta-intcmp-id": "non_campaign",
    });

    $(
      ".contact-cta__list-item a.contact-cta__mobile-link[href*='/contact-us/']"
    ).attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "get_started_contact_you",
      "data-ta-intcmp-id": "non_campaign",
    });

    $(
      ".contact-cta__list-item a.contact-cta__mobile-link[href*='/locations']"
    ).attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "get_started_find_office",
      "data-ta-intcmp-id": "non_campaign",
    });

    $(".contact-cta__list-item a.contact-cta__mobile-link[href*='tel:']").on(
      "click",
      () => {
        tdl = {};
        tdl.site_events = { call_customer_care: true };
        ttmCallTrackAnalytics();
      }
    );
  }

  if (urlPath.indexOf("/personal/cds/certificates-of-deposit") > -1) {
    // DLG 2.1 & 2.2
    $("#contactDrop, .contact-cta__list-item--links").on(
      "click",
      'a[href*="tel:"]',
      () => {
        tdl = {};
        tdl.site_events = {
          call_customer_care: true,
        };
        ttmCallTrackAnalytics();
      }
    );

    // DLG 3.1
    $(".global-nav .logo a").attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "header_frb_logo",
      "data-ta-intcmp-id": "non_campaign",
    });

    // DLG 3.2
    $('#contactDrop a[href*="/have-us-contact-you"]').attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "header_contact_you",
      "data-ta-intcmp-id": "non_campaign",
    });

    // DLG 3.3
    $('.hero-box a.button[href*=".ashx"]').attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "body_special_offer",
      "data-ta-intcmp-id": "non_campaign",
    });

    // DLG 3.4
    $('a[href*="/current-deposit-rates"]').attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "body_browse_rates",
      "data-ta-intcmp-id": "non_campaign",
    });

    // DLG 3.5
    $('a[href*="/cd-calculator"]').attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "body_cd_calculator",
      "data-ta-intcmp-id": "non_campaign",
    });

    // DLG 3.6
    $('.contact-cta__list-item--links a[href*="/have-us-contact-you"]').attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "body_continue_form",
      "data-ta-intcmp-id": "non_campaign",
    });

    // DLG (PBI 588632)
    $.each($(".dash-box .term a[href*='.ashx']"), function () {
      $(this).attr({
        "data-ta-intcmp": "",
        "data-ta-intcmp-loc": `body_special_offer_${$(this)
          .closest(".dash-box")
          .find(".rates")
          .text()}`,
        "data-ta-intcmp-id": "non_campaign",
      });
    });
  }
};

const ttmCampaign2017AIO = function () {
  // for /student-loan-refinancing Master Brand Campaign 2017
  if ($(".aio-estimator-wrapper").length) {
    $(".logo a").attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "header",
      "data-ta-intcmp-id": "fund_your_dreams",
    });
    $('#contactDrop a[href*="/have-us-contact-you"]').attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "header",
      "data-ta-intcmp-id": "fund_your_dreams",
    });

    $(".ta-linkable-content-preview a").attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "body",
      "data-ta-intcmp-id": "fund_your_dreams",
    });
  }
};

const ttmSeeWhatItTakes = function (siteEvents, loanObject) {
  const siteEvent = {};
  siteEvent[siteEvents] = true;
  tdl = {};
  tdl.site_events = siteEvent;
  tdl.loan_attributes = loanObject;
  ttmCallTrackAnalytics();
};

const ttmFYDGetLoanAttributes = function (loanObject) {
  // AIO 3 steps
  $("#seeWhat").on("click", () => {
    ttmSeeWhatItTakes("see_what_it_takes", loanObject);
  });

  $("#findBanker").on("click", () => {
    ttmSeeWhatItTakes("find_a_banker", loanObject);
  });
};

// Track Analytics for Google Landing Page
const ttmGoogleScrollingEvent = function () {
  const siteEvent = {};
  const st = $(this).scrollTop();
  let scrollQuartile;
  let scrollDirection;
  tdl = {};

  if (st > lastScrollTop) {
    scrollDirection = "down";
  } else if (st < lastScrollTop) {
    scrollDirection = "up";
  } else if (st === lastScrollTop) {
    // do nothing
    // In IE this is an important condition because there seems to be some instances where the last scrollTop is equal to the new one
  }
  lastScrollTop = st;
  const s = $(window).scrollTop();
  const d = $(document).height();
  const c = $(window).height();
  // eslint-disable-next-line radix
  const scrollPercent = parseInt((s / (d - c)) * 100);

  if (scrollPercent <= 25) {
    scrollQuartile = 1;
  } else if (scrollPercent >= 25 && scrollPercent <= 50) {
    scrollQuartile = 2;
  } else if (scrollPercent > 50 && scrollPercent <= 75) {
    scrollQuartile = 3;
  } else if (scrollPercent > 75) {
    scrollQuartile = 4;
  }
  siteEvent.page_scroll = true;
  tdl.site_events = siteEvent;
  tdl.scroll_quartile = scrollQuartile;
  tdl.scroll_direction = scrollDirection;
  ttmCallTrackAnalytics();
};

// Self ID Not Qualified
const ttmSelfIDNotQualified = function (siteEvents) {
  const siteEvent = {};

  siteEvent[siteEvents] = true;

  tdl = {};
  tdl.site_events = siteEvent;

  ttmCallTrackAnalytics();
};

// 6.6	AIO Estimator – Landing Page
const ttmSLROneStepOnInit = function () {
  const siteEvent = {};
  siteEvent.aio_estimator_landing = true;
  tdl = {};
  tdl.site_events = siteEvent;
  tdl.customer_lead_id = scvisitor;
  ttmCallTrackAnalytics();
};

// 6.7	E14B Estimator – Landing Page
const ttmE14BOnInit = function () {
  const siteEvent = {};
  siteEvent.e14b_estimator_landing = true;
  tdl = {};
  tdl.site_events = siteEvent;
  tdl.customer_lead_id = scvisitor;
  ttmCallTrackAnalytics();
};

// 7.4.3	E14B Estimator – "Continue" Click
const ttmE14BContinue = function (siteEvents) {
  const siteEvent = {};
  siteEvent[siteEvents] = true;
  tdl = {};
  tdl.site_events = siteEvent;
  tdl.customer_lead_id = scvisitor;
  ttmCallTrackAnalytics();
};

// 7.4.4	E14B Estimator – Step 2 Page View
const ttmE14BStep2 = function (siteEvents) {
  const siteEvent = {};
  siteEvent[siteEvents] = true;
  tdl = {};
  tdl.site_events = siteEvent;
  tdl.customer_lead_id = scvisitor;
  ttmCallTrackAnalytics();
};

const ttmPLOCBankerInfo = function () {
  const siteEvent = {};
  siteEvent.ploc_banker_info = true;
  tdl = {};
  tdl.site_events = siteEvent;
  tdl.customer_lead_id = scvisitor;
  ttmCallTrackAnalytics();
};

// 7.4.5
const ttmE14BConnectNow = function (
  siteEventsObjects,
  customerObject,
  teamObject,
  formFieldsObject
) {
  tdl = {};
  tdl.site_events = siteEventsObjects;
  tdl.customer_lead_id = customerObject.customerLeadId;
  tdl.customer_lead_type = customerObject.customerLeadType;
  tdl.customer_lead_level = customerObject.customerLeadLevel;
  tdl.team_member_id = teamObject.teamMemberId;
  tdl.business_name = formFieldsObject.business_Name;
  tdl.years_in_business = formFieldsObject.years_in_business;
  tdl.business_industry = formFieldsObject.business_industry;
  tdl.annual_revenue = formFieldsObject.annual_revenue;
  tdl.loan_amount = formFieldsObject.loan_amount;
  tdl.two_years_profitable = formFieldsObject.two_years_profitable;
  tdl.e14b_referred_by = formFieldsObject.e14b_referred_by;
  tdl.e14b_referred_by_answer = formFieldsObject.e14b_referred_by_answer;
  tdl.zip_code = formFieldsObject.zip_code;
  ttmCallTrackAnalytics();
};

// submit the estimator 2 steps. called from FRB.aioEstimatorNew.js
// 7.5.29	AIO Estimator – "See My Results"
const ttmEstimatorContactRequest = function (
  siteEventsObjects,
  customerObject,
  teamObject
) {
  tdl = {};
  tdl.site_events = siteEventsObjects;
  tdl.customer_lead_id = customerObject.customerLeadId;
  tdl.customer_lead_type = customerObject.customerLeadType;
  tdl.customer_lead_level = customerObject.customerLeadLevel;
  tdl.team_member_id = teamObject.teamMemberId;

  ttmCallTrackAnalytics();
};

// 7.5.18	AIO Estimator – Schedule an appointment
// 7.4.6	    E14B          – Schedule an appointment
const ttmE14BScheduleAppointment = function (siteEvents) {
  const siteEvent = {};
  siteEvent[siteEvents] = true;
  tdl = {};
  tdl.site_events = siteEvent;
  ttmCallTrackAnalytics();
};

// Contact a banker linkedin tagging
const ttmEstimatorBankerSocialInfo = function (
  siteEvent1,
  siteEvent2,
  exitURL,
  loanObject,
  teamObject
) {
  const siteEvent = {};

  siteEvent[siteEvent1] = true;
  siteEvent[siteEvent2] = true;

  tdl = {};
  tdl.site_events = siteEvent;
  tdl.exit_url = exitURL;
  tdl.loan_attributes = loanObject;
  tdl.team_member_id = teamObject.teamMemberId;
  tdl.prod_lob = "student loan refinancing";
  ttmCallTrackAnalytics();
};
// 7.5.6	Email a Sales Manager
const ttmEstimatorRMInfo = function (
  siteEvents,
  customerObject,
  loanObject,
  teamObject
) {
  const siteEvent = {};

  siteEvent[siteEvents] = true;

  tdl = {};
  tdl.site_events = siteEvent;
  tdl.customer_lead_id = customerObject.customerLeadId;
  tdl.customer_lead_type = customerObject.customerLeadType;
  tdl.customer_lead_level = customerObject.customerLeadLevel;
  tdl.loan_attributes = loanObject;
  tdl.team_member_id = teamObject.teamMemberId;
  tdl.prod_lob = "student loan refinancing";
  ttmCallTrackAnalytics();
};

const ttmRelationshipBasedPricing = function (evt) {
  const siteEvent = {};

  siteEvent.rel_price_toggle = true;

  tdl = {};
  tdl.site_events = siteEvent;
  tdl.percent_loan_amt = evt;
  ttmCallTrackAnalytics();
};

const ttmPLOCScheduleAppointment = function () {
  const siteEvent = {};

  siteEvent.ploc_appt_sched = true;

  tdl = {};
  tdl.site_events = siteEvent;

  ttmCallTrackAnalytics();
};

const ttmPLOCRelationshipBasedPricing = function (evt) {
  const siteEvent = {};

  siteEvent.ploc_rel_price_toggle = true;

  tdl = {};
  tdl.site_events = siteEvent;
  tdl.percent_loan_amt = evt;
  ttmCallTrackAnalytics();
};

const ttmEstimatorStep1 = function (
  zipCode,
  plocReferredBy,
  plocReferredByAnswer,
  promoCode
) {
  const siteEvent = {};

  siteEvent.ploc_calc_contact_info_continue_click = true;

  tdl = {};
  tdl.site_events = siteEvent;
  tdl.zip_code = zipCode;
  tdl.ploc_referred_by = plocReferredBy;
  tdl.ploc_referred_by_answer = plocReferredByAnswer;
  tdl.promo_code = promoCode;
  ttmCallTrackAnalytics();
};

const ttmEstimatorStep2 = function (
  loanUsedFor,
  loanUsedForOtherExplain,
  loanUseOfFunds,
  existingDebt,
  loanAmt,
  currentScreen
) {
  const siteEvent = {};

  siteEvent.ploc_calc_loan_info_continue_click = true;

  tdl = {};
  tdl.site_events = siteEvent;
  tdl.loan_used_for = loanUsedFor;
  tdl.loan_used_for_other_explain = loanUsedForOtherExplain;
  tdl.loan_use_of_funds = loanUseOfFunds;
  tdl.existing_debt = existingDebt;
  tdl.loan_amt = loanAmt;
  tdl.current_screen = currentScreen;
  ttmCallTrackAnalytics();
};

const ttmEstimatorStep3 = function (
  creditScore,
  annualIncome,
  balanceOfAccounts
) {
  const siteEvent = {};

  siteEvent.ploc_calc_finances_submit_click = true;

  tdl = {};
  tdl.site_events = siteEvent;
  tdl.credit_score = creditScore;
  tdl.annual_income = annualIncome;
  tdl.balance_of_accounts = balanceOfAccounts;
  ttmCallTrackAnalytics();
};

const ttmPLOCPotentialSavings = function (
  currTerm,
  currRate,
  currLoanAmt,
  potentialSavingAmt,
  selPercentLoanAmt,
  chosenTermRate,
  loanType,
  existingDebt
) {
  const siteEvent = {};

  siteEvent.potential_savings_cta = true;

  tdl = {};
  tdl.site_events = siteEvent;
  tdl.chosen_term_rate = chosenTermRate;
  tdl.loan_type = loanType;
  tdl.existing_debt = existingDebt;
  tdl.cur_term = currTerm;
  tdl.cur_rate = currRate;
  tdl.cur_loan_amt = currLoanAmt;
  tdl.potential_savings_amt = potentialSavingAmt;
  tdl.sel_percent_loan_amt = selPercentLoanAmt;
  ttmCallTrackAnalytics();
};

const ttmPLOCLoanTerms = function (currTerm) {
  tdl = {};
  tdl.site_events = {
    ploc_tab_rate: true,
  };
  tdl.cur_term = currTerm;
  ttmCallTrackAnalytics();
};

const ttmPotentialSavings = function (
  currTerm,
  currRate,
  currLoanAmt,
  potentialSavingAmt,
  selPercentLoanAmt,
  chosenTermRate
) {
  const siteEvent = {};

  siteEvent.potential_savings_cta = true;

  tdl = {};
  tdl.site_events = siteEvent;
  tdl.chosen_term_rate = chosenTermRate;
  tdl.cur_term = currTerm;
  tdl.cur_rate = currRate;
  tdl.cur_loan_amt = currLoanAmt;
  tdl.potential_savings_amt = potentialSavingAmt;
  tdl.sel_percent_loan_amt = selPercentLoanAmt;
  ttmCallTrackAnalytics();
};

/** ***** Analytics for Digital Banking Desktop & Mobile ****** */
const ttmDigitalBankingPage = function () {
  if ($("#fullPage").length && $(".digital-banking-page-select").length) {
    $(".static-header a.button").attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "header_get_started",
      "data-ta-intcmp-id": "non_campaign",
    });

    // Tag Customer Care Tel
    $(".static-header").on("click", 'a[href*="tel:"]', () => {
      tdl = {};
      tdl.site_events = {
        call_customer_care: true,
      };
      ttmCallTrackAnalytics();
    });
    let intcmpLocValue = "body";

    $(".service-list a")
      .not("[class*='heading']")
      .each((ind, obj) => {
        intcmpLocValue = "body";
        if (
          $(obj).attr("href").indexOf("/digitalbanking/firsttimedesktop") > -1
        ) {
          intcmpLocValue = "body_first_time_desktop";
        } else if (
          $(obj).attr("href").indexOf("/digitalbanking/firsttimemobiles") > -1
        ) {
          intcmpLocValue = "body_first_time_mobile";
        } else if (
          $(obj).attr("href").indexOf("/digitalbanking/quicken") > -1
        ) {
          intcmpLocValue = "body_finmgmt_tool";
        } else if (
          $(obj).attr("href").indexOf("/digitalbanking/features") > -1
        ) {
          intcmpLocValue = "body_digital_banking_features";
        }
        $(obj).attr({
          "data-ta-intcmp": "",
          "data-ta-intcmp-loc": intcmpLocValue,
          "data-ta-intcmp-id": "non_campaign",
        });
      });

    $(".contact-cta").on("click", 'a[href*="tel:"]', () => {
      tdl = {};
      tdl.site_events = {
        call_customer_care: true,
      };
      ttmCallTrackAnalytics();
    });

    $(".contact-cta a").each((ind, obj) => {
      // window.location.href.indexOf("/personal/residential-lending") > -1
      // ? "eagle_community"
      // : "get_started_today";

      intcmpLocValue = "body";
      if ($(obj).attr("href").indexOf("/personal") > -1) {
        intcmpLocValue = "body_get_started";
      } else if ($(obj).attr("href").indexOf("online.firstrepublic.com") > -1) {
        intcmpLocValue = "body_enroll";
      } else if ($(obj).attr("href").indexOf("/bankingonline") > -1) {
        intcmpLocValue = "body_sign_in";
      }
      $(obj).attr({
        "data-ta-intcmp": "",
        "data-ta-intcmp-loc": intcmpLocValue,
        "data-ta-intcmp-id": "non_campaign",
      });
    });
  }
};

/** ***** Analytics for AIO Desktop & Mobile ****** */
const ttmCampaignStudentLoan = function () {
  if (
    $("#fullPage").length &&
    $(".aio-landing-page-select").length &&
    window.location.href.indexOf("/about-us/careers") === -1
  ) {
    // Tag logo
    $(".static-header a.logo, .static-header a.button").attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "header",
      "data-ta-intcmp-id": "aio",
    });

    // Tag Customer Care Tel
    $(".static-header").on("click", 'a[href*="tel:"]', () => {
      tdl = {};
      tdl.site_events = {
        call_customer_care: true,
      };
      ttmCallTrackAnalytics();
    });

    // Tag Get Your Rate
    $.each($("#fullPage section[data-anchor]"), function (index) {
      const newIndex = index + 1;
      $(this)
        .find("a.get-your-rate")
        .attr({
          "data-ta-intcmp": "",
          "data-ta-intcmp-loc": `slide_${newIndex}`,
          "data-ta-intcmp-id": "aio",
        });
    });

    // Tag Articles
    $('section[data-anchor="slide7"] .featured-articles__list-item a').attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "article",
      "data-ta-intcmp-id": "aio",
    });

    // Footer Slide
    $('section[data-anchor="slide8"] .service-list__list-item a').attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "footer",
      "data-ta-intcmp-id": "aio",
    });
  }
};

const ttmEstimatorFullpage = function () {
  if (
    ($("#fullPage").length &&
      $(".aio-estimator-wrapper-new.fullPage").length) ||
    $(".estimator-tracker").length
  ) {
    // Tag logo
    $(".static-header a.logo, .static-header a.button").attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "header",
      "data-ta-intcmp-id": "non_campaign",
    });

    // Tag Customer Care Tel
    $(".static-header").on("click", 'a[href*="tel:"]', () => {
      tdl = {};
      tdl.site_events = {
        call_customer_care: true,
      };
      ttmCallTrackAnalytics();
    });

    $(".aio-steps a.sym-exclamation").on("click", () => {
      ttmSelfIDNotQualified("why_we_need_to_know");
    });

    // 7.5.17	AIO Estimator – Rate Slider
    $(".aio-estimator-wrapper-new").on(
      "mouseup keyup",
      ".slider-wrapper #slider",
      () => {
        tdl = {};
        tdl.site_events = {
          slider_rate: true,
        };
        ttmCallTrackAnalytics();
      }
    );
    // 7.5.31
    $(".rate-tabs-content").on("click", "ul.rates-box li a.rates-links", () => {
      ttmPLOCLoanTerms();
    });
  }
};

const ttmCareers = function () {
  if (window.location.href.indexOf("/about-us/careers") >= 0) {
    // DLG 4.1 Search Openings - Header
    $('nav a[href*="careers-firstrepublic"]').attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "global_header_search_openings",
      "data-ta-intcmp-id": "non_campaign",
    });

    // DLG 4.2 Search Openings – Hero CTA
    $('.hero-box a[href*="careers-firstrepublic"]').attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "hero_search_openings",
      "data-ta-intcmp-id": "non_campaign",
    });

    // DLG 4.3 Learn More - Volunteering
    $(
      '.service-list__list .service-list__list-item a[href*="commitment-to-the-community"]'
    ).attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "learn_more_volunteering",
      "data-ta-intcmp-id": "non_campaign",
    });

    // DLG 4.4 Learn More – Values and Vision
    $(
      '.service-list__list .service-list__list-item a[href*="corporate-values"]'
    ).attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "learn_more_values_vision",
      "data-ta-intcmp-id": "non_campaign",
    });

    // DLG 4.5 Learn More – Strength and Stability
    $(
      '.service-list__list .service-list__list-item a[href*="financial-strength"]'
    ).attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "learn_more_strength_stability",
      "data-ta-intcmp-id": "non_campaign",
    });

    // DLG 4.6 FRB Logo
    $(".static-header a.logo").attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "global_header_logo",
      "data-ta-intcmp-id": "non_campaign",
    });
  }
};

const ttmCOL = function () {
  if (window.location.href.indexOf("/business/corporate-online-banking") >= 0) {
    // DLG 3.2 Privacy and Security Center
    $(".security a[href*='/privacy/security-and-fraud-prevention']").attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "body_privacy_security",
      "data-ta-intcmp-id": "non_campaign",
    });

    // DLG 3.1 Secure Sign In Hero Banner
    $(
      ".hero-box a[href*='www.frcorporateonline.com/wcmfd/wcmpw/CustomerLogin']"
    ).attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "hero_signin",
      "data-ta-intcmp-id": "non_campaign",
    });

    // DLG 2.1 Download File, Global func is available TTM_download_file, need to do UI changes (Add download file link)
    // DLG 2.2 Video Playback
  }
};

const ttmWFFMFormThankYou = function () {
  if (
    $(".wffm-from").length &&
    $(".wffm-from").data("wffm-from") ===
      ttmCustomerLeadType.cdBirthdaySpecial.id
  ) {
    _dl.site_events = {
      lead_submitted: true,
      contact_me: true,
    };
    _dl.form_name = ttmCustomerLeadType.cdBirthdaySpecial.label;
  }
};

const ttmAlphabetPage = function () {
  if (urlPath.indexOf("/custom/alphabet") > -1) {
    // email
    $('.contact-cta__list-item--links a[href*="mailto:"]').on("click", () => {
      tdl = {};
      tdl.site_events = { email_customer_care: true };
      ttmCallTrackAnalytics();
    });

    // adding attribute under "Your Needs"
    $(".service-list__list-item--title a.heading-link").attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "product",
      "data-ta-intcmp-id": "non_campaign",
    });

    // adding attribute under "Financial Education"
    $('#financial-education a[href*="/articles-insights/"] ').attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "article",
      "data-ta-intcmp-id": "non_campaign",
    });
  }
};

const ttmGoogle = function () {
  const list = document.getElementsByTagName("link");
  let hasCanonical = false;

  // iterate over elements and output rel attribute values
  for (let i = 0; i < list.length; i++) {
    if (list[i].getAttribute("rel") === "canonical") {
      hasCanonical = true;
    }
  }

  if (
    hasCanonical &&
    $("link[rel='canonical']").attr("href").indexOf("/custom/google") > -1
  ) {
    // email
    $('a[href*="mailto:"]').on("click", () => {
      tdl = {};
      tdl.site_events = { email_customer_care: true };
      ttmCallTrackAnalytics();
    });
  }
};

// Track Analytics for Google Landing Page

const ttmGoogleScheduleAppointment = function () {
  const siteEvent = {};
  tdl = {};
  siteEvent.gp_hero_tap_sched_appt = true;
  tdl.site_events = siteEvent;
  ttmCallTrackAnalytics();
};

const ttmGoogleHeroLocation = function () {
  const siteEvent = {};

  tdl = {};
  siteEvent.gp_hero_tap_location = true;
  tdl.site_events = siteEvent;
  ttmCallTrackAnalytics();
};

const ttmGoogleHeroCall = function () {
  const siteEvent = {};

  tdl = {};
  siteEvent.gp_hero_tap_call = true;
  tdl.site_events = siteEvent;
  ttmCallTrackAnalytics();
};

const ttmGoogleBankerEmail = function () {
  const siteEvent = {};
  tdl = {};
  siteEvent.gp_hero_tap_banker_email = true;
  tdl.site_events = siteEvent;
  ttmCallTrackAnalytics();
};

const ttmGoogleStickyFooter = function () {
  const siteEvent = {};
  tdl = {};
  siteEvent.gp_sticky_footer_tap_call = true;
  siteEvent.call_customer_care = true;
  siteEvent.call_branch = true;

  tdl.site_events = siteEvent;
  ttmCallTrackAnalytics();
};

const ttmGooleStickyFooterScheduleAppointment = function () {
  const siteEvent = {};
  tdl = {};
  siteEvent.gp_sticky_footer_tap_sched_appt = true;
  tdl.site_events = siteEvent;
  ttmCallTrackAnalytics();
};

const ttmGoogleAboutFRB = function () {
  const siteEvent = {};
  tdl = {};
  siteEvent.gp_tap_about_frb = true;
  tdl.site_events = siteEvent;
  ttmCallTrackAnalytics();
};

const ttmGoogleExceptionalOffers = function () {
  const siteEvent = {};
  tdl = {};
  siteEvent.gp_tap_offers = true;
  tdl.site_events = siteEvent;
  ttmCallTrackAnalytics();
};

const ttmGoogleMeetFRBTeam = function () {
  const siteEvent = {};
  tdl = {};
  siteEvent.gp_toggle_choose_your_team = true;
  tdl.site_events = siteEvent;
  ttmCallTrackAnalytics();
};

const ttmGoogleCallOrTextFRB = function () {
  const siteEvent = {};
  tdl = {};
  siteEvent.gp_tap_meet_your_team_call = true;
  tdl.site_events = siteEvent;
  ttmCallTrackAnalytics();
};

const ttmGoogleConnectOtherWayFRB = function () {
  const siteEvent = {};
  tdl = {};
  siteEvent.gp_tap_meet_your_team_connect = true;
  tdl.site_events = siteEvent;
  ttmCallTrackAnalytics();
};

const ttmGoogleMeetFRBBanker = function () {
  const siteEvent = {};
  tdl = {};
  siteEvent.gp_tap_meet_your_team_email = true;
  tdl.site_events = siteEvent;
  ttmCallTrackAnalytics();
};

const ttmGoogleScheduleMeetWithFRBBanker = function () {
  const siteEvent = {};
  tdl = {};
  siteEvent.gp_tap_meet_your_team_sched_appt = true;
  tdl.site_events = siteEvent;
  ttmCallTrackAnalytics();
};
const ttmGoogleHeroGetStarted = function () {
  const siteEvent = {};
  tdl = {};
  siteEvent.gp_hero_tap_get_started = true;
  tdl.site_events = siteEvent;
  ttmCallTrackAnalytics();
};

const ttmGoogleStickyFooterGetStarted = function () {
  const siteEvent = {};
  tdl = {};
  siteEvent.gp_sticky_footer_tap_get_started = true;
  tdl.site_events = siteEvent;
  ttmCallTrackAnalytics();
};

const ttmGoogleOpenAccountBonus = function () {
  const siteEvent = {};
  tdl = {};
  siteEvent.gp_bonuses_tap_open_account = true;
  tdl.site_events = siteEvent;
  ttmCallTrackAnalytics();
};

const ttmGoogleScheduleAppointmentModal = function () {
  const siteEvent = {};
  tdl = {};
  siteEvent.gp_get_started_modal_tap_sched_appt = true;
  tdl.site_events = siteEvent;
  ttmCallTrackAnalytics();
};
const ttmGoogleOpenAccountModal = function () {
  const siteEvent = {};
  tdl = {};
  siteEvent.gp_get_started_modal_tap_open_acct = true;
  tdl.site_events = siteEvent;
  ttmCallTrackAnalytics();
};
const ttmGoogleLoginModal = function () {
  const siteEvent = {};
  tdl = {};
  siteEvent.gp_get_started_modal_tap_login = true;
  tdl.site_events = siteEvent;
  ttmCallTrackAnalytics();
};
const ttmGoogleSignUpFormSubmit = function () {
  const siteEvent = {};
  tdl = {};
  siteEvent.gp_signup_form_modal_tap_submit = true;
  tdl.site_events = siteEvent;
  ttmCallTrackAnalytics();
};
// DLG 7.9 Video Playback
const ttmVimeoVideoPlayback = function (name, length, interval) {
  tdl = {};
  tdl.site_events = {
    video_playback: true,
  };
  tdl.video_name = name;
  tdl.video_length = length;
  tdl.playback_interval = interval;
  tdl.event_element = `video playback: ${name}`;
  ttmCallTrackAnalytics();
};
const ttmMobileBankingFAQs = function (question) {
  tdl = {};
  tdl.site_events = {
    toggle_content_tab: true,
  };
  tdl.content_tab_text = question;
  ttmCallTrackAnalytics();
};
// Trusted Partner
const ttmTPProviderAllPartners = function () {
  tdl = {};
  tdl.site_events = {
    trusted_partners_tap_providers_all_partners: true,
  };
  ttmCallTrackAnalytics();
};
const ttmTPProviderFinancial = function () {
  tdl = {};
  tdl.site_events = {
    trusted_partners_tap_providers_financial: true,
  };
  ttmCallTrackAnalytics();
};
const ttmTPProviderProfessional = function () {
  tdl = {};
  tdl.site_events = {
    trusted_partners_tap_providers_professional: true,
  };
  ttmCallTrackAnalytics();
};
const ttmTPProviderNonProfit = function () {
  tdl = {};
  tdl.site_events = {
    trusted_partners_tap_providers_non_profit: true,
  };
  ttmCallTrackAnalytics();
};
const ttmTPProviderRealEstate = function () {
  tdl = {};
  tdl.site_events = {
    trusted_partners_tap_providers_real_estate: true,
  };
  ttmCallTrackAnalytics();
};
const ttmTPProviderTech = function () {
  tdl = {};
  tdl.site_events = {
    trusted_partners_tap_providers_tech: true,
  };
  ttmCallTrackAnalytics();
};
const ttmCampaign2019 = function () {
  // private welath management page
  if ($("h1").text().toLowerCase() === "private wealth management") {
    // have us contact you
    $('.contact-cta__list-item--links a[href*="contact-us/"]').attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "get_started_contact",
      "data-ta-intcmp-id": "non_campaign",
    });
    // meet the team
    $('.contact-cta__list-item--links a[href*="/team"]').attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "get_started_team",
      "data-ta-intcmp-id": "non_campaign",
    });

    // tab 1 articles links
    $(".featured-articles a").attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "article",
      "data-ta-intcmp-id": "non_campaign",
    });

    // tab 2 services
    $(
      ".tab-panels ul#custom-accordion li.accordion-navigation:nth-child(2) #your-needs a"
    ).attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "service",
      "data-ta-intcmp-id": "non_campaign",
    });

    // team member in
    $(".the-team a.heading-link").on("click", function () {
      tdl = {};
      tdl.site_events = { view_teammember_profile: true };
      tdl.team_member = $(this).text();
      ttmCallTrackAnalytics();
    });
  }

  // who we are page
  if ($(".who-we-are-new").length > 0) {
    // have us contact you
    $('.contact-cta__list-item--links a[href*="contact-us/"]').attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "get_started_contact",
      "data-ta-intcmp-id": "non_campaign",
    });
    // meet the team
    $('.contact-cta__list-item--links a[href*="/locations"]').attr({
      "data-ta-intcmp": "",
      "data-ta-intcmp-loc": "find_an_office",
      "data-ta-intcmp-id": "non_campaign",
    });
  }
};

// SLR Standalone Savings Calculator DLG
// dlg 7.5.21
const ttmSavingsCalculatorCurrentRateTabs = function (currentScreen) {
  tdl = {};
  tdl.site_events = { standalone_tap_rate: true };
  tdl.current_screen = currentScreen;
  ttmCallTrackAnalytics();
};

// dlg 7.5.22
const ttmSavingsCalculatorRatioRates = function (currentScreen, discount) {
  tdl = {};
  tdl.site_events = {
    standalone_ratio_rate_selection: true,
  };
  tdl.current_screen = currentScreen;
  tdl.percent_loan_amt = discount;
  ttmCallTrackAnalytics();
};

// dlg 7.5.23
const ttmSavingsCalculatorInitiateLoan = function () {
  tdl = {};
  tdl.site_events = { standalone_initiate_loan_info: true };
  tdl.current_screen = "Enter your info";
  ttmCallTrackAnalytics();
};

// dlg 7.5.24
const ttmSavingsCalculatorCalculate = function (
  termRate,
  loanRowLength,
  loansArray
) {
  tdl = {};
  tdl.site_events = { standalone_see_my_results: true };
  tdl.current_screen = "Potential savings";
  tdl.chosen_term_rate = termRate;
  tdl.standalone_loans_added = loanRowLength;
  tdl.standalone_loans_info = loansArray;
  ttmCallTrackAnalytics();
};

// dlg 7.5.25
const ttmSavingsCalculatorBankerConnect = function () {
  $(document).on("click", ".connect-with-banker", () => {
    tdl = {};
    tdl.site_events = { standalone_contact_banker: true };
    tdl.current_screen = "Potential savings";
    ttmCallTrackAnalytics();
  });
};

/**
 * @description CD Renewal analytics
 */
// dlg 6.8 cd renewal landing
const ttmCDRenewal = function (result) {
  tdl = {
    site_events: {
      cd_renewal_landing: result,
    },
  };
  ttmCallTrackAnalytics();
};

// dlg 6.9 cd renewal request recieved
const ttmCDRenewalRequestRecieved = function (result) {
  tdl = {
    site_events: {
      cd_renewal_request_recieved: result,
    },
  };
  ttmCallTrackAnalytics();
};

// dlg 7.15.2 cd renewal tap view rate sheet
const ttmCDRenewalViewRateSheet = function () {
  tdl = {
    site_events: {
      cd_renewal_tap_view_rate_sheet: true,
    },
  };
  ttmCallTrackAnalytics();
};

// dlg 7.15.3 cd renewal tap continue
const ttmCDRenewalContinue = function () {
  tdl = {
    site_events: {
      cd_renewal_tap_continue: true,
    },
  };
  ttmCallTrackAnalytics();
};

/**
 * @description Easy enroll analytics
 */
// dlg 7.11.2 verify identity
const ttmEEGetPasscode = function () {
  tdl = {
    site_events: {
      easy_enroll_verify_tap_get_passcode: true,
    },
  };
  ttmCallTrackAnalytics();
};

// dlg 7.11.3 enter passcode
const ttmEEVerifyPasscode = function () {
  tdl = {
    site_events: {
      easy_enroll_passcode_tap_verify: true,
    },
  };
  ttmCallTrackAnalytics();
};

// dlg 7.11.4 personal details
const ttmEEPersonalDetails = function () {
  tdl = {
    site_events: {
      easy_enroll_personal_details_tap_next: true,
    },
  };
  ttmCallTrackAnalytics();
};

// Analytics 7.14.1 - connect with banker modal window - Email
const ttmDAABankerModalTapEmail = function () {
  tdl = {
    site_events: {
      daa_connect_with_banker_modal_tap_email: true,
    },
  };
  ttmCallTrackAnalytics();
};

// Analytics 7.14.2 - connect with banker modal window - call
const ttmDAABankerModalTapCall = function () {
  tdl = {
    site_events: {
      daa_connect_with_banker_modal_tap_call: true,
    },
  };
  ttmCallTrackAnalytics();
};
// Analytics 7.14.3 - open an account modal window
const ttmDAATapOpenAccountModal = function () {
  tdl = {
    site_events: {
      daa_open_account_modal_tap_open_account: true,
    },
  };
  ttmCallTrackAnalytics();
};

// Analytics 7.14.4 - open an account modal window - login here
const ttmDAAOpenAccountTapLogin = function () {
  tdl = {
    site_events: {
      daa_open_account_modal_tap_login: true,
    },
  };
  ttmCallTrackAnalytics();
};
// Analytics 7.14.5 - signup modal window - Submit
const ttmDAASignUpModalTapSubmit = function () {
  tdl = {
    site_events: {
      daa_signup_form_modal_tap_submit: true,
    },
  };
  ttmCallTrackAnalytics();
};
// Analytics 7.14.6 - signup modal window - Secure login
const ttmDAASignUpModalTapLogin = function () {
  tdl = {
    site_events: {
      daa_signup_form_modal_tap_login: true,
    },
  };
  ttmCallTrackAnalytics();
};
// Analytics 7.14.7 - signup modal window - Thank you
const ttmDAASignUpModalTapThankYou = function () {
  tdl = {
    site_events: {
      daa_signup_form_modal_view_thank_you: true,
    },
  };
  ttmCallTrackAnalytics();
};

// dlg 7.11.5 create username and password
const ttmEECreateUandP = function () {
  tdl = {
    site_events: {
      easy_enroll_createuandp_tap_enroll: true,
    },
  };
  ttmCallTrackAnalytics();
};

// dlg 7.11.6 successfull enrollment
const ttmEEEnrollSuccess = function () {
  tdl = {
    site_events: {
      easy_enroll_success: true,
    },
  };
  ttmCallTrackAnalytics();
};

// dlg 7.11.7 signin
const ttmEESignIn = function () {
  tdl = {
    site_events: {
      easy_enroll_success_tap_signin: true,
    },
  };
  ttmCallTrackAnalytics();
};

// dlg 7.11.8 error
const ttmEEError = function (errorCode, errorMessage) {
  tdl = {
    site_events: {
      app_error: true,
    },
    error_code: errorCode,
    error_msg: errorMessage,
  };
  ttmCallTrackAnalytics();
};

// dlg 7.3.14 form error
const ttmEEFormError = function (formName, errorCode) {
  tdl = {
    site_events: {
      form_error: true,
    },
    form_name: formName,
    error_code: errorCode,
  };
  ttmCallTrackAnalytics();
};

// DLG 7.12.1 7.12.2

const ttmCampaignMortgageRefi = function () {
  if ($("#fullPage.mortgage-refi").length) {
    const $bookAnAppointment = $('a[href*="us.10to8"]');
    const $sendAnEmail = $('a[href*="mailto:"]');
    let teamMemberID;
    let matches;
    let acronym;

    $bookAnAppointment.each(function () {
      $(this).on("click", function () {
        acronym = "";
        if ($(this).closest("center[data-initials]").length) {
          acronym = $(this)
            .closest("center[data-initials]")
            .attr("data-initials");
        } else {
          teamMemberID = `${$(this)
            .closest(".service-list__list-item")
            .find(".extend-h3")
            .text()}`;
          matches = teamMemberID !== "" ? teamMemberID.match(/\b(\w)/g) : [];
          acronym = Array.isArray(matches) ? matches.join("") : "";
        }
        tdl = {};
        tdl.site_events = { mtg_refi_bk_appt: true };
        if (acronym !== "") {
          tdl.team_member_id = acronym;
        }
        ttmCallTrackAnalytics();
      });
    });

    $sendAnEmail.on("click", function () {
      acronym = "";
      if ($(this).closest("center[data-initials]").length) {
        acronym = $(this)
          .closest("center[data-initials]")
          .attr("data-initials");
      } else {
        teamMemberID = `${$(this)
          .closest(".service-list__list-item")
          .find(".extend-h3")
          .text()}`;
        matches = teamMemberID !== "" ? teamMemberID.match(/\b(\w)/g) : [];
        acronym = Array.isArray(matches) ? matches.join("") : "";
      }
      tdl = {};
      tdl.site_events = { mtg_refi_send_me_email: true };
      if (acronym !== "") {
        tdl.team_member_id = acronym;
      }
      ttmCallTrackAnalytics();
    });
  }
};

// DLG 7.13.1 7.13.2
const ttmCampaignNationalMortgage = function () {
  if ($("#fullPage.national-mortgage").length) {
    const $bookAnAppointment = $('a[href*="us.10to8"]');

    $bookAnAppointment.each(function () {
      $(this).on("click", () => {
        tdl = {};
        tdl.site_events = { nat_camp_land_bk_appt: true };
        ttmCallTrackAnalytics();
      });
    });
    $('a[href*="mailto:"]').on("click", () => {
      tdl = {};
      tdl.site_events = { nat_camp_land_send_me_email: true };
      ttmCallTrackAnalytics();
    });
  }
};

// DLG 7.4.7 Mortgage Calculator Form - OnLoad
const ttmMortgageRefiLanding = function () {
  tdl = {
    site_events: {
      initiate_mortgage_calculator: true,
    },
  };
  ttmCallTrackAnalytics();
};

// DLG 7.4.8 Mortgage Calculator Form - Submit
const ttmMortgageRefiSubmit = function (hasQualified, hv, hla, hp, cs, zc) {
  tdl = {
    site_events: {
      mortgage_qualifying_result: hasQualified === true,
      mortgage_non_qualifying_result: hasQualified === false,
    },
    home_value: hv,
    home_loan_amount: hla,
    home_payment: hp,
    credit_score: cs,
    zip_code: zc,
  };
  ttmCallTrackAnalytics();
};

// DLG 7.4.9 Mortgage Calculator Form - Qualified
const ttmMortgageRefiQualified = function () {
  tdl = {
    site_events: {
      mortgage_calculator_qualified_results: true,
    },
  };
  ttmCallTrackAnalytics();
};

// DLG 7.4.11, 7.4.12 Mortgage Calculator  - Schedule with banker
const ttmMortgageScheduleAppt = function () {
  if (sessionStorage.mortgageType === "Refi") { // Refinance loan
    tdl = {
      site_events: {
        home_loan_schedule_an_appt: true,
      },
      home_value: sessionStorage.homeValue,
      home_payment: sessionStorage.payment,
      home_loan_amount: sessionStorage.loanAmt
    };
  } else { // purchase a new home
    tdl = {
      site_events: {
        home_loan_schedule_an_appt: true,
      },
      purchase_price: sessionStorage.purchasePrice,
      down_payment: sessionStorage.downPayment,
    };
  }
  
  ttmCallTrackAnalytics();
};

// DLG 7.4.13 Mortgage Calculator  - Non quaified based on Geography
const ttmMortgageInvalidZipcode = function () {
  tdl = {
    site_events: {
      mortgage_calculator_non_qualified_geography: true,
    },
  };
  ttmCallTrackAnalytics();
};

// DLG 7.4.14 Mortgage Calculator  - Non qualified based on LTV/Credit Score
const ttmMortgageInvalidLtvCredit = function () {
  tdl = {
    site_events: {
      mortgage_calculator_non_qualified_ltv_credit: true,
    },
  };
  ttmCallTrackAnalytics();
};


// PLOC soft credit pull starts && 7.3.34 DLG
const ttmPLOCSoftCreditFICOReport = function () {
  tdl = {
    site_events: {
      ploc_soft_credit_self_report_fico: true,
    },
    credit_score: "750 or higher",
  };
  ttmCallTrackAnalytics();
};

const ttmPLOCSoftCreditInsufficientCredit = function () {
  tdl = {
    site_events: {
      ploc_soft_credit_pull_insufficient_credit: true,
    },
  };
  ttmCallTrackAnalytics();
};

const ttmPLOCSoftCreditUserNotAlive = function () {
  tdl = {
    site_events: {
      ploc_soft_credit_pull_user_not_alive: true,
    },
  };
  ttmCallTrackAnalytics();
};

const ttmPLOCSoftCreditUnableToQualify = function () {
  tdl = {
    site_events: {
      "ploc_soft_credit_pull_unable_to_qualify: ": true,
    },
  };
  ttmCallTrackAnalytics();
};

const ttmPLOCSoftCreditFrozenCredit = function () {
  tdl = {
    site_events: {
      ploc_soft_credit_pull_frozen_credit: true,
    },
  };
  ttmCallTrackAnalytics();
};

// PLOC soft credit pull ends

const ttmExternalURL = function (exitUrl) {
  tdl = {};
  tdl.site_events = { property_referral: true };
  tdl.exit_url = exitUrl;
  ttmCallTrackAnalytics();
};

// DLG 7.17 Text Me The App
const ttmTextMeTheApp = function () {
  tdl = {};

  tdl = {
    site_events: {
      text_me_the_app: true,
    },
  };

  ttmCallTrackAnalytics();
};

// DLG 7.18.1 Expiring Browsers
// Softblock modal launch
const ttmEBSoftBlockView = function () {
  tdl = {};

  tdl = {
    site_events: {
      soft_block_modal_view: true,
    },
  };

  ttmCallTrackAnalytics();
};

// DLG 7.18.2 Expiring Browsers
// Sign in button in softblock modal
const ttmEBSoftBlockTapSignIn = function () {
  tdl = {};

  tdl = {
    site_events: {
      soft_block_modal_tap_sign_in: true,
    },
  };

  ttmCallTrackAnalytics();
};

// DLG 7.20.1 CRA Lead Form landing on Step 1
const ttmCRALeadFormLanding = function () {
  tdl = {
    site_events: {
      initiate_cra_lead_form: true,
    },
  };
  ttmCallTrackAnalytics();
};


// DLG 7.20.2 CRA Lead Form
const ttmCRALeadFormContinue = function () {
  tdl = {
    site_events: {
      tap_cra_lead_form_continue: true,
    },
  };
  ttmCallTrackAnalytics();
};

// DLG 7.20.3 CRA Lead Form landing on Step2
const ttmCRALeadFormStep2Landing = function () {
  tdl = {
    site_events: {
      input_cra_lead_form: true,
    },
  };
  ttmCallTrackAnalytics();
};

// DLG 7.20.4 CRA Lead Form Submit
const ttmCRALeadFormSubmit = function () {
  tdl = {
    site_events: {
      tap_cra_lead_form_submit: true,
    },
  };
  ttmCallTrackAnalytics();
};


/**
 * @function activityMapTracker
 * Implementation to generate unique activity map report
 * for similar links on a page.
 * https://experienceleague.adobe.com/docs/analytics/implementation/vars/page-vars/s-objectid.html?lang=en
 * JIRA: MARTECH-1319
 */
const activityMapTracker = function () {
  _trackAnalytics(tdl);

  // eslint-disable-next-line camelcase
  s_objectID = $(this).data("s-object-id");
};

// DOM ready
$(document).ready(() => {
  // Global click event listener on links with 'data-s-object-id'
  $(document).on("click", "a[data-s-object-id]", () => {
    activityMapTracker();
  });

  if (isTrackAnalitycsLoaded()) {
    //FRB.bodyInternalLinkTracking.init();
  }

  // eslint-disable-next-line camelcase
  if (typeof dl_location !== "undefined") {
    // eslint-disable-next-line no-undef
    //ttmLocationGetDirection(dl_location);
  }

  // eslint-disable-next-line camelcase, no-undef
  if (typeof ttm_ta_errorPage !== "undefined" && ttm_ta_errorPage === true) {
    //ttmErrorPage();
  }
/*
  ttmShareLinks();
  ttmLocationViewHours();
  ttmSuggestedSearch();
  ttmSearchResultClick();
  ttmUtilityNavMenuLinks();
  ttmContactUsCustomerCare();
  ttmLocationDetailCall();
  ttmFooterSocialNetwork();
  ttmNewsFilter();
  ttmHeaderSignIn();
  ttmDownloadFile();
  ttmHomepageCarouselRotate();
  ttmAlerts();
  ttmCategoryTabThrough();
  ttmWFFMSubmit();
  ttmTestimonialComponent();
  ttmCampaignFall2016BB();
  ttmCampaignFall2016PWM();
  ttmCampaignQ12017();
  ttmCampaignQ12017RegularPage();
  ttmCampaign2018IanBremmer();
  ttmCampaign2017AIO();
  ttmOLBSupportCall();
  ttmOLBForgotUsernamePasswordLink();
  ttmDigitalBankingPage();
  ttmBrandLogo();
  ttmArticles();
  ttmServices();
  ttmTestimonials();
  ttmCTA();
  ttmCampaignCD();
  ttmCampaignStudentLoan();
  ttmEstimatorFullpage();
  ttmCareers();
  ttmCOL();
  ttmWFFMFormThankYou();
  ttmAlphabetPage();
  ttmGoogle();
  ttmCampaign2019();
  ttmSavingsCalculatorBankerConnect();
  ttmCampaignMortgageRefi();
  ttmCampaignNationalMortgage();
  */
});

// Capture analytics data on window load
$(window).load(() => {
  // See What it Takes
  // eslint-disable-next-line camelcase
  if (typeof dl_loanObject !== "undefined") {
    // eslint-disable-next-line no-undef
    //ttmFYDGetLoanAttributes(dl_loanObject);
  }
  if ($(".e14b-estimator-wrapper").length > 0) {
    //ttmE14BOnInit();
  }

  if (
    $(
      ".aio-estimator-wrapper-new.aio-refresh-1step, .aio-estimator-wrapper-new.aio-refresh-classic, .aio-estimator-wrapper-new.aio-refresh, #estimator.estimator-tracker"
    ).length > 0
  ) {
    //ttmSLROneStepOnInit();
  }

  if ($("#banker.estimator-tracker").length > 0) {
    //ttmPLOCBankerInfo();
  }

  // Self ID Not Qualified
  $(".aio-estimator-wrapper .aio-yesno a[data-val='No']").on("click", () => {
    //ttmSelfIDNotQualified("self_id_not_qualified");
  });

  $(".aio-estimator-wrapper .question-header.question-1").on(
    "click",
    "a",
    () => {
      //ttmSelfIDNotQualified("why_we_need_to_know");
    }
  );

  $(".aio-estimator-wrapper .question-header.question-3").on(
    "click",
    "a",
    () => {
      //ttmSelfIDNotQualified("why_we_need_to_know");
    }
  );

  //ttmPWMTeamFilter();
});

// ES6
export default {
  isTrackAnalitycsLoaded,
  ttmGoogleScrollingEvent,
  ttmExternalURL,
  ttmVideoPlayback,
  ttmPLOCSoftCreditFrozenCredit,
  ttmPLOCSoftCreditInsufficientCredit,
  ttmPLOCSoftCreditUserNotAlive,
  ttmPLOCSoftCreditUnableToQualify,
  ttmPLOCScheduleAppointment,
  ttmCDRenewal,
  ttmCDRenewalRequestRecieved,
  ttmCDRenewalContinue,
  ttmCDRenewalViewRateSheet,
  ttmDAABankerModalTapEmail,
  ttmDAABankerModalTapCall,
  ttmDAASignUpModalTapSubmit,
  ttmDAAOpenAccountTapLogin,
  ttmDAATapOpenAccountModal,
  ttmDAASignUpModalTapLogin,
  ttmEESignIn,
  ttmEEGetPasscode,
  ttmEEVerifyPasscode,
  ttmEEPersonalDetails,
  ttmEECreateUandP,
  ttmEEFormError,
  ttmEEError,
  ttmEEEnrollSuccess,
  ttmGoogleMeetFRBTeam,
  ttmGoogleCallOrTextFRB,
  ttmGoogleConnectOtherWayFRB,
  ttmGoogleMeetFRBBanker,
  ttmGoogleScheduleMeetWithFRBBanker,
  ttmGoogleExceptionalOffers,
  ttmGoogleHeroLocation,
  ttmGoogleScheduleAppointment,
  ttmGoogleHeroCall,
  ttmGoogleBankerEmail,
  ttmGoogleStickyFooter,
  ttmGooleStickyFooterScheduleAppointment,
  ttmGoogleAboutFRB,
  ttmGoogleHeroGetStarted,
  ttmGoogleStickyFooterGetStarted,
  ttmGoogleOpenAccountBonus,
  ttmGoogleScheduleAppointmentModal,
  ttmGoogleOpenAccountModal,
  ttmGoogleLoginModal,
  ttmGoogleSignUpFormSubmit,
  ttmDAASignUpModalTapThankYou,
  ttmPLOCRelationshipBasedPricing,
  ttmEstimatorStep1,
  ttmEstimatorStep2,
  ttmEstimatorStep3,
  ttmPLOCSoftCreditFICOReport,
  ttmEstimatorContactRequest,
  ttmEstimatorRMInfo,
  ttmPLOCPotentialSavings,
  ttmCDCalculator,
  ttmCurrentDepositRate,
  ttmAudioPlayback,
  ttmE14BScheduleAppointment,
  ttmE14BStep2,
  ttmE14BContinue,
  ttmE14BConnectNow,
  ttmArticlesFilter,
  ttmLocationIndex,
  ttmLocationBubbleGetDirection,
  ttmLocationZoomMap,
  ttmLocationToggleMapList,
  ttmSavingsCalculatorRatioRates,
  ttmSavingsCalculatorCurrentRateTabs,
  ttmSavingsCalculatorCalculate,
  ttmSavingsCalculatorInitiateLoan,
  ttmMobileBankingFAQs,
  ttmInterstitialMessages,
  ttmOLBWrongPassword,
  ttmOLBSignIn,
  ttmOLBForgotUsernameError,
  ttmVimeoVideoPlayback,
  ttmPLOCLoanTerms,
  ttmCampaignQ12017Testimonials,
  ttmEstimatorBankerSocialInfo,
  ttmRelationshipBasedPricing,
  ttmPotentialSavings,
  ttmTPProviderAllPartners,
  ttmTPProviderFinancial,
  ttmTPProviderProfessional,
  ttmTPProviderNonProfit,
  ttmTPProviderRealEstate,
  ttmTPProviderTech,
  ttmMortgageRefiLanding,
  ttmMortgageRefiSubmit,
  ttmMortgageRefiQualified,
  ttmMortgageScheduleAppt,
  ttmMortgageInvalidZipcode,
  ttmMortgageInvalidLtvCredit,
  ttmTestimonialsFilters,
  ttmTextMeTheApp,
  ttmEBSoftBlockView,
  ttmEBSoftBlockTapSignIn,
  ttmCRALeadFormLanding,
  ttmCRALeadFormContinue,
  ttmCRALeadFormStep2Landing,
  ttmCRALeadFormSubmit,
};
